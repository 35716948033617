import React from 'react'
import {
  Box,
  Flex,
  Text,
  PrimaryButton,
  SecondaryOutlinedButton,
  useApi,
} from '@fivehealth/botero'
import { useCookies } from 'react-cookie'

import BotThumbsUp from '../../assets/bot-thumbs-up.svg'
import Config from '../../../Config'

const ChernobylEntryConfirmation = ({
  closeModal,
  title = 'Changes Saved!',
  // shortLabel,
  moduleDataSource,
  exportFileUsingLink,
}) => {
  const [cookies] = useCookies([Config.cookie.name])
  const token = cookies && cookies[Config.cookie.name]

  const handleClose = () => {
    closeModal()
  }

  const {
    queries: { useEinsteinChernobylExport },
  } = useApi({
    queries: ['useEinsteinChernobylExport'],
  })

  const { data: exportData = null, isLoading } = useEinsteinChernobylExport({
    enabled: !!moduleDataSource,
    variables: {
      uid: (moduleDataSource || {}).uid || '',
      outputFormat: 'CSV',
      searchQuery: '',
    },
  })

  const canExport =
    exportData &&
    exportData.pages.length > 0 &&
    exportData.pages[0].einsteinChernobylEntrys

  const handleExport = () => {
    if (canExport) {
      const link = exportData.pages[0].einsteinChernobylEntrys.exportByUrl
      const fileName = moduleDataSource?.name?.replaceAll(' ', '')
      exportFileUsingLink(link, fileName)
    }
  }

  return (
    <Box p={2} data-testid="exportModal">
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <Flex justifyContent="center" alignItems="center" m={2}>
          <Box as="img" src={BotThumbsUp} />
        </Flex>
        <Text textAlign="center" mb={2} fontSize={2} fontWeight="600">
          {title}
        </Text>
        <Text textAlign="center" fontSize={2} fontWeight="600">
          We recommend you export the database
        </Text>
        <Text textAlign="center" mb={2} fontSize={2} fontWeight="600">
          to ensure it is always up to date.
        </Text>
        {/* <Text fontSize={2} style={{ opacity: 0.8 }}>
          This will ensure your master sheet is up to date.
        </Text> */}

        <Flex justifyContent="center" alignItems="center" mt={2} mb={2}>
          <SecondaryOutlinedButton
            borderRadius="8px"
            m={1}
            fontSize={14}
            fontWeight={400}
            style={{ padding: '11px 15px' }}
            onClick={handleClose}
            data-testid="maybeLaterButton"
          >
            Maybe later
          </SecondaryOutlinedButton>
          <PrimaryButton
            disabled={!exportData || !token || isLoading}
            borderRadius="8px"
            m={1}
            fontSize={14}
            onClick={handleExport}
            style={{ padding: '16px 18px' }}
            data-testid="exportButton"
          >
            {/* !!! is this a general lable  */}
            {/* Export {shortLabel} list */}
            Export Database
          </PrimaryButton>
        </Flex>
      </Flex>
    </Box>
  )
}

export default ChernobylEntryConfirmation
