import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex,
  PrimaryButton,
  SecondaryOutlinedButton,
  Text,
  theme,
} from '@fivehealth/botero'
import { formatISO } from 'date-fns/fp'
import { Link } from 'react-router-dom'
import { trim } from 'lodash'
import { format, parseISO } from 'date-fns'
import AlertModal from '../../../components/Modals/AlertModal'
import BasicEditModal from '../../../components/Modals/BasicEditModal'
import { transformTextToFormalCase } from '../../../../Utils'
import Chip from '../../../components/Chip/Chip'

const getForm = (
  dataDepartments,
  dataDesignations,
  settingsData,
  dataDivisions,
  adminType
) => {
  const { adminContactFields } = settingsData?.einsteinSettings || {}
  // const [departmentField, setDepartmentField] = useState({
  //   id: 'selectedDepartment',
  //   type: 'select',
  //   label: 'Select Department',
  //   isMulti: true,
  //   options: dataDepartments?.map((o) => ({
  //     value: o.uid,
  //     label: o.name,
  //   })),
  //   required: true,
  //   // visibility: adminType === 'Department Admin', // Show this field only for Department Admin
  //   visibility: false,
  // })
  const [divisionField, setDivisionField] = useState({
    id: 'selectedDivision',
    type: 'select',
    label: 'Select Division',
    isMulti: true,
    // Assuming you have a similar data source for divisions
    options: dataDivisions?.map((o) => ({
      value: o.uid,
      label: o.name,
    })),
    required: true,
    visibility: adminType === 'Division Admin', // Show this field only for Division Admin
  })

  useEffect(() => {
    // setDepartmentField({
    //   id: 'selectedDepartment',
    //   type: 'botselect',
    //   label: 'Select Department',
    //   extraProps: { isDisabled: true },
    //   isMulti: false,
    //   options: dataDepartments?.map((o) => ({
    //     value: o.uid,
    //     label: o.name,
    //   })),
    //   required: true,
    //   // visibility: adminType === 'Department Admin', // Show this field only for Department Admin
    //   visibility: false,
    // })

    setDivisionField({
      id: 'selectedDivision',
      type: 'botselect',
      label: 'Select Division',
      isMulti: false,
      // Assuming you have a similar data source for divisions
      options: dataDivisions?.map((o) => ({
        value: o.uid,
        label: o.name,
      })),
      required: true,
      visibility: adminType === 'Division Admin', // Show this field only for Division Admin
    })
  }, [adminType])

  // New field for division selection
  const emailRequired = adminContactFields?.includes('email') || false
  const phoneRequired = !emailRequired
    ? true
    : adminContactFields?.includes('phone') || false
  const fields = [
    {
      id: 'adminInformation',
      title: 'Admin Details',
      type: 'input',
      fields: [
        {
          id: 'firstName',
          testId: 'firstName',
          type: 'input',
          visibility: true,
          label: 'Name',
          placeholder: 'Enter Name',
          required: true,
          fullWidth: true,
        },
        {
          id: 'phone',
          testId: 'phone',
          type: 'phone',
          label: 'Phone number',
          editable: true,
          //   value: !enableAddUser ? 'xxxxxxxx' : '',
          value: '',
          required: phoneRequired,
        },
        {
          id: 'email',
          testId: 'email',
          type: 'input',
          visibility: true,
          label: 'Email address',
          placeholder: 'Enter email address',
          required: emailRequired,
        },
        {
          id: 'designation',
          type: 'editableSelect',
          label: 'Designation',
          options: dataDesignations?.map((o) => ({
            value: o.uid,
            label: o.name,
          })),
          required: true,
        },
        {
          id: 'department',
          type: 'editableSelect',
          label: 'Department',
          options: dataDepartments?.map((o) => ({
            value: o.uid,
            label: o.name,
          })),
          required: true,
        },
      ],
      required: true,
    },
    {
      id: 'accountSettings',
      testId: 'accountSettings',
      title: 'Account Settings',
      fields: [
        {
          id: 'accountType',
          type: 'select',
          label: 'Account Type',
          options: [
            {
              value: 'Department Admin',
              label: 'Department Admin',
            },
            {
              value: 'Division Admin',
              label: 'Division Admin',
            },
            {
              value: 'Super Admin',
              label: 'Super Admin',
            },
          ],
        },
      ],
      required: false,
    },
  ]
  if (adminType === 'Division Admin') {
    fields.push({
      type: 'input',
      fields: [divisionField],
      required: true,
    })
  }
  return fields
}

const getAccountTypeText = (isSuper, divisions) => {
  if (isSuper) return 'Super Admin'
  if (divisions && divisions.length > 0) return 'Division Admin'
  return 'Department Admin'
}

const createDefaultProp = (data, dataDesignations, dataDepartments) => {
  let departmentUid
  let designationUid

  if (data?.department) {
    departmentUid = dataDepartments?.find(
      (ele) => ele?.name === data?.department?.name
    )?.uid
  }
  if (data?.designation) {
    designationUid = dataDesignations?.find(
      (ele) => ele?.name === data?.designation?.name
    )?.uid
  }

  return {
    firstName: data?.firstName,
    phone: data?.identifiableInformations?.find((d) => d.type === 'PHONE')
      ?.encryptedValue,
    email: data?.identifiableInformations?.find((d) => d.type === 'EMAIL')
      ?.encryptedValue,
    department: { label: data?.department?.name, value: departmentUid },
    designation: { label: data?.designation?.name, value: designationUid },
    uid: data?.uid,
    accountType: {
      value: getAccountTypeText(data?.isSuper, data?.divisions),
      label: getAccountTypeText(data?.isSuper, data?.divisions),
    },
    selectedDepartment: data?.departments?.map((o) => ({
      value: o.uid,
      label: o.name,
    })),
    selectedDivision: data?.divisions?.map((o) => ({
      value: o.uid,
      label: o.name,
    })),
  }
}

export const AlertModalConfirmation = ({
  title,
  description,
  closeModal,
  botmdImage,
  descriptionLink = {},
  logEventProps = {},
  testId = '',
}) => (
  <AlertModal
    testId={testId}
    title={title}
    description={description}
    handleClose={closeModal}
    secondaryButton={null}
    descriptionLink={descriptionLink}
    renderCloseTrigger={() => (
      <Flex mt={4} justifyContent="center" alignItems="center">
        <SecondaryOutlinedButton
          borderRadius="8px"
          mr={2}
          onClick={() => closeModal()}
          logEventProps={logEventProps}
          data-testid={`${testId}Button`}
        >
          Close
        </SecondaryOutlinedButton>
      </Flex>
    )}
    botmdImage={botmdImage}
  />
)

const handleUpdateConfirmationModal = ({
  openModal,
  closeModal,
  onUpdate,
  updateInputParam = null,
  data = {},
  actionType,
}) => {
  const idInfo = {
    email: data?.identifiableInformations?.find(
      (o) => o.type?.toUpperCase() === 'EMAIL'
    )?.encryptedValue,
    phone: data?.identifiableInformations?.find(
      (o) => o.type?.toUpperCase() === 'PHONE'
    )?.encryptedValue,
  }

  const deactivatedOn =
    actionType?.toLowerCase() === 'activate' ? null : formatISO(new Date())

  const actionLabel =
    actionType?.toLowerCase() === 'activate' ? 'reactivated' : 'deactivated'
  const title =
    actionType?.toLowerCase() === 'activate' ? 'Reactivate' : 'Deactivate'

  const isBulkUpdate = !!updateInputParam

  openModal(
    <BasicEditModal
      data={data}
      closeModal={closeModal}
      testId={`update_account_modal-${actionType}`}
      title={
        !isBulkUpdate
          ? `${title} "${transformTextToFormalCase(data?.firstName)}"?`
          : `Bulk ${actionType} users?`
      }
      renderDescription={() => (
        <>
          <Box mt={4} mb={4}>
            {isBulkUpdate
              ? `What happens to all selected user account when ${actionLabel}?`
              : `What happens when an account is ${actionLabel}?`}
          </Box>
          {actionType?.toLowerCase() === 'activate' ? (
            <ul>
              <li>
                <Text fontWeight="400" mt={1} fontSize={2}>
                  {isBulkUpdate
                    ? 'The selected admin users will be able to sign into Einstein.'
                    : 'The admin will be able to sign into Einstein.'}
                </Text>
              </li>
              <li>
                <Text fontWeight="400" mt={1} fontSize={2}>
                  {isBulkUpdate
                    ? 'The selected admin users will be able to manage the hospital app according to defined permissions.'
                    : 'The admin will be able to manage the hospital app according to defined permissions.'}
                </Text>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <Text fontWeight="400" mt={1} fontSize={2}>
                  {isBulkUpdate
                    ? 'The selected admin users will no longer be able to sign into Einstein.'
                    : 'The admin will no longer be able to sign into Einstein.'}
                </Text>
              </li>
              {!isBulkUpdate && data?.broadcastCount > 0 && (
                <li>
                  <Text fontWeight="400" mt={1} fontSize={2}>
                    <span style={{ fontWeight: 'bold' }}>
                      {data?.broadcastCount}
                    </span>{' '}
                    scheduled broadcasts created by this admin will still be
                    sent out.
                  </Text>
                </li>
              )}

              <li>
                <Text fontWeight="400" mt={1} fontSize={2}>
                  {`Note that ${
                    isBulkUpdate ? 'all selected admin accounts' : 'admin'
                  } will still have access to Bot MD Hospital app
                    if added in `}
                  <Link
                    to="/user_management"
                    target="_self"
                    onClick={() => closeModal()}
                  >
                    User List
                  </Link>
                </Text>
              </li>
            </ul>
          )}
        </>
      )}
      renderInputForm={() => (
        <Flex mt={2} justifyContent="end" alignItems="end">
          <SecondaryOutlinedButton
            borderRadius="8px"
            mr={2}
            mt={2}
            onClick={() => {
              closeModal()
            }}
          >
            Cancel
          </SecondaryOutlinedButton>
          <PrimaryButton
            mt={2}
            borderRadius="8px"
            style={
              actionType?.toLowerCase() !== 'activate'
                ? {
                    backgroundColor: theme.colors.danger,
                  }
                : null
            }
            onClick={() => {
              const param = !isBulkUpdate
                ? {
                    updateInput: {
                      uid: data?.uid,
                      firstName: data?.firstName,
                      departmentUid: data?.department?.uid,
                      designationUid: data?.designation?.uid,
                      deactivatedOn,
                      ...idInfo,
                    },
                  }
                : updateInputParam
              onUpdate({ ...param, broadcastCount: data?.broadcastCount })
            }}
          >
            {isBulkUpdate ? `Bulk ${actionType} users` : `${title} user`}
          </PrimaryButton>
        </Flex>
      )}
    />
  )
}

export const INIT_DEFAULT_FETCH = 25
export const DATE_FORMAT = 'dd MMMM yyyy, h:mm a'
export const DEFAULT_PARAMS = {
  sortBy: 'CREATED_ON',
  first: INIT_DEFAULT_FETCH,
}
export const einsteinTableColSettings = [
  {
    id: 'col-name',
    accessor: 'firstName',
    Header: 'Name',
    Cell: ({ value }) => <Text>{trim(value)}</Text>,
  },
  {
    id: 'col-designation',
    accessor: 'designation',
    Header: 'Designation',
    Cell: (data) => (
      <Text>{data?.row?.original?.designation?.name || '-'}</Text>
    ),
  },
  {
    id: 'col-department',
    accessor: 'department',
    Header: 'Department',
    Cell: (data) => <Text>{data?.row?.original?.department?.name || '-'}</Text>,
  },
  {
    id: 'col-accountType',
    accessor: 'isSuper',
    Header: 'Account Type',
    Cell: (data) => (
      <Text>
        {getAccountTypeText(
          data?.row?.original?.isSuper,
          data?.row?.original?.divisions
        )}
      </Text>
    ),
  },
  {
    id: 'col-accountStatus',
    accessor: 'isActivated',
    Header: 'Account Status',
    Cell: ({ value }) => (
      <Chip
        cursor="pointer"
        label={value ? 'Activated' : 'Deactivated'}
        borderRadius="6px"
        fontSize="14px"
        bg={value ? '#DCF7E8' : '#F4F6F8'}
        textColor={value ? '#27AE60' : '#697481'}
      />
    ),
  },
  {
    id: 'col-createdOn',
    accessor: 'createdOn',
    Header: 'Created On',
    Cell: ({ value }) => <Text>{format(parseISO(value), DATE_FORMAT)}</Text>,
  },
]

export { getForm, createDefaultProp, handleUpdateConfirmationModal }
