import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import Config from 'Config'
import { useEinsteinAdministratorProfile } from 'old-code/context/EinsteinAdministratorContext'

const OrganizationsContext = React.createContext(null)

export const useOrganizationsContext = () => {
  const context = React.useContext(OrganizationsContext)
  if (context === null || context === undefined) {
    throw new Error('useOrganizationsContext is outside OrganizationsProvider')
  }
  return context
}

const OrganizationsProvider = ({ children }) => {
  const publicOrganizationsList = ['cgh', 'ttsh', 'sgh', 'nccs']
  const allowLabFormularyList = ['ttsh', 'nkti', 'botmd', 'dharmais', 'demo']
  const allowDrugFormularyList = [
    'cgh',
    'ktph',
    'nkti',
    'botmd',
    'dharmais',
    'parkwayradio',
    'demo',
  ]

  if (Config.environment === 'staging') {
    allowLabFormularyList.push('ktph')
  }

  const [isPublicHospital, setIsPublicHospital] = useState(false)
  const [isLabFormularyEnabled, setIsLabFormularyEnabled] = useState(false)
  const [isDrugFormularyEnabled, setIsDrugFormularyEnabled] = useState(false)

  const einsteinAdmin = get(
    useEinsteinAdministratorProfile(),
    'einsteinAdmin',
    {}
  )

  const contextValue = {
    isPublicHospital,
    isLabFormularyEnabled,
    isDrugFormularyEnabled,
  }

  useEffect(() => {
    if (einsteinAdmin) {
      const { organizationKey } = einsteinAdmin?.hospital || ''
      setIsPublicHospital(publicOrganizationsList.includes(organizationKey))
      setIsLabFormularyEnabled(allowLabFormularyList.includes(organizationKey))
      setIsDrugFormularyEnabled(
        allowDrugFormularyList.includes(organizationKey)
      )
    }
  }, [einsteinAdmin])

  return (
    <OrganizationsContext.Provider value={contextValue}>
      {children}
    </OrganizationsContext.Provider>
  )
}

export default OrganizationsProvider
