import React, { useState, useEffect } from 'react'
import {
  Box,
  Flex,
  H1,
  InfoCard,
  FAIcon,
  useApi,
  Text,
} from '@fivehealth/botero'
import { useMediaQuery } from 'react-responsive'
import { get, isEmpty, chain } from 'lodash'
import {
  faUser,
  faQuestion,
  faArrowRight,
} from '@fortawesome/pro-regular-svg-icons'
import { useEinsteinAdministratorProfile } from 'old-code/context/EinsteinAdministratorContext'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import usePostHog from 'old-code/customHooks/usePostHog'

import DataTable from './components/DataTable'
import EventsConstant from '../../config/constants/events.constants'

const Overview = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' })
  const [einsteinBroadcastTasks, setEinsteinBroadcastTasks] = useState([])
  const history = useHistory()
  const [weeklyQueryingUsers, setWeeklyQueryingUsers] = useState({})
  const [weeklySignedInUsers, setWeeklySignedInUsers] = useState({})

  const [documents, setDocuments] = useState([])
  const [weeklyActiveUsers, setWeeklyActiveUsers] = useState({})

  const einsteinAdmin = get(
    useEinsteinAdministratorProfile(),
    'einsteinAdmin',
    {}
  )

  const {
    queries: {
      useEinsteinBroadcastSentTask,
      useEinsteinMetricSnapShotWithoutPaginit,
    },
  } = useApi({
    queries: [
      'useEinsteinBroadcastSentTask',
      'useEinsteinMetricSnapShotWithoutPaginit',
    ],
  })

  const {
    data: einsteinBroadcastTasksData,
    isLoading: isBroadcastLoading,
    isFetching: isBroadcastFetching,
  } = useEinsteinBroadcastSentTask({
    variables: {
      sortDesc: true,
      first: 10,
      status: ['COMPLETED', 'SENDING'],
    },
  })

  const { data: weeklyQueryingUsersData } =
    useEinsteinMetricSnapShotWithoutPaginit({
      variables: {
        key: 'WEEKLY_QUERYING',
        first: 1,
      },
    })

  const { data: weeklySignedInUsersData } =
    useEinsteinMetricSnapShotWithoutPaginit({
      variables: {
        key: 'WEEKLY_SIGNED_IN',
        first: 1,
      },
    })

  const { data: weeklyActiveUsersData } =
    useEinsteinMetricSnapShotWithoutPaginit({
      variables: {
        key: 'WEEKLY_ACTIVE',
        first: 1,
      },
    })

  const {
    data: topDocumentsSearchedData,
    isLoading: isDocumentLoading,
    isFetching: isDocumentFetching,
  } = useEinsteinMetricSnapShotWithoutPaginit({
    variables: {
      key: 'LAST_30_DAYS_DOCUMENTS_VIEWED',
      first: 10,
    },
  })

  useEffect(() => {
    const tempTasks = chain(einsteinBroadcastTasksData)
      .get('pages', [])
      .flatMap((page) => page || [])
      .filter((page) => page.status !== 'DRAFT')
      .map((task) => ({
        uid: task.uid,
        broadcastName: task.name,
        sendTime: task.sentOn,
        createdOn: task.scheduledFor,
        status: task.status,
        createdBy: task.createdBy.fullName,
        rulesets: task.rulesets,
        messages: task.messagesWithSecureUrl,
        secureMessages: task.messagesWithSecureUrl,
      }))
      .value()
    setEinsteinBroadcastTasks(tempTasks.slice(0, 10))
  }, [einsteinBroadcastTasksData])

  useEffect(() => {
    if (!isEmpty(topDocumentsSearchedData?.einsteinMetricSnapshots)) {
      const { einsteinMetricSnapshots } = topDocumentsSearchedData
      if (
        einsteinMetricSnapshots &&
        einsteinMetricSnapshots?.edges?.length > 0
      ) {
        const selectedEdge = einsteinMetricSnapshots.edges[0]
        const result = selectedEdge.node.value
        const docs = Object.keys(result)
          .map((key) => ({
            title: key,
            queryCount: result[key],
          }))
          .sort((a, b) => b.queryCount - a.queryCount)
          .slice(0, 10)
        setDocuments(docs)
      }
    }
  }, [topDocumentsSearchedData])

  const logPostHogEvent = usePostHog()
  useEffect(() => {
    logPostHogEvent('dashboard:overview_view')
  }, [logPostHogEvent])

  const handleRowPressed = (task) => {
    history.push({
      pathname: '/broadcast_info',
      state: {
        data: task,
      },
    })
  }

  const getInfo = (usersData) => {
    let info = { weekData: '', count: 0 }
    const edges = usersData?.einsteinMetricSnapshots?.edges || []
    if (!isEmpty(edges)) {
      const data = edges[0]
      const start = DateTime.fromISO(data.node.startOn)
        .setLocale('en-SG')
        .toFormat('dd LLL')
      const end = DateTime.fromISO(data.node.endOn)
        .setLocale('en-SG')
        .toFormat('dd LLL, yyyy')
      info = {
        weekData: `${start} - ${end}`,
        count: data.node.value.count,
      }
    }
    return info
  }

  useEffect(() => {
    setWeeklyQueryingUsers(getInfo(weeklyQueryingUsersData))
  }, [weeklyQueryingUsersData])

  useEffect(() => {
    setWeeklySignedInUsers(getInfo(weeklySignedInUsersData))
  }, [weeklySignedInUsersData])

  useEffect(() => {
    setWeeklyActiveUsers(getInfo(weeklyActiveUsersData))
  }, [weeklyActiveUsersData])

  const navigateToAnalytics = (
    title,
    description,
    queryKey,
    chartType,
    onHoverText
  ) => {
    history.push({
      pathname: '/analytics_detail_chart',
      state: {
        defaultData: {
          title,
          description,
          queryKey,
          chartType,
          onHoverText,
          duration: {
            id: 7,
            label: 'Last 7 days',
          },
        },
      },
    })
  }

  const showAnalyticsDetail = (data) => {
    history.push({
      pathname: '/analytics_detail_chart',
      state: {
        defaultData: data,
      },
    })
  }

  const renderInfoCardTitle = (title, weekData) => (
    <>
      <Flex alignItems="center">
        {title}
        <FAIcon
          icon={faArrowRight}
          style={{ marginLeft: 4, fontSize: 13, color: '#697481' }}
        />
      </Flex>
      <Text fontSize={12} fontWeight="500" color="#697481" lineHeight="20px">
        {weekData}
      </Text>
    </>
  )

  const isAdminEmpty = () =>
    isEmpty(einsteinAdmin) || Object.keys(einsteinAdmin).length === 0

  return (
    <Box mt={2} ml={2}>
      <H1 mb={isMobile ? '0px' : '24px'} fontSize={isMobile ? '24px' : '32px'}>
        Welcome, {isAdminEmpty() ? '' : einsteinAdmin.fullName}
      </H1>
      <Flex
        pb={1}
        mb={[3, 0]}
        flexDirection={['column', 'column', 'row']}
        flexWrap="wrap"
      >
        <InfoCard
          data-testid="weekly-active-users-card"
          onClick={() =>
            navigateToAnalytics(
              'Active Users',
              'View and download the count of all active users during your selected time period',
              'DAILY_ACTIVE',
              'LINE_CHART',
              'An active user is a user who opens up and engages with the Bot MD Hospital app. This includes both Bot queries and user interactions (e.g. tapping on Favorites)'
            )
          }
          mr={[0, 0, 6]}
          mt={3}
          stats={weeklyActiveUsers.count}
          icon={
            <FAIcon icon={faUser} style={{ fontSize: 20, color: '#fff' }} />
          }
          variant="primary"
          flexDirection={['row', 'row', 'column']}
          title={renderInfoCardTitle(
            'Weekly Active Users',
            weeklyActiveUsers.weekData
          )}
          logEventProps={{
            subSource: EventsConstant.OVERVIEW_PAGE,
            eventName: EventsConstant.DASHBOARD_WEEKLY_ACTIVE_USERS,
            page: EventsConstant.OVERVIEW_PAGE,
          }}
        />
        <InfoCard
          mr={[0, 0, 6]}
          data-testid="weekly-querying-users-card"
          mt={3}
          onClick={() =>
            navigateToAnalytics(
              'Querying Users',
              'View and download the count of all querying users during your selected time period',
              'DAILY_QUERYING',
              'LINE_CHART',
              'A querying user is a user who has asked at least 1 question to the Bot. This does not include user interactions (e.g. tapping on Favorites)'
            )
          }
          stats={weeklyQueryingUsers.count}
          icon={
            <FAIcon icon={faQuestion} style={{ fontSize: 20, color: '#fff' }} />
          }
          variant="success"
          flexDirection={['row', 'row', 'column']}
          title={renderInfoCardTitle(
            'Weekly Querying Users',
            weeklyQueryingUsers.weekData
          )}
          logEventProps={{
            subSource: EventsConstant.OVERVIEW_PAGE,
            eventName: EventsConstant.DASHBOARD_WEEKLY_QUERYING_USERS,
            page: EventsConstant.OVERVIEW_PAGE,
          }}
        />
        <InfoCard
          data-testid="weekly-users-signin-card"
          mr={[0, 0, 6]}
          onClick={() =>
            navigateToAnalytics(
              'Activated Accounts',
              'View and download the count of all users who have logged into the Bot MD Hospital application at least once during your selected time period',
              'DAILY_SIGNED_IN',
              'LINE_CHART',
              'Activated accounts are the users who have logged into the Bot MD Hospital application at least once'
            )
          }
          mt={3}
          stats={weeklySignedInUsers.count}
          icon={
            <FAIcon icon={faUser} style={{ fontSize: 20, color: '#fff' }} />
          }
          variant="warning"
          flexDirection={['row', 'row', 'column']}
          title={renderInfoCardTitle(
            'Weekly Activated Accounts',
            weeklySignedInUsers.weekData
          )}
          logEventProps={{
            subSource: EventsConstant.OVERVIEW_PAGE,
            eventName: EventsConstant.DASHBOARD_WEEKLY_SIGNED_USERS,
            page: EventsConstant.OVERVIEW_PAGE,
          }}
        />
        {
          // NOTE: Is this needed for the e2e?
          // <InfoCard data-testid="weekly-users-signin-card" mr={[0, 0, 6]} onClick={() => navigateToAnalytics('Daily Users Signed-in', 'Signed In Users', 'DAILY_SIGNED_IN', 'LINE_CHART')} mt={3} stats={weeklySignedInUsers.count} icon={<FAIcon icon={faUser} style={{ fontSize: 20, color: '#fff' }} />} variant="warning" flexDirection={['row', 'row', 'column']} title={renderInfoCardTitle('Weekly Users Signed-in', weeklySignedInUsers.weekData)} />
        }
      </Flex>
      <Flex
        mt={8}
        pb={8}
        pr={8}
        flex={1}
        justifyContent="space-between"
        flexDirection={['column', 'row']}
      >
        <DataTable
          defaultCursor
          isLoading={isDocumentFetching || isDocumentLoading}
          data-testid="top-documents-table"
          title="Top Documents Viewed Last 30 days"
          tableHeaders={[
            { key: 'title', label: 'Title' },
            { key: 'queryCount', label: 'Views' },
          ]}
          rows={isEmpty(documents) ? [] : documents}
          pr={[0, 0, '20px']}
          mb={[4, 4, 0]}
          width={['100%', '80%', '60%']}
          handleTitleClick={() =>
            showAnalyticsDetail({
              chartType: 'BAR_CHART',
              queryKey: 'LAST_30_DAYS_DOCUMENTS_VIEWED',
              title: 'Top Documents Viewed',
              onHoverText:
                'Ranks the total number of times specific documents were viewed by users during your selected time period',
              description:
                'View and download the count of documents that are most viewed by users',
              breakdownTableKey: 'Document Name',
              breakdownTableValue: 'Views',
            })
          }
          noResultProps={{
            description: 'No search data',
          }}
          logEventProps={{
            subSource: EventsConstant.OVERVIEW_PAGE,
            eventName: EventsConstant.DASHBOARD_ALL_DOCUMENT,
            page: EventsConstant.OVERVIEW_PAGE,
          }}
          tableElementlogEventProps={{
            subSource: EventsConstant.OVERVIEW_TOP_DOCUMENT_TABLE,
            eventName: EventsConstant.DASHBOARD_DOCUMENT_ELEMENT_VIEWED,
            page: EventsConstant.OVERVIEW_PAGE,
          }}
        />

        <DataTable
          isLoading={isBroadcastLoading || isBroadcastFetching}
          data-testid="recent-broadcast-table"
          title="Recent Hospital Broadcasts"
          tableHeaders={[
            { key: 'broadcastName', label: 'Broadcast name' },
            { key: 'status', label: 'Status' },
            { key: 'sendTime', label: 'Send time' },
          ]}
          rows={einsteinBroadcastTasks}
          mb={[4, 4, 0]}
          ml={[0, 0, 2]}
          width={['100%', '80%', '50%']}
          handleTitleClick={() => {
            localStorage.setItem('selected_broadcast_tab', 'sent')
            history.push('/broadcast')
          }}
          handleRowPressed={handleRowPressed}
          noResultProps={{
            description: 'No recent broadcasts',
          }}
          logEventProps={{
            subSource: EventsConstant.OVERVIEW_PAGE,
            eventName: EventsConstant.DASHBOARD_ALL_BROADCASTS,
            page: EventsConstant.OVERVIEW_PAGE,
          }}
          tableElementlogEventProps={{
            subSource: EventsConstant.OVERVIEW_RECENT_BROADCASTS,
            eventName: EventsConstant.DASHBOARD_BROADCAST_ELEMENT_VIEWED,
            page: EventsConstant.OVERVIEW_PAGE,
          }}
        />
      </Flex>
    </Box>
  )
}

export default Overview
