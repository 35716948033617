import {
  Box,
  FAIcon,
  Flex,
  H1,
  H5,
  PrimaryButton,
  useApi,
} from '@fivehealth/botero'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import Tabs from 'old-code/components/Tabs/Tabs'
import _, { isEmpty, map } from 'lodash'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import BroadcastManagerTaskInfoContent from './BroadcastManagerTaskInfoContent'
import BroadcastManagerTaskInfoDelivery from './BroadcastManagerTaskInfoDelivery'
import BroadcastManagerTaskInfoRecipients from './BroadcastManagerTaskInfoRecipients'
import EventsConstant from '../../../config/constants/events.constants'

const getTotalProfiles = (obj) =>
  obj.rulesets.reduce(
    (sum, ruleset) => sum + (ruleset.allProfiles?.allUids?.length || 0),
    0
  )

const getTabContent = (tab, data) => {
  switch (tab) {
    case 'Delivery status':
      return <BroadcastManagerTaskInfoDelivery data={data} />

    case 'content':
      return <BroadcastManagerTaskInfoContent data={data} />

    case 'recipients':
      return <BroadcastManagerTaskInfoRecipients data={data} />

    default:
      return null
  }
}

const BackButton = styled(Flex)`
  &:hover {
    opacity: 1;
  }
`

const BroadcastManagerTaskInfo = () => {
  const location = useLocation()
  const history = useHistory()
  const data = location.state ? location.state.data : {}

  const [selectedTab, setSelectedTab] = useState(null)

  const onSetTabsRef = (ref) => setSelectedTab(ref.selectedTab)

  const {
    queries: { useEinsteinBroadcastTask },
  } = useApi({
    queries: ['useEinsteinBroadcastTask'],
  })

  const { data: einsteinBroadcastTask } = useEinsteinBroadcastTask({
    enabled: !!data.uid,
    variables: {
      uid: data.uid,
    },
  })

  if (einsteinBroadcastTask) {
    const { einsteinSentBroadcast } = einsteinBroadcastTask

    data.totalProfiles =
      !isEmpty(einsteinSentBroadcast.rulesets) &&
      einsteinSentBroadcast.rulesets[0].allProfiles
        ? getTotalProfiles(einsteinSentBroadcast)
        : 0

    data.deliveredProfiles = map(
      einsteinSentBroadcast.deliveredProfiles.edges,
      (device) => device.node
    )

    data.deliveredProfilesCount =
      einsteinSentBroadcast.deliveredProfiles.totalCount

    data.unDeliveredProfiles = einsteinSentBroadcast.undeliveredProfiles.edges
  }

  // const downloadBroadcastActions = [
  //   { id: 'downloadPDF', label: 'Download Broadcast log (PDF)' },
  //   { id: 'downloadCSV', label: 'Download Recipients list (CSV)' },
  // ]

  const goBack = () => {
    history.goBack()
  }

  return (
    <Box mt={2}>
      {/* Back Button */}
      <BackButton
        id="backBtn"
        data-testid="backBtn"
        alignItems="center"
        mb={1}
        ml="16px"
        hover={{ opacity: 0.6 }}
        cursor="pointer"
        onClick={goBack}
      >
        <FAIcon
          icon={faChevronLeft}
          color="darkestShade"
          fontWeight="500"
          style={{ fontSize: 12, fontWeight: 500, marginRight: 4 }}
        />
        <H5 letterSpacing="-0.08px" fontSize={14} color="darkestShade">
          Back
        </H5>
      </BackButton>

      {/* Title */}
      <Flex justifyContent="space-between" alignItems="center">
        <H1
          data-testid="broadcastTitle"
          ml={2}
        >{`Broadcast Log: ${data.broadcastName}`}</H1>
        <Flex>
          <PrimaryButton
            data-testid="duplicateBroadcastButton"
            borderRadius="8px"
            mr={2}
            onClick={() => {
              const dupData = { ...data, isDuplicate: true }
              history.push('/broadcast_create', {
                defaultData: dupData,
                isIndividualMode: false,
                defaultValue: [],
              })
            }}
            logEventProps={{
              subSource: EventsConstant.BROADCAST_TABLE_SOURCE,
              eventName: EventsConstant.BROADCAST_DUPLICATE,
              page: EventsConstant.BROADCAST_CREATE_PAGE,
            }}
          >
            Duplicate broadcast
          </PrimaryButton>

          {/* <DropdownMenu
            fontWeight="normal"
            actions={downloadBroadcastActions}
            label={
              <SecondaryOutlinedButton
                borderRadius="8px"
                endIcon={
                  <FAIcon
                    icon={faChevronDown}
                    style={{ color: 'darkestShade' }}
                  />
                }
              >
                Download
              </SecondaryOutlinedButton>
            }
          /> */}
        </Flex>
      </Flex>

      {/* Tab */}
      <Tabs
        m={2}
        headerProps={{
          borderBottom: '1px solid #ccc',
          overflow: 'auto',
          className: 'scrollbar-invisible',
          style: {
            whiteSpace: 'nowrap',
          },
          id: 'Account',
        }}
        tabsRef={onSetTabsRef}
        tabs={_.map(['Delivery status', 'content', 'recipients'], (tab) => ({
          id: tab,
          testId: `${tab}Tab`,
          label: _.startCase(tab),
          logEventProps: {
            subSource: EventsConstant.BROADCAST_HEAD_SOURCE,
            eventName: `${EventsConstant.BROADCAST_TAB_SELECTED} - ${tab}`,
            page: EventsConstant.BROADCAST_DETAILS_PAGE,
          },
        }))}
        postHogModule="broadcasts"
      />

      {/* Statistics */}
      {getTabContent(selectedTab, data)}
    </Box>
  )
}

export default BroadcastManagerTaskInfo
