import { Flex, Box, Tooltip, Text } from '@fivehealth/botero'
import {
  faChevronDown,
  faChevronUp,
  faImage,
  faText,
  faTrashAlt,
  faFilm,
  faFileAlt,
} from '@fortawesome/pro-regular-svg-icons'
import OutlinedBox from 'old-code/components/Box/OutlinedBox'
import ClickableIcon from 'old-code/components/ClickableIcon/ClickableIcon'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import BroadcastMessageTemplateImage from './BroadcastMessageTemplateImage'
import BroadcastMessageTemplateText from './BroadcastMessageTemplateText'
import BroadcastMessageTemplateMedia from './BroadcastMessageTemplateMedia'
import BroadcastMessageTemplateDocuments from './BroadcastMessageTemplateDocuments'

const BroadcastMessageTemplate = ({
  id,
  templateRef,
  templateForms,
  onDelete,
  selectedState,
  onSort,
  onClear,
  isDisabled = false,
  onFormChange,
  hideDelete = false,
  hideMoveUp = false,
  hideMoveDown = false,
  ...props
}) => {
  const [selected, setSelected] = useState(selectedState || 'text')

  const getTemplate = (template) => {
    switch (template) {
      case 'text':
        return (
          <BroadcastMessageTemplateText
            isDisabled={isDisabled}
            onFormChange={onFormChange}
            templateRef={templateRef}
            templateForms={templateForms}
            {...props}
          />
        )
      case 'image':
        return (
          <BroadcastMessageTemplateImage
            onFormChange={onFormChange}
            templateRef={templateRef}
            {...props}
          />
        )
      case 'media':
        return (
          <BroadcastMessageTemplateMedia
            id={id}
            onFormChange={onFormChange}
            templateRef={templateRef}
            {...props}
          />
        )
      case 'documents-media':
        return (
          <BroadcastMessageTemplateDocuments
            id={id}
            onFormChange={onFormChange}
            templateRef={templateRef}
            {...props}
          />
        )

      default:
        return null
    }
  }

  useEffect(() => {
    if (!_.isEmpty(selectedState)) {
      setSelected(selectedState)
    }
  }, [selectedState])

  const handleDelete = () => {
    onDelete(id)
    onFormChange()
  }

  return (
    <OutlinedBox mb={2}>
      <Flex
        p={1}
        justifyContent="space-between"
        alignItems="center"
        bg="lightestShade"
        borderTopRightRadius={10}
        borderTopLeftRadius={10}
      >
        <Flex>
          <Box mr="4px">
            <Tooltip
              tooltip={
                <Text fontSize="12px" color="white">
                  Text
                </Text>
              }
              toolTipOptions={{
                placement: 'bottom',
              }}
              toolTipElementProps={{
                maxWidth: 275,
                backgroundColor: '#000 !important',
                px: 1,
              }}
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <ClickableIcon
                disabled={isDisabled}
                icon={faText}
                color={_.isEqual(selected, 'text') ? 'primary' : 'darkestShade'}
                bgColor={
                  _.isEqual(selected, 'text') ? '#E8EAED' : 'transparent'
                }
                onClick={() => {
                  onClear(id)
                  setSelected('text')
                }}
                data-testid="text-template-option"
              />
            </Tooltip>
          </Box>

          <Box mr="4px">
            <Tooltip
              tooltip={
                <Text fontSize="12px" color="white">
                  Image/GIF
                </Text>
              }
              toolTipOptions={{
                placement: 'bottom',
              }}
              toolTipElementProps={{
                maxWidth: 275,
                backgroundColor: '#000 !important',
                px: 1,
              }}
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <ClickableIcon
                disabled={isDisabled}
                icon={faImage}
                color={
                  _.isEqual(selected, 'image') ? 'primary' : 'darkestShade'
                }
                bgColor={
                  _.isEqual(selected, 'image') ? '#E8EAED' : 'transparent'
                }
                onClick={() => {
                  onClear(id)
                  setSelected('image')
                }}
                data-testid="image-template-option"
              />
            </Tooltip>
          </Box>

          <Box mr="4px">
            <Tooltip
              tooltip={
                <Text fontSize="12px" color="white">
                  Media
                </Text>
              }
              toolTipOptions={{
                placement: 'bottom',
              }}
              toolTipElementProps={{
                maxWidth: 275,
                backgroundColor: '#000 !important',
                px: 1,
              }}
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <ClickableIcon
                customVideoAudio
                disabled={isDisabled}
                icon={faFilm}
                color={
                  _.isEqual(selected, 'media')
                    ? 'rgb(37, 107, 246)'
                    : 'rgb(105, 116, 129)'
                }
                bgColor={
                  _.isEqual(selected, 'media') ? '#E8EAED' : 'transparent'
                }
                onClick={() => {
                  onClear(id)
                  setSelected('media')
                }}
                mr={1}
                data-testid="media-template-option"
              />
            </Tooltip>
          </Box>

          <Box mr="4px">
            <Tooltip
              tooltip={
                <Text fontSize="12px" color="white">
                  Document
                </Text>
              }
              toolTipOptions={{
                placement: 'bottom',
              }}
              toolTipElementProps={{
                maxWidth: 275,
                backgroundColor: '#000 !important',
                px: 1,
              }}
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <ClickableIcon
                disabled={isDisabled}
                icon={faFileAlt}
                color={
                  _.isEqual(selected, 'documents-media')
                    ? 'primary'
                    : 'darkestShade'
                }
                bgColor={
                  _.isEqual(selected, 'documents-media')
                    ? '#E8EAED'
                    : 'transparent'
                }
                onClick={() => {
                  onClear(id)
                  setSelected('documents-media')
                }}
                mr={1}
                data-testid="documents-media-template-option"
              />
            </Tooltip>
          </Box>
        </Flex>
        <Flex>
          {!hideDelete && (
            <>
              {!hideMoveUp && (
                <ClickableIcon
                  icon={faChevronUp}
                  onClick={() => onSort(id, 'up')}
                />
              )}
              {!hideMoveDown && (
                <ClickableIcon
                  icon={faChevronDown}
                  onClick={() => onSort(id, 'down')}
                />
              )}

              <ClickableIcon
                icon={faTrashAlt}
                onClick={() => handleDelete(id)}
              />
            </>
          )}
        </Flex>
      </Flex>
      <Box
        pr={2}
        pl={2}
        pt={4}
        pb={4}
        borderTopStyle="solid"
        borderTopColor="mediumShade"
        borderTopWidth={2}
      >
        {getTemplate(selected)}
      </Box>
    </OutlinedBox>
  )
}

export default BroadcastMessageTemplate
