import { Box, FAIcon, Flex, Text } from '@fivehealth/botero'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import DropdownMenu from 'old-code/components/DropdownMenu/DropdownMenu'
import ErrorMessage from 'old-code/components/ErrorMessage/ErrorMessage'
import React, { useRef, useState, useEffect } from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import VariableTextMentionStyles from './VariableTextMentionStyles'

const isLastInputVariable = (currentString) => {
  const startIndex = currentString.lastIndexOf('@')
  if (startIndex !== -1) {
    const extractedSubstring = currentString.slice(startIndex)
    const regex = /@\[[^\]]+\]\(\d+\)$/
    if (regex.test(extractedSubstring.trim())) {
      return true
    }
  }
  return false
}

const VariableTextInput = ({
  onMessageChange,
  text,
  error,
  totalVariables,
  inputVariables,
  isDisabled = false,
  label = 'Welcome message',
}) => {
  const inputRef = useRef(null)
  const [value, setValue] = useState(text)
  const [selectedVariables, setSelectedVariables] = useState([])

  useEffect(() => {
    // back formatting the welcome menu text to the specific format that we require to display
    let formattedResult = text
    totalVariables.forEach((variable) => {
      formattedResult = formattedResult.replace(
        `\${${variable.value}}`,
        `@[  ${variable.display}  ](${variable.id})`
      )
    })
    setSelectedVariables([...inputVariables])
    setValue(formattedResult)
  }, [inputVariables])

  const handleChange = (_, templateInput, parsedInput) => {
    setValue(templateInput)

    // formatting the text to input variables format i.e ${variable}
    let formattedResult = parsedInput
    selectedVariables.forEach((variable) => {
      formattedResult = formattedResult.replace(
        variable.display,
        `\${${variable.value}}`
      )
    })
    onMessageChange(formattedResult)
  }
  const append = (tagText) => {
    const i = inputRef.current.selectionStart
    const { length } = selectedVariables.filter((e) => e.end <= i) // no. of tags before the cursor
    const extraSpaces = length * 10

    const isLastVariable = isLastInputVariable(
      value.substring(0, i + extraSpaces)
    )
    const x =
      `${value.substring(0, i + extraSpaces).trim()}` +
      `${
        isLastVariable
          ? ` @[  ${tagText.display}](${tagText.id}) ${value.substring(
              i + extraSpaces
            )}`
          : `@[  ${tagText.display}](${tagText.id}) ${value.substring(
              i + extraSpaces
            )}`
      }`
    const formattedText =
      `${text.substring(0, i + extraSpaces).trim()} ` +
      `\${${tagText.value}} ${text.substring(i + extraSpaces)}`
    setValue(x)
    onMessageChange(formattedText)
    inputRef.current.setSelectionRange(i + tagText.length + 1, 0)
  }

  const addTag = (taggedVariable) => {
    const updatedSelectedList = selectedVariables
    updatedSelectedList.push({
      id: taggedVariable.id,
      display: taggedVariable.display,
      value: taggedVariable.value,
      start: inputRef.current.selectionStart,
      end: inputRef.current.selectionStart + taggedVariable.display.length - 1,
    })
    setSelectedVariables(updatedSelectedList)
    append(taggedVariable)
  }

  const actions = [
    {
      id: 'userName',
      label: "User's name",
      onClick: () =>
        addTag({ id: 1, display: "User's name", value: 'full_name' }),
    },
    {
      id: 'userDesignation',
      label: "User's designation",
      onClick: () =>
        addTag({ id: 2, display: "User's designation", value: 'designation' }),
    },
    {
      id: 'userDepartment',
      label: "User's department",
      onClick: () =>
        addTag({ id: 3, display: "User's department", value: 'department' }),
    },
  ]

  const getTextInputStyles = () => ({
    control: {
      fontSize: 14,
      color: '#111824',
      fontWeight: '400',
      letterSpacing: '-0.08px',
      lineHeight: '24px',
    },
    '&multiLine': {
      control: {
        minHeight: 63,
      },
      highlighter: {
        padding: 12,
        border: '1px solid transparent',
        letterSpacing: '-0.08px',
        lineHeight: '24px',
      },
      input: {
        padding: 12,
        border: error ? '1px solid rgb(224, 81, 56)' : '1px solid #D5D9DE',
        borderRadius: 8,
        letterSpacing: '-0.08px',
        lineHeight: '24px',
      },
    },
  })

  return (
    <Box>
      <Flex mt={2} mb={1} justifyContent="space-between">
        <Text
          fontSize="12px"
          fontWeight="600"
          lineHeight="20px"
          letterSpacing="-0.08px"
          color="darkestShade"
        >
          {label}
        </Text>
        <DropdownMenu
          disabled={isDisabled}
          moveLeft="-96px"
          fontWeight="500"
          label={
            <Flex alignItems="center">
              <Text
                mr={1}
                fontSize={1}
                fontWeight="500"
                lineHeight="20px"
                letterSpacing="-0.08px"
                color="#256BF7"
              >
                Insert variable
              </Text>
              <FAIcon
                icon={faChevronDown}
                fontSize="14px"
                style={{ color: '#256BF7' }}
              />
            </Flex>
          }
          actions={actions}
          width="300px"
        />
      </Flex>

      <MentionsInput
        disabled={isDisabled}
        style={{ ...getTextInputStyles(), maxHeight: '300px', padding: '4px' }}
        data-testid="welcomeMessage"
        markup="@[  __display__  ](__id__)"
        value={value}
        onChange={handleChange}
        inputRef={inputRef}
      >
        <Mention
          disabled={isDisabled}
          trigger="~"
          data={totalVariables}
          style={VariableTextMentionStyles}
        />
      </MentionsInput>

      {error && <ErrorMessage error={error} />}
    </Box>
  )
}

export default VariableTextInput
