import React from 'react'
import { SecondaryOutlinedButton } from '@fivehealth/botero'
import { faFileCsv, faFileExcel } from '@fortawesome/pro-solid-svg-icons'
import Chip from 'old-code/components/Chip/Chip'
import DropdownMenu from 'old-code/components/DropdownMenu/DropdownMenu'
import usePostHog from 'old-code/customHooks/usePostHog'

const ExportDropdown = ({
  label,
  canExport,
  totalCount,
  handleExport,
  outputFormat,
  showCSV = true,
  showXLSX = true,
  showTemplate = false,
  setOutputFormat,
  exportActionClicked,
  LabelComponent,
  testId,
  logEventPropsAll = {},
  triggerWithoutOutputFormat = false,
  postHogModule = '',
}) => {
  const logPostHogEvent = usePostHog()
  const exportActions = [
    {
      id: 'exportAsCSV',
      testId: 'exportAsCSV',
      icon: faFileCsv,
      visibility: showCSV,
      label: 'Export as CSV',
      description: 'Export data in a CSV format',
      onClick: () => {
        logPostHogEvent(`${postHogModule}:export_dropdown_option_click`, {
          option: 'csv',
        })
        if (triggerWithoutOutputFormat || outputFormat === 'CSV') {
          handleExport('CSV')
          return
        }
        // TODO: Fix this please
        // eslint-disable-next-line no-param-reassign
        exportActionClicked.current = true
        setOutputFormat('CSV')
      },
      logEventProps: logEventPropsAll.csvExport,
    },
    {
      id: 'exportAsXLSX',
      testId: 'exportAsXLSX',
      icon: faFileExcel,
      label: 'Export as XLSX',
      visibility: showXLSX,
      description: 'Export data in a XLSX format',
      onClick: () => {
        logPostHogEvent(`${postHogModule}:export_dropdown_option_click`, {
          option: 'xlsx',
        })
        if (triggerWithoutOutputFormat || outputFormat === 'XLSX') {
          handleExport('XLSX')
          return
        }
        // TODO: Fix this please
        // eslint-disable-next-line no-param-reassign
        exportActionClicked.current = true
        setOutputFormat('XLSX')
      },
      logEventProps: logEventPropsAll.xlxsExport,
    },
    {
      id: 'exportAsXLSX',
      testId: 'exportAsXLSX',
      icon: faFileExcel,
      visibility: showTemplate,
      label: 'Export as XLSX',
      description: 'Export data in a XLSX format',
      onClick: () => {
        logPostHogEvent(`${postHogModule}:export_dropdown_option_click`, {
          option: 'xlsx',
        })
        if (triggerWithoutOutputFormat || outputFormat === 'TEMPLATE') {
          handleExport('TEMPLATE')
          return
        }
        // TODO: Fix this please
        // eslint-disable-next-line no-param-reassign
        exportActionClicked.current = true
        setOutputFormat('TEMPLATE')
      },
      logEventProps: logEventPropsAll.templateExport,
    },
  ]

  return (
    <DropdownMenu
      data-testid={testId}
      fontWeight="500"
      label={
        LabelComponent || (
          <SecondaryOutlinedButton
            disabled={!canExport || totalCount === 0}
            borderRadius="8px"
            ml={24}
            height="40px"
            endIcon={
              totalCount || totalCount === 0 ? (
                <Chip
                  fontWeight="600"
                  borderRadius="8px"
                  fontSize="12px"
                  padding="2px 8px"
                  height="24px"
                  display="flex"
                  alignItems="center"
                  flexGrow="0"
                  label={totalCount}
                  bg="lightShade"
                  textColor="fullShade"
                />
              ) : null
            }
            id={testId}
            data-testid={testId}
            onClick={() =>
              logPostHogEvent(`${postHogModule}:export_dropdown_click`)
            }
          >
            {label}
          </SecondaryOutlinedButton>
        )
      }
      actions={exportActions}
      width="300px"
    />
  )
}

export default ExportDropdown
