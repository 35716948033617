import React, { useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import {
  Box,
  FAIcon,
  Flex,
  H5,
  PrimaryButton,
  SecondaryOutlinedButton,
  Text,
} from '@fivehealth/botero'

import {
  faAngleDown,
  faAngleUp,
  faEdit,
  faExclamationTriangle,
  faMinusCircle,
  faPlusCircle,
  faUser,
} from '@fortawesome/pro-regular-svg-icons'

import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'

import { useModal } from 'old-code/context/ModalContext'
import UserManagementUploadConfirmation from './UserManagementUploadConfirmation'

const BackButton = styled(Flex)`
  &:hover {
    opacity: 0.8;
  }
`

const tabs = [
  {
    id: 'error',
    testId: 'error',
    title: 'Error',
    icon: faExclamationTriangle,
    fields: ['firstName', 'activateAfter', 'deactivateAfter', 'remarks'],
    description:
      'user profiles contain errors. Please refer the Remarks column for more details',
  },
  {
    id: 'new',
    testId: 'new',
    title: 'To be created/activated',
    icon: faPlusCircle,
    fields: ['firstName', 'designation', 'department'],
  },
  {
    id: 'update',
    testId: 'update',
    title: 'To be updated',
    icon: faEdit,
    fields: ['firstName', 'designation', 'department'],
  },
  {
    id: 'expiring',
    testId: 'expiring',
    title: 'To be deactivated',
    icon: faMinusCircle,
    fields: ['firstName', 'designation', 'department'],
  },
  {
    id: 'same',
    testId: 'same',
    title: 'No changes',
    icon: faUser,
    fields: ['firstName', 'designation', 'department'],
  },
]

const UserManagementUploadStats = ({
  showDashboard,
  showFullSync,
  showBulkUpdate,
  data,
}) => {
  const { openModal, closeModal } = useModal()
  const [expand, setExpand] = useState({})

  const uploadResponse = _.get(data, 'uploadResponse')
  const label = _.get(data, 'label')
  const excelOptions = _.get(data, 'excelOptions')

  const getUsers = (state) => {
    if (_.isEqual(state, 'error')) {
      return _.get(uploadResponse, 'invalid_rows', []).map((o) => ({
        ...o,
        activate_after: o.activate_after || '',
        deactivate_after: o.deactivate_after || '',
      }))
    }
    return _.get(uploadResponse, `sync_status.${state}`, [])
  }

  const handleOnClick = () => {
    if (label === 'Overwrite User List') {
      return showFullSync()
    }
    return showBulkUpdate()
  }

  const handleContinue = (action) => {
    openModal(
      <UserManagementUploadConfirmation
        action={action}
        label={label}
        excelOptions={excelOptions}
        fileId={uploadResponse.fileId}
        showDashboard={showDashboard}
        closeModal={closeModal}
      />,
      { width: '650px' },
      false
    )
  }

  return (
    <Box>
      {/* Back Button */}
      <BackButton
        id="backBtn"
        alignItems="center"
        mb={1}
        ml="16px"
        hover={{ opacity: 0.6 }}
        cursor="pointer"
        onClick={() => handleOnClick()}
      >
        <FAIcon
          icon={faChevronLeft}
          color="darkestShade"
          fontWeight="500"
          style={{ fontSize: 12, fontWeight: 500, marginRight: 4 }}
        />
        <H5 fontSize={14} color="darkestShade">
          {`Back to ${_.startCase(label)} File Upload`}
        </H5>
      </BackButton>

      {/* Title */}
      <Flex justifyContent="space-between" alignItems="center">
        <Text m={2} fontSize={5} fontWeight="700">
          {`${uploadResponse.valid_profile_count} user profiles found`}
        </Text>
        <Flex m={2} mr={5} alignItems="center" justifyContent="right">
          <PrimaryButton
            data-testid="userMangementUploadConfirmButton"
            borderRadius="8px"
            mr={3}
            onClick={() => handleContinue('confirmation')}
          >
            Continue
          </PrimaryButton>
          <SecondaryOutlinedButton
            borderRadius="8px"
            onClick={() => handleContinue('discard')}
          >
            Cancel
          </SecondaryOutlinedButton>
        </Flex>
      </Flex>

      {_.isNull(uploadResponse) ? null : (
        <Box m={2}>
          {_.map(tabs, (tab) => (
            <Box
              data-testid={tab.testId}
              key={tab.id}
              m={3}
              p={2}
              borderRadius="10px"
              borderStyle="solid"
              borderWidth={1}
              borderColor={
                _.isEqual(tab.id, 'error') &&
                !_.isEmpty(uploadResponse.invalid_rows)
                  ? 'red'
                  : 'darkestShade'
              }
              onClick={() =>
                setExpand({
                  [tab.id]: !_.get(expand, tab.id, false),
                })
              }
            >
              {/* Title */}
              <Flex justifyContent="space-between">
                <Flex alignItems="center">
                  <FAIcon
                    icon={tab.icon}
                    color={
                      _.isEqual(tab.id, 'error') &&
                      !_.isEmpty(uploadResponse.invalid_rows)
                        ? '#E05138'
                        : 'darkestShade'
                    }
                  />
                  <Text
                    ml={4}
                    fontWeight="600"
                    fontSize="18px"
                    color={
                      _.isEqual(tab.id, 'error') &&
                      !_.isEmpty(uploadResponse.invalid_rows)
                        ? '#E05138'
                        : 'fullShade'
                    }
                  >
                    {tab.title}
                  </Text>
                </Flex>
                <Flex justifyContent="right" alignItems="center">
                  <FAIcon
                    icon={
                      _.get(expand, tab.id, false) ? faAngleUp : faAngleDown
                    }
                  />
                </Flex>
              </Flex>

              {/* Tab */}
              <Box ml={6} mt={2}>
                {/* Stats */}
                <Text
                  m={1}
                  color={
                    _.isEqual(tab.id, 'error') &&
                    !_.isEmpty(uploadResponse.invalid_rows)
                      ? '#E05138'
                      : 'darkestShade'
                  }
                >
                  {`${getUsers(tab.id).length} ${_.get(
                    tab,
                    'description',
                    'users'
                  )}`}
                </Text>

                {/* Table */}
                {_.get(expand, tab.id, false) && (
                  <>
                    <Flex m={1} mt={3}>
                      {_.map(tab.fields, (field) => (
                        <Text
                          key={field}
                          fontWeight="600"
                          width="200px"
                          color="fullShade"
                        >
                          {_.startCase(field)}
                        </Text>
                      ))}
                    </Flex>
                    {_.map(getUsers(tab.id), (profile, index) => (
                      <Flex key={index} m={1}>
                        {_.map(tab.fields, (field) => (
                          <Text
                            key={`${index}-${field}`}
                            width="200px"
                            color="darkestShade"
                          >
                            {_.get(profile, _.snakeCase(field), '')}
                          </Text>
                        ))}
                      </Flex>
                    ))}
                  </>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default UserManagementUploadStats
