import {
  Box,
  Flex,
  InputField,
  Label,
  PrimaryButton,
  Radio,
  Text,
  useApi,
} from '@fivehealth/botero'
import BotSelect from 'old-code/components/BotSelect/BotSelect'
import SelectDate from 'old-code/components/SelectDate/SelectDate'
import { isAfter, isBefore } from 'date-fns'
import { find, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { getDateBefore, getDateTime, validateEmail } from 'Utils'
import useExportFile from 'old-code/customHooks/useExportFile'
import SimpleLoader from 'old-code/components/SimpleLoader/SimpleLoader'

import filterOptions, {
  calculateStartDate,
  dateRangeOptions,
  isCustomDateRange,
  radioOptions,
} from './AnalyticsHelper'
import EventsConstant from '../../config/constants/events.constants'

const ChatMessagesExport = ({ setShowStatusBar, parentPage }) => {
  const [filterData, setFilterData] = useState([])
  const [departments, setDepartments] = useState([])
  const [designations, setDesignations] = useState([])
  const [selectedDepartments, setSelectedDepartments] = useState([])
  const [selectedDesignations, setSelectedDesignations] = useState([])
  const [dateRange, setDateRange] = useState(null)
  const [email, setEmail] = useState('')
  const exportFileUsingLink = useExportFile()

  const defaultEndDate = new Date()
  const defaultStartDate = getDateBefore(1, defaultEndDate)
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [exportDestination, setExportDestination] = useState('download')

  const {
    queries: { useEinsteinSettings, useEinsteinExportChatMessages },
  } = useApi({
    queries: ['useEinsteinSettings', 'useEinsteinExportChatMessages'],
  })

  const { data: settingsData } = useEinsteinSettings({
    variables: {},
  })

  const mapOptions = (data) => data.map((obj) => obj.value)

  const createInput = () => {
    const startOn = calculateStartDate(startDate, dateRange)
    const endOn = getDateTime(endDate)
    const input = {
      startOn,
      endOn,
    }

    if (!find(selectedDepartments, (obj) => obj.value === 'all')) {
      input.departmentIn = mapOptions(selectedDepartments)
    }
    if (!find(selectedDesignations, (obj) => obj.value === 'all')) {
      input.designationIn = mapOptions(selectedDesignations)
    }
    if (!find(filterData, (obj) => obj.value === 'all')) {
      input.chatMessageTypeIn = filterData.map((filter) => filter.value)
    }
    if (!isEmpty(email)) {
      input.email = email
    }
    return input
  }

  const { data: exportResponse, refetch } = useEinsteinExportChatMessages({
    enabled: false,
    variables: createInput(),
  })

  useEffect(() => {
    if (
      exportResponse &&
      exportResponse.einsteinExportChatMessages.exportedCsvUrl
    ) {
      if (exportDestination === 'download') {
        const link = exportResponse.einsteinExportChatMessages.exportedCsvUrl
        exportFileUsingLink(link, 'chatMessages')
      } else {
        window.scrollTo(0, 0)
        setShowStatusBar(true)
      }
    }
  }, [exportResponse])

  useEffect(() => {
    const getOptions = (defaultLabel, groupLabel, data) => {
      const defaultOptions = {
        label: 'Default',
        options: [
          {
            label: defaultLabel,
            value: 'all',
          },
        ],
      }
      const options = data.map((designation) => ({
        label: designation,
        value: designation,
      }))
      const moreOptions = {
        label: groupLabel,
        options,
      }
      return [defaultOptions, moreOptions]
    }

    if (settingsData && settingsData.einsteinSettings) {
      const { profileFilterOptions } = settingsData.einsteinSettings
      const designationOptions = getOptions(
        'All designations',
        'Designations',
        profileFilterOptions.designations
      )

      const departmentOptions = getOptions(
        'All departments',
        'Departments',
        profileFilterOptions.departments
      )

      setDesignations(designationOptions)
      setDepartments(departmentOptions)
    }
  }, [settingsData])

  const GroupTitle = ({ title }) => (
    <Text color="fullShade" fontSize={2} fontWeight="600" lineHeight="24px">
      {title}
    </Text>
  )

  const ItemLabel = ({ label }) => (
    <Text
      color="darkestShade"
      fontSize="12px"
      fontWeight="600"
      lineHeight="20px"
      mb="4px"
      mt={3}
    >
      {label}
    </Text>
  )

  const isExportButtonDisabled = () =>
    isEmpty(filterData) ||
    isEmpty(selectedDepartments) ||
    isEmpty(selectedDesignations) ||
    !dateRange ||
    (exportDestination === 'email' && !validateEmail(email))

  const handleExportData = () => {
    setShowStatusBar(false)
    refetch()
  }

  const onStartDateChange = (date) => {
    if (isBefore(date, endDate)) {
      setStartDate(date)
    }
  }

  const onEndDateChange = (date) => {
    if (isAfter(date, startDate)) {
      setEndDate(date)
    }
  }

  const MultiCalendar = () => {
    if (isCustomDateRange(dateRange)) {
      return (
        <Flex alignItems="center" justifyContent="space-between">
          <Flex flexDirection="column">
            <ItemLabel label="Export: start date" />
            <SelectDate
              defaultDate={startDate}
              onDateChange={onStartDateChange}
            />
          </Flex>
          <Flex ml={3} flexDirection="column">
            <ItemLabel label="Export: end date" />
            <SelectDate defaultDate={endDate} onDateChange={onEndDateChange} />
          </Flex>
        </Flex>
      )
    }
    return null
  }

  const onEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const onExportDestinationChange = (option) => {
    if (option.id === 'download') {
      setEmail('')
    }
    setExportDestination(option.id)
  }

  const isOptionDisabled = (option, options) => {
    if (!isEmpty(options)) {
      const allSelected = find(options, (obj) => obj.value === 'all')
      if (allSelected) {
        return true
      }
      return option.value === 'all'
    }
    return false
  }

  if (!designations || !departments) {
    return <SimpleLoader />
  }
  return (
    <Box width="608px">
      <Flex mt={3} justifyContent="space-between" alignItems="center">
        <Text color="fullShade" fontSize={2} fontWeight="600" lineHeight="24px">
          Export Message logs
        </Text>
        <PrimaryButton
          disabled={isExportButtonDisabled()}
          justifyContent="flex-end"
          borderRadius="8px"
          onClick={handleExportData}
          logEventProps={{
            subSource: EventsConstant.ANALYTICS_MESSAGE_LIG,
            eventName: `${EventsConstant.EXPORT_CHAT_MESSAGES} - ${exportDestination}`,
            page: parentPage,
          }}
        >
          Export
        </PrimaryButton>
      </Flex>
      <Box
        borderColor="mediumShade"
        borderWidth={1}
        borderStyle="solid"
        borderRadius={8}
        p={2}
        mt={2}
      >
        <GroupTitle title="Define export" />
        <ItemLabel label="Filter data" />
        <BotSelect
          options={filterOptions}
          onChange={(selected) => setFilterData(selected)}
          isOptionDisabled={(option) => isOptionDisabled(option, filterData)}
        />
        <ItemLabel label="Select department(s)" />
        <BotSelect
          options={departments}
          onChange={(selected) => setSelectedDepartments(selected)}
          isOptionDisabled={(option) =>
            isOptionDisabled(option, selectedDepartments)
          }
        />
        <ItemLabel label="Select designation(s)" />
        <BotSelect
          options={designations}
          onChange={(selected) => {
            setSelectedDesignations(selected)
          }}
          isOptionDisabled={(option) =>
            isOptionDisabled(option, selectedDesignations)
          }
        />
      </Box>
      <Box
        borderColor="mediumShade"
        borderWidth={1}
        borderStyle="solid"
        borderRadius={8}
        p={2}
        mt={2}
      >
        <GroupTitle title="Define export date range" />
        <ItemLabel label="Date range" />
        <BotSelect
          width="276px"
          isMulti={false}
          isClearable={false}
          options={dateRangeOptions}
          onChange={(selected) => setDateRange(selected)}
        />
        <MultiCalendar />
      </Box>
      <Box
        borderColor="mediumShade"
        borderWidth={1}
        borderStyle="solid"
        borderRadius={8}
        p={2}
        mt={2}
      >
        <GroupTitle title="Output format" />
        <ItemLabel label="Export destination" />
        <Flex alignItems="center">
          {radioOptions.map((option) => (
            <Label key={option.id} width="unset">
              <Radio
                checked={exportDestination === option.id}
                onChange={() => onExportDestinationChange(option)}
              />
              <Text
                color="fullShade"
                fontSize="14px"
                fontWeight="400"
                lineHeight="24px"
                mr={3}
              >
                {option.label}
              </Text>
            </Label>
          ))}
        </Flex>
        {exportDestination === 'email' && (
          <>
            <ItemLabel label="Recipient email address" />
            <InputField
              sanitize
              width="100%"
              placeholder="Enter email address"
              onChange={onEmailChange}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

export default ChatMessagesExport
