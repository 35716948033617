/* eslint-disable import/no-unresolved */
import {
  Box,
  Flex,
  useApi,
  PrimaryButton,
  SecondaryOutlinedButton,
  Text,
  sendLog,
} from '@fivehealth/botero'

import React from 'react'
import { useHistory } from 'react-router-dom'
import { extractTextBeforeDelimiter } from 'Utils'
import AddToFixedGroup from './AddToFixedGroup'
import EventsConstant from '../../../../config/constants/events.constants'
import BotmdCryBaby from '../../../../assets/bot-crying-avatar.svg'
import DropdownMenu from '../../../../components/DropdownMenu/DropdownMenu'
import { useModal } from '../../../../context/ModalContext'
import usePostHog from '../../../../customHooks/usePostHog'

import { AlertModalConfirmation } from '../../../AdminSettings/helper'

const UserManagementTableRowEdit = ({ data, showInfoView, dropdownRef }) => {
  const history = useHistory()
  const { openModal, closeModal } = useModal()
  const logPostHogEvent = usePostHog()
  const deactivated = data.status === 'DEACTIVATED'

  const {
    queries: {
      useDirectoryProfileActivate,
      useDirectoryProfileDeactivate,
      useBulkDirectoryActions,
    },
  } = useApi({
    queries: [
      'useDirectoryProfileActivate',
      'useDirectoryProfileDeactivate',
      'useBulkDirectoryActions',
    ],
  })

  const addToFixedGroup = () =>
    openModal(
      <AddToFixedGroup
        selectedUids={[data.uid]}
        closeModal={closeModal}
        openModal={openModal}
        clearSelectBar={() => {}}
      />,
      { overflow: 'scroll' },
      false
    )

  const { mutateAsync: updateDirectoryProfileActivation } =
    useDirectoryProfileActivate({
      variables: {},
      onSuccess: (alldata) => {
        const { queryClient, data: resultData } = alldata
        if (resultData?.errors?.length) {
          const modalProps = {
            title: resultData?.errors[0]?.message.replace(/[`'[\]/]/gi, ''),
            description: null,
            botmdImage: BotmdCryBaby,
          }

          openModal(
            <AlertModalConfirmation
              logEventProps={{
                subSource: EventsConstant.ADMIN_INFO_PAGE,
                eventName: EventsConstant.ADMIN_PROFILE_EDITED_SUCCESSFULLY,
                page: EventsConstant.ADMIN_INFO_PAGE,
              }}
              closeModal={() => {
                closeModal()
                queryClient.invalidateQueries('einsteinAdminGetByUid')
              }}
              {...modalProps}
            />
          )
          sendLog(
            {
              subSource: EventsConstant.USER_LIST_ACTION_INACTIVE,
              page: EventsConstant.USER_MANAGEMENT_PAGE,
              metaData: JSON.stringify(resultData.errors || []),
            },
            EventsConstant.USER_ERROR_ACTIVATE
          )
        }
        if (queryClient) {
          queryClient.invalidateQueries('directoryProfiles')
          queryClient.invalidateQueries('allDirectoryProfiles')
        }
      },
    })

  const { mutateAsync: updateDirectoryProfileDeactivation } =
    useDirectoryProfileDeactivate({
      variables: {},
      onSuccess: ({ queryClient, data: resultData }) => {
        if (resultData?.errors?.length) {
          sendLog(
            {
              subSource: EventsConstant.USER_LIST_ACTION_ACTIVE,
              page: EventsConstant.USER_MANAGEMENT_PAGE,
              metaData: JSON.stringify(resultData.errors || []),
            },
            EventsConstant.USER_ERROR_DEACTIVATE
          )
        }
        if (queryClient) {
          queryClient.invalidateQueries('directoryProfiles')
          queryClient.invalidateQueries('allDirectoryProfiles')
        }
      },
    })
  const { mutateAsync: updateBulkDirectoryActions } = useBulkDirectoryActions({
    variables: {},
    onSuccess: ({ queryClient: successQueryClient }) => {
      if (successQueryClient) {
        successQueryClient.invalidateQueries('directoryProfiles')
        successQueryClient.invalidateQueries('allDirectoryProfiles')
      }
    },
  })

  const showActivationPromptModal = ({
    dataParam,
    actionCallback,
    actionLabel,
    bgcolor,
  }) => {
    openModal(
      <Box
        width="720px"
        p={4}
        data-testid={`prompt_container-${actionLabel.toLowerCase()}`}
      >
        <Text fontWeight="bold" fontSize={3}>
          {`${actionLabel} ${dataParam.name.split(':')[0].trim()} ?`}
        </Text>
        <Text mt={3} fontSize={2}>
          {`Are you sure you want to ${actionLabel.toLowerCase()} the user "${dataParam.name
            .split(':')[0]
            .trim()}"?`}
        </Text>
        <Flex mt={3} justifyContent="right" alignItems="center">
          <SecondaryOutlinedButton
            data-testid={`prompt_cancel_btn-${actionLabel.toLowerCase()}`}
            borderRadius="8px"
            mr={3}
            onClick={() => {
              closeModal()
            }}
          >
            Cancel
          </SecondaryOutlinedButton>
          <PrimaryButton
            data-testid={`prompt_submit_btn-${actionLabel.toLowerCase()}`}
            borderRadius="8px"
            onClick={() => {
              actionCallback()
              closeModal()
            }}
            style={{
              backgroundColor: bgcolor,
            }}
          >
            {actionLabel}
          </PrimaryButton>
        </Flex>
      </Box>,
      {},
      false
    )
  }
  const sendBroadcast = () => {
    logPostHogEvent('broadcasts:create_broadcast_save_draft_button')
    history.push('/broadcast_create', {
      defaultData: {
        recipients: [
          {
            uid: data.uid,
            value: data.uid,
            label: `${extractTextBeforeDelimiter(data.name, ':')}`,
            type: 'userUID',
          },
        ],
      },
      isIndividualMode: true,
      defaultValue: data.uid,
    })
  }

  const activeActions = [
    {
      id: 'editDetails',
      testId: 'editDetails',
      label: 'Edit User',
      onClick: () => showInfoView({ ...data, openEdit: true }),
      logEventProps: {
        subSource: EventsConstant.USER_LIST_ACTION_ACTIVE,
        eventName: EventsConstant.USERS_EDIT_FORM_OPENED,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
    {
      id: 'addToFixedGroup',
      testId: 'addToFixedGroup',
      label: 'Add to User Group',
      onClick: () => addToFixedGroup(data),
      logEventProps: {
        subSource: EventsConstant.USER_LIST_ACTION_ACTIVE,
        eventName: EventsConstant.USERS_GROUP_FORM_OPENED,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
    {
      id: 'sendBroadcast',
      testId: 'sendBroadcast',
      label: 'Send Broadcast',
      onClick: () => sendBroadcast(),
      logEventProps: {
        subSource: EventsConstant.USER_LIST_ACTION_ACTIVE,
        eventName: EventsConstant.USERS_SEND_BROADCAST_OPENED,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
    {
      id: 'resendOnboardingEmail',
      testId: 'resendOnboardingEmail',
      label: 'Resend Onboarding Email',
      onClick: () => {
        updateBulkDirectoryActions({
          input: {
            allUids: [data.uid],
            opType: 'RESEND_ACTIVATION_EMAIL',
          },
        })
      },
      logEventProps: {
        subSource: `${EventsConstant.USER_LIST_BULK_ACTIONS} - Resend Activation Email`,
        eventName: EventsConstant.USERS_SEND_BROADCAST_OPENED,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
    {
      id: 'deactivate',
      testId: 'deactivate',
      label: 'Deactivate User',
      color: 'red',
      divider: true,
      onClick: () => {
        showActivationPromptModal({
          dataParam: data,
          actionCallback: () =>
            updateDirectoryProfileDeactivation({
              input: {
                uid: data.uid,
              },
            }),
          actionLabel: 'Deactivate Account',
          bgcolor: '#E05138',
        })
      },
      logEventProps: {
        subSource: EventsConstant.USER_LIST_ACTION_ACTIVE,
        eventName: EventsConstant.USERS_DEACTIVATE_MODAL_OPENED,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
  ]

  const inActiveActions = [
    {
      id: 'editDetails',
      testId: 'editDetails',
      label: 'View Details',
      onClick: () => showInfoView({ ...data, openEdit: false }),
      logEventProps: {
        subSource: EventsConstant.USER_LIST_ACTION_INACTIVE,
        eventName: EventsConstant.USERS_EDIT_FORM_OPENED,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
    {
      id: 'addToFixedGroup',
      testId: 'addToFixedGroup',
      label: 'Add To User Group',
      enable: false,
      logEventProps: {
        subSource: EventsConstant.USER_LIST_ACTION_INACTIVE,
        eventName: EventsConstant.USERS_GROUP_FORM_OPENED,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
    {
      id: 'sendBroadcast',
      testId: 'sendBroadcast',
      label: 'Send Broadcast',
      enable: false,
      logEventProps: {
        subSource: EventsConstant.USER_LIST_ACTION_INACTIVE,
        eventName: EventsConstant.USERS_SEND_BROADCAST_OPENED,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
    {
      id: 'reactive',
      testId: 'reactive',
      label: 'Reactivate Account',
      divider: true,
      onClick: () => {
        showActivationPromptModal({
          dataParam: data,
          actionCallback: () =>
            updateDirectoryProfileActivation({
              input: {
                uid: data.uid,
                deactivatedOn: null,
                deactivateAfter: null,
              },
            }),
          actionLabel: 'Reactivate',
          bgcolor: 'primary',
        })
      },
      logEventProps: {
        subSource: EventsConstant.USER_LIST_ACTION_INACTIVE,
        eventName: EventsConstant.USERS_REACTIVATE_MODAL_OPENED,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
  ]

  return (
    <DropdownMenu
      fontWeight="normal"
      testId="elipsis"
      actions={deactivated ? inActiveActions : activeActions}
      dropdownRef={dropdownRef}
      logPostHogEvent={logPostHogEvent}
      postHogModule="user_list"
    />
  )
}

export default UserManagementTableRowEdit
