/* eslint-disable import/no-unresolved */
import {
  Box,
  FAIcon,
  Flex,
  H5,
  Select,
  PrimaryButton,
  SecondaryOutlinedButton,
  Text,
  useApi,
  sendLog,
} from '@fivehealth/botero'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import BotWidget from 'old-code/components/BotWidget/BotWidget'
import Chip from 'old-code/components/Chip/Chip'
import StatusBar from 'old-code/components/Status/StatusBar'
import Form from 'old-code/components/Form/Form'
import {
  get,
  isEmpty,
  map,
  includes,
  filter,
  chain,
  isEqual,
  pullAt,
  lt,
  flatMap,
  gt,
} from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'
import { generateRandomId, parseErrorMessageValue } from 'Utils'
import getCustomStyles from 'old-code/components/BotSelect/Helper'
import RouteLeaveGuard from 'old-code/components/RouteLeaveGuard/RouteLeaveGuard'
import { useEinsteinAdministratorProfile } from 'old-code/context/EinsteinAdministratorContext'
import Config from 'Config'
import usePostHog from 'old-code/customHooks/usePostHog'
import BotMdAvatar from '../../../assets/bot-table-avatar.svg'
import BroadcastMessageTemplate from './BroadcastMessageTemplate'
import { getCarouselTemplateData } from './Utils'
import EventsConstant from '../../../config/constants/events.constants'
import { useModal } from '../../../context/ModalContext'
import AlertModal from '../../../components/Modals/AlertModal'
import BotmdCryBaby from '../../../assets/bot-crying-avatar.svg'
import { AlertModalConfirmation } from '../../AdminSettings/helper'

const MAX_TEMPLATE_COUNT = 3
const eventLogDataBroadcastHelper = (data, scheduleMessage) => {
  const result = {
    status: data?.input?.status,
    scheduledFor: data?.input?.scheduledFor,
  }
  const messageTypes = []
  data?.input?.messages?.forEach((ele) => messageTypes.push(ele.message.type))
  result.messageTypes = messageTypes
  const eventName =
    result.status === 'SCHEDULED'
      ? `${EventsConstant.SEND_BROADCAST} - ${scheduleMessage}`
      : `${EventsConstant.BROADCAST_SAVE_DRAFT} - ${scheduleMessage}`
  sendLog(
    {
      subSource: EventsConstant.BROADCAST_HEAD_SOURCE,
      page: EventsConstant.BROADCAST_CREATE_PAGE,
      metaData: JSON.stringify(result),
    },
    eventName
  )
  return result
}

const CirclularChip = ({ label }) => (
  <Chip
    label={label}
    color="primary"
    bg="#BEDBFD50"
    borderRadius="50%"
    fontSize={12}
    fontWeight="bold"
    width="14px"
    style={{ boxSizing: 'content-box' }}
  />
)

const BroadcastMessageCreate = () => {
  const formRef = useRef()
  const { openModal, closeModal } = useModal()
  const [formTouched, setFormTouched] = useState(false)
  const [rerenderForm, setRerenderForm] = useState(false)
  const templateRef = useRef([])
  const [templates, setTemplates] = useState([{ tmpId: generateRandomId(32) }])
  const [uidIndexMap, setUidIndexMap] = useState({})
  const [isDraft, setIsDraft] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const [saveAction, setSaveAction] = useState('')

  const [showStatusBarMessage, setShowStatusBarMessage] = useState('')
  const defaultData = location.state ? location.state.defaultData : {}
  const isEditMode = location.state ? location.state.isEditMode : false

  const isIndividualMode = location.state
    ? location.state.isIndividualMode
    : false
  const defaultValue = location.state ? location.state.defaultValue : []
  const [renderPreviewPane, setRenderPreviewPane] = useState(false)
  const lastUpdated = useRef(new Date())
  const [defaultSelectOptions, setDefaultSelectOptions] = useState(
    isIndividualMode ? defaultValue : []
  )
  const [open, setOpen] = useState(true)
  const [savedSuccess, setSavedSuccess] = useState(false)
  const [pendingLocation, setPendingLocation] = useState(null)

  const [saveActionChanged, setSaveActionChanged] = useState('')
  const [readyForNavigation, setReadyForNavigation] = useState(false)
  const [shouldNavigateToUserGroup, setShouldNavigateToUserGroup] =
    useState(false)
  const [draftChanged, setDraftChanged] = useState(false)
  const [defaultRecepientChanged, setDefaultRecepientChanged] = useState(false)
  const [defaultOnetimeBroadcastChanged, setDefaultOnetimeBroadcastChanged] =
    useState(false)
  const [draftEmpty, setDraftEmpty] = useState(false)

  const einsteinAdmin = get(
    useEinsteinAdministratorProfile(),
    'einsteinAdmin',
    {}
  )

  lastUpdated.current = new Date()
  const BackButton = styled(Flex)`
    &:hover {
      opacity: 0.8;
    }
  `

  const onSetFormRef = (ref) => {
    formRef.current = ref
  }

  const onSetTemplateRef = (ref, index) => {
    templateRef.current[index] = ref
  }

  const goBack = () => {
    history.goBack()
  }

  const {
    queries: {
      useEinsteinBroadcastTasksUpdate,
      useEinsteinBroadcastTasksCreate,
      useAllProfilesGroups,
      useEinsteinBroadcastDelete,
    },
  } = useApi({
    queries: [
      'useEinsteinBroadcastTasksUpdate',
      'useEinsteinBroadcastTasksCreate',
      'useAllProfilesGroups',
      'useEinsteinBroadcastDelete',
    ],
  })

  const updateRequestsRef = useRef([])
  const logPostHogEvent = usePostHog()

  const { data: profilesGroupData } = useAllProfilesGroups({
    variables: { active: true },
  })

  const checkOnlyRecipientsExist = (obj) =>
    Object.keys(obj).length === 1 && 'recipients' in obj

  const { mutateAsync: deleteBroadcast } = useEinsteinBroadcastDelete({
    variables: {},
    onSuccess: ({ queryClient }) => {
      setSavedSuccess(true)
      goBack()
      if (queryClient) {
        queryClient.invalidateQueries('einsteinBroadcasts')
        queryClient.invalidateQueries('einsteinSentBroadcasts')
      }
    },
  })

  const profilesGroup = chain(profilesGroupData)
    .get('pages', [])
    .flatMap((page) => page || [])
    .value()

  const { mutateAsync: updateBroadcastTask } = useEinsteinBroadcastTasksUpdate({
    variables: {},
    onSuccess: ({ queryClient, data: resultData }) => {
      if (updateRequestsRef.current.length === 0) {
        closeModal()
      }
      if (resultData.einsteinBroadcastUpdate) {
        sendLog(
          {
            subSource: EventsConstant.BROADCAST_HEAD_SOURCE,
            page: EventsConstant.BROADCAST_CREATE_PAGE,
            metaData: JSON.stringify(resultData.einsteinBroadcastUpdate),
          },
          EventsConstant.BROADCAST_UPDATE_SUCCESSFULLY
        )
        setSavedSuccess(true)
        if (queryClient) {
          queryClient.invalidateQueries('einsteinBroadcasts')
        }
        setReadyForNavigation(true)
      } else if (resultData.errors) {
        sendLog(
          {
            subSource: EventsConstant.BROADCAST_HEAD_SOURCE,
            page: EventsConstant.BROADCAST_CREATE_PAGE,
            metaData: JSON.stringify(resultData.errors),
          },
          EventsConstant.BROADCAST_UPDATE_ERROR
        )
        const errors = resultData.errors.map((err) => {
          const error = parseErrorMessageValue(err)
          // TEMP: BE should send this message
          if (
            error ===
            '`scheduled_for` must be set in the future for status scheduled.'
          ) {
            return 'Broadcast date must be set in the future.'
          }
          return error
        })
        setShowStatusBarMessage(errors)
      }
    },
  })
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const { mutateAsync: createBroadcastTask } = useEinsteinBroadcastTasksCreate({
    variables: {},
    onSuccess: async ({ queryClient, data: resultData }) => {
      if (
        resultData.einsteinBroadcastCreate &&
        updateRequestsRef?.current?.length > 0
      ) {
        // Send each subsequent update request
        openModal(
          <AlertModal
            handleClose={() => {}}
            title="Create Broadcast In Progress"
            botmdImage={BotMdAvatar}
            description="Sending Broadcast in progress, please wait"
            primaryButton={{
              text: 'Close',
              onClick: () => {
                closeModal()
              },
            }}
          />
        )

        const processRequestsSequentially = updateRequestsRef.current.reduce(
          (accPromise, request) =>
            accPromise.then(async () => {
              request.input.uid =
                resultData?.einsteinBroadcastCreate?.einsteinBroadcast.uid
              await updateBroadcastTask(request)
              return delay(200)
            }),
          Promise.resolve()
        )

        processRequestsSequentially
          .then(() => {
            // Clear the ref after all updates have been sent
            updateRequestsRef.current = []
          })
          .catch(() => {
            // Handle any errors
            updateRequestsRef.current = []
          })
        return
      }
      if (resultData.einsteinBroadcastCreate) {
        if (
          defaultData &&
          defaultData.status === 'DRAFT' &&
          saveAction === 'SCHEDULED' &&
          defaultData.uid
        ) {
          // delete broadcast
          const variables = {
            input: {
              uid: defaultData.uid,
            },
          }
          deleteBroadcast(variables)
        } else {
          setSavedSuccess(true)

          if (queryClient) {
            queryClient.invalidateQueries('einsteinBroadcasts')
          }
        }
        sendLog(
          {
            subSource: EventsConstant.BROADCAST_HEAD_SOURCE,
            page: EventsConstant.BROADCAST_CREATE_PAGE,
            metaData: JSON.stringify(resultData.einsteinBroadcastCreate),
          },
          EventsConstant.BROADCAST_CREATED_SUCCESSFULLY
        )
        setReadyForNavigation(true)
      } else if (resultData.errors) {
        // if (resultData.status === 403) {
        const modalProps = {
          title: 'Failed to create new Broadcast',
          description: 'Please check your input and try again later.',
          botmdImage: BotmdCryBaby,
        }
        openModal(
          <AlertModalConfirmation
            closeModal={() => {
              closeModal()
            }}
            {...modalProps}
          />
        )
        // }
        sendLog(
          {
            subSource: EventsConstant.BROADCAST_HEAD_SOURCE,
            page: EventsConstant.BROADCAST_CREATE_PAGE,
            metaData: JSON.stringify(resultData.errors),
          },
          EventsConstant.BROADCAST_CREATED_ERROR
        )
        const errors = resultData.errors.map((err) =>
          parseErrorMessageValue(err)
        )
        setShowStatusBarMessage(errors)
      }
    },
  })

  const getUserGroupsDropdown = () => {
    const defaultOptions = get(defaultData, 'recipients', [])
    if (defaultData && defaultData.rulesets) {
      const directories = chain(defaultData.rulesets)
        .filter((ruleset) => !ruleset.uid)
        .flatMap((directory) => directory.directoryProfiles)
        .value()
      const directoryOptionsToAdd = chain(directories)
        .flatMap((directory) => ({
          uid: directory.uid,
          label: directory.firstName.concat(' ').concat(directory.lastName),
          value: directory.uid,
          type: 'userUID',
          addOptions: true,
        }))
        .value()
      defaultOptions.push(...directoryOptionsToAdd)
    }
    const opts = chain(defaultOptions)
      .flatMap((recipient) => {
        if (get(recipient, 'addOptions') === 'false') {
          return []
        }
        return recipient
      })
      .uniq()
      .value()

    const allProfiles = {
      uid: 'allProfiles',
      name: 'All Hospital Users',
      divider: true,
      label: 'All Hospital Users',
      description: 'Fixed group to send to all users',
      value: 'allProfiles',
      isdisabled: true,
      ruleset: {},
      createdBy: {},
    }

    const isSuperAdmin = einsteinAdmin?.isSuper || false
    const defOptions = {
      label: 'default Group',
      options: isSuperAdmin ? [allProfiles] : [],
    }

    const options = [
      ...opts,
      ...map(profilesGroup, (group) => ({
        uid: group.uid,
        label: group.name,
        divider: true,
        type: 'profilesGroup',
        value: group,
      })),
    ]

    const supersetOptions = [
      ...opts,
      ...map(profilesGroup, (group) => ({
        uid: group.uid,
        label: group.name,
        divider: true,
        type: 'profilesGroup',
        value: group,
      })),
      allProfiles,
    ]

    const optionMore = { label: 'Created Groups', options }
    const finalOptions = [{ ...defOptions }, { ...optionMore }]

    if (
      defaultSelectOptions.length === 1 &&
      defaultSelectOptions[0] === 'allProfiles'
    ) {
      options.push({
        uid: 'allProfiles',
        name: 'All Hospital Users',
        divider: true,
        label: 'All Hospital Users',
        description: 'Fixed group to send to all users',
        value: 'allProfiles',
        isdisabled: true,
        ruleset: {},
        createdBy: {},
      })
    }

    if (formRef.current && defaultData && !isEmpty(defaultSelectOptions)) {
      // indicates first load state, so that we don't end up resetting the form, needs a major rewrite of select component
      if (saveAction.length === 0 && !defaultRecepientChanged) {
        formRef.current.updateFormData(
          'recipients',
          filter(options, (option) =>
            includes(defaultSelectOptions, option.uid)
          )
        )
      }
      if (defaultData?.sentOn && !defaultOnetimeBroadcastChanged) {
        setTimeout(() => {
          formRef.current.updateFormData(
            'scheduledFor',
            get(defaultData, 'sentOn', '')
          )
        }, 200)
      }
    }

    return (
      ((!isEmpty(finalOptions) &&
        isEmpty(defaultSelectOptions) &&
        isEmpty(defaultData)) ||
        (!isEmpty(defaultData) &&
          !isEmpty(defaultSelectOptions) &&
          !isEmpty(options))) && (
        <div data-testid="searchGroupsInput">
          <Box style={{ maxWidth: '502px' }}>
            <Select
              classNames={{ control: () => 'target-border' }}
              styles={getCustomStyles('100%')}
              placeholder="Search groups"
              options={finalOptions}
              defaultValue={filter(supersetOptions, (option) =>
                includes(defaultSelectOptions, option.uid)
              )}
              isMulti
              isClearable
              isOptionDisabled={(option) => {
                if (!isEmpty(formRef.current.getFormData())) {
                  const selectedUids = chain(
                    formRef.current.getFormData().recipients
                  )
                    .filter((opt) => opt.uid === 'allProfiles')
                    .value()
                  if (selectedUids.length > 0) {
                    return true
                  }
                  if (
                    formRef.current.getFormData() &&
                    formRef.current.getFormData().recipients &&
                    formRef.current.getFormData().recipients.length > 0
                  ) {
                    return option.uid === 'allProfiles'
                  }
                  return false
                }
                return false
              }}
              onChange={(selected) => {
                setDefaultRecepientChanged(true)
                formRef.current.updateFormData('recipients', selected)
              }}
            />
          </Box>
        </div>
      )
    )
  }

  useEffect(() => {
    localStorage.removeItem('selected_broadcast_tab')
    if (defaultData && defaultData.rulesets) {
      let selectOptions = []
      const profileOpts = chain(defaultData.rulesets)
        .flatMap((ruleset) => ruleset.uid)
        .filter((ruleset) => ruleset != null)
        .value()
      const recs = chain(defaultData.rulesets)
        .flatMap((ruleset) => ({
          uid: ruleset.uid,
          label: ruleset.name,
          type: 'profilesGroup',
          value: ruleset,
        }))
        .filter((ruleset) => ruleset != null)
        .value()
      selectOptions = [...profileOpts]
      if (!isEmpty(recs)) {
        formRef.current.updateFormData('recipients', recs)
      }
      const directories = chain(defaultData.rulesets)
        .filter((ruleset) => !ruleset.uid)
        .flatMap((directory) => directory.directoryProfiles)
        .value()
      const directoryOptions = chain(directories)
        .flatMap((directory) => directory.uid)
        .filter((directory) => directory != null)
        .value()
      if (directoryOptions && !isEmpty(directoryOptions)) {
        selectOptions = [...selectOptions, ...directoryOptions]
      }
      if (isEmpty(selectOptions)) {
        selectOptions = []
        if (isEmpty(defaultData.rulesets)) {
          selectOptions.push('empty')
        } else {
          selectOptions.push('allProfiles')
        }
      }
      setDefaultSelectOptions(selectOptions)
    }
    if (defaultData && defaultData.allSelectedUsers) {
      const selectOptions = []
      selectOptions.push('allSelectedUsers')
      setDefaultSelectOptions(selectOptions)
    }
    const messages = get(defaultData, 'messages', [])
    if (!isEmpty(messages)) {
      setIsDraft(true)

      if (!isEmpty(messages)) {
        setTemplates([])
      }
      const savedTemplates = []
      messages.map(({ message }) => {
        if (message.type === 'text') {
          const templateData = map(templateRef.current, () => ({
            tmpId: generateRandomId(32),
            button: null,
            selected: 'text',
            switch: false,
            template: {
              message: message.text,
            },
          }))
          templateData.selected = 'text'
          savedTemplates.push(templateData[0])
        } else if (message.type === 'paragraphs') {
          const templateData = map(templateRef.current, () => ({
            tmpId: generateRandomId(32),
            button: null,
            selected: 'text',
            switch: false,
            template: {
              title: message.paragraphs[0].title,
              message: message.paragraphs[1].text,
            },
          }))
          templateData.selected = 'text'
          savedTemplates.push(templateData[0])
        } else if (message.type === 'sections') {
          const templateData = map(templateRef.current, () => ({
            tmpId: generateRandomId(32),
            button: {
              buttonText: message.sections[1].actions[0].button.text,
              websiteURL: message.sections[1].actions[0].button.url,
            },
            selected: 'text',
            switch: true,
            template: {
              title:
                message.sections[0].paragraphs.length > 1
                  ? message.sections[0].paragraphs[0].title
                  : '',
              message:
                message.sections[0].paragraphs.length > 1
                  ? message.sections[0].paragraphs[1].text
                  : message.sections[0].paragraphs[0].text,
            },
          }))
          templateData.selected = 'text'
          savedTemplates.push(templateData[0])
        } else if (message.type === 'image') {
          const templateData = map(templateRef.current, () => ({
            tmpId: generateRandomId(32),
            button: null,
            switch: false,
            selected: 'image',
            url_upload: message.image.url,
            image_source: 'websiteLink',
            template: {
              url_upload: message.image.url,
              image_source: 'websiteLink',
            },
          }))
          templateData.selected = 'image'
          savedTemplates.push(templateData[0])
        } else if (message.type === 'carousel') {
          const carouselTemplateData = getCarouselTemplateData(
            message,
            templateRef
          )

          savedTemplates.push(carouselTemplateData)
        }
        return true
      })
      setTemplates([...savedTemplates])
    }
    if (defaultData?.status) {
      setSaveActionChanged(defaultData?.status)
    }
  }, [])

  useEffect(() => {
    if (shouldNavigateToUserGroup) {
      closeModal()
      history.push('/user_groups')
    } else if (savedSuccess) {
      closeModal()
      if (pendingLocation) {
        history.push(pendingLocation.pathname)
      } else {
        if (saveActionChanged === 'DRAFT') {
          localStorage.setItem('selected_broadcast_tab', 'drafts')
        }
        history.push('/broadcast')
      }
    }
  }, [readyForNavigation])

  const addTemplate = () => {
    const templateData = map(templateRef.current, (template) =>
      template.getRawFormData()
    )

    setTemplates([...templateData, { tmpId: generateRandomId(32) }])
  }

  const fetchScheduledDate = (statusText, formData) => {
    if (isEmpty(formData)) {
      return ''
    }

    const scheduledDate =
      formData.scheduledFor &&
      formData.scheduleMessage === 'scheduleLater' &&
      DateTime.fromJSDate(formData.scheduledFor).toUTC().toISO()
        ? DateTime.fromJSDate(formData.scheduledFor).toUTC().toISO()
        : DateTime.fromISO(formData.scheduledFor)
            .setLocale('en-SG')
            .toFormat('dd LLLL yyyy, t')

    if (
      !DateTime.fromISO(scheduledDate).isValid &&
      formData.scheduledFor &&
      formData.scheduleMessage !== 'sendNow'
    ) {
      const parsedDate = DateTime.fromFormat(
        scheduledDate,
        'dd LLLL yyyy, h:mm a'
      )
      return parsedDate.toUTC().toISO()
    }

    if (
      !formData.recurringBroadcast ||
      isEmpty(formData.recurringBroadcast.selectedFrequency)
    ) {
      return isEqual(formData.scheduleMessage, 'sendNow')
        ? DateTime.now().plus({ minutes: 1 }).toUTC().toISO()
        : scheduledDate
    }
    return DateTime.now().plus({ year: 99 }).toUTC().toISO()
  }

  const fetchScheduledFrequency = (formData) => {
    if (
      formData.recurringBroadcast &&
      formData.recurringBroadcast.selectedFrequency
    ) {
      switch (formData.recurringBroadcast.selectedFrequency.value) {
        case 'day': {
          return flatMap(
            formData.recurringBroadcast.recurringBroadcastTime.value,
            (time) => ({
              unit: 'day',
              every: 1,
              time_of_day: time,
            })
          )
        }

        case 'week': {
          return flatMap(
            formData.recurringBroadcast.recurringBroadcastTime.value,
            (time) =>
              map(
                formData.recurringBroadcast.recurringBroadcastDate.value,
                (day) => ({
                  unit: 'week',
                  every: 1,
                  day_of_week: day.id,
                  time_of_day: time,
                })
              )
          )
        }

        case 'month': {
          return flatMap(
            formData.recurringBroadcast.recurringBroadcastTime.value,
            (time) =>
              map(
                formData.recurringBroadcast.recurringBroadcastDate.value,
                (day) => ({
                  unit: 'month',
                  every: 1,
                  day_of_month: day.value,
                  time_of_day: time,
                })
              )
          )
        }
        default:
          return {}
      }
    }
    return null
  }

  const prepareRequests = (directoryProfileUids) => {
    const batchSize = Config.domain === 'botmd.sg' ? 125 : 500
    if (!directoryProfileUids) return []
    const initialUids = {
      directory_profile_uids: directoryProfileUids.directory_profile_uids.slice(
        0,
        batchSize
      ),
    }
    const remainingUids =
      directoryProfileUids.directory_profile_uids.slice(batchSize)

    const batches = Array.from(
      { length: Math.ceil(remainingUids.length / batchSize) },
      (_, i) => remainingUids.slice(i * batchSize, i * batchSize + batchSize)
    )
    const subsequentRequests = batches.map((batch) => ({
      input: {
        upsertDirectoryprofileUids: batch,
      },
    }))

    return [initialUids, subsequentRequests]
  }

  const changeTimeString = (dateStr, timeStr) => {
    if (!dateStr || !timeStr) {
      return ''
    }
    const date = new Date(dateStr)
    const [hours, minutes] = timeStr.split(':').map(Number)
    date.setHours(hours)
    // minus 2 min to fix bug with immediate broadcast not triggering
    date.setMinutes(minutes - 2)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
  }

  const getBroadcastInput = (formData, templateData, statusText) => {
    const selectedUsers = chain(formData.recipients)
      .filter((recipient) => recipient.uid === 'allSelectedUsers')
      .flatMap((recipient) => ({
        directory_profile_uids: recipient.uids,
      }))
      .value()

    const profilesArray = chain(formData.recipients)
      .filter(
        (recipient) =>
          recipient.type === 'profilesGroup' &&
          recipient.uid !== 'allSelectedUsers' &&
          recipient.uid !== 'allProfiles'
      )
      .flatMap((recipient) => ({
        uid: recipient.uid,
      }))
      .value()
    const individualArray = chain(formData.recipients)
      .filter((recipient) => recipient.type === 'userUID')
      .flatMap((recipient) => ({
        directory_profile_uids: [recipient.value],
      }))
      .value()

    const allGroups = chain(formData.recipients)
      .filter((recipient) => recipient.uid === 'allProfiles')
      .value()

    const directoryProfileInput = individualArray.concat(selectedUsers)
    const [reducedInput, subsequentRequest] = prepareRequests(
      directoryProfileInput[0]
    )

    const profileGroupInput = reducedInput
      ? profilesArray.concat(reducedInput)
      : profilesArray

    let scheduledDate = fetchScheduledDate(statusText, formData)

    if (subsequentRequest?.length > 0) {
      scheduledDate = DateTime.fromISO(scheduledDate)
        .plus({ seconds: 30 })
        .toUTC()
        .toISO()
    }

    const input = {
      input: {
        name: formData.broadcastName,
        status: statusText,
        scheduledFor: scheduledDate,
        recurringFrequencies: fetchScheduledFrequency(formData),
        messages: templateData,
        profilesGroupRulesets: isEmpty(allGroups)
          ? profileGroupInput
          : [{ all_active: true }],
      },
    }

    if (
      input.input.scheduledFor === 'Invalid DateTime' &&
      statusText === 'DRAFT'
    ) {
      // delete input.input.scheduledFor
      input.input.scheduledFor = null
    }

    if (isEmpty(input.input.recurringFrequencies)) {
      // delete input.input.recurringFrequencies
      input.input.recurringFrequencies = []
    } else {
      // delete input.input.scheduledFor
      input.input.scheduledFor = null
      if (formData.recurringBroadcast.broadcastStartAndEndDate.startDate) {
        input.input.startOn = changeTimeString(
          formData?.recurringBroadcast?.broadcastStartAndEndDate?.startDate,
          formData?.recurringBroadcast?.recurringBroadcastTime?.value?.[0]
        )
      }
      if (formData.recurringBroadcast.broadcastStartAndEndDate.endDate) {
        const [hour, min] = (
          formData.recurringBroadcast.recurringBroadcastTime.value.length
            ? formData.recurringBroadcast.recurringBroadcastTime.value[0]
            : '09:00'
        ).split(':')
        let minPlusOne = parseInt(min, 10) + 1
        if (minPlusOne > 10) {
          minPlusOne = `0${minPlusOne}`
        }
        formData.recurringBroadcast.broadcastStartAndEndDate.endDate.setHours(
          hour
        )
        formData.recurringBroadcast.broadcastStartAndEndDate.endDate.setMinutes(
          minPlusOne
        )

        input.input.endOn = DateTime.fromJSDate(
          formData.recurringBroadcast.broadcastStartAndEndDate.endDate
        )
          .toUTC()
          .toISO()
      }
    }
    if (isEmpty(input.input.profilesGroupRulesets.directory_profile_uids)) {
      delete input.input.profilesGroupRulesets.directory_profile_uids
    }
    if (isEmpty(input.input.profilesGroupRulesets.uid)) {
      delete input.input.profilesGroupRulesets.uid
    }
    return [input, subsequentRequest]
  }

  const parseDraftData = (data) => {
    if (!data) {
      return null
    }
    const rulesetsArray = (data?.profilesGroupRulesets || data?.rulesets)
      ?.map((ele) => ele?.uid)
      .filter((ele) => ele)
      .sort()
    const messagesParsed = data?.messages?.map((ele) => {
      const currentMessage = ele
      if (currentMessage?.message?.type === 'image') {
        delete currentMessage.message.image.stitchUrl
      }
      if (currentMessage?.message?.type === 'carousel') {
        currentMessage.message?.carousel?.map((carousel) => {
          const currentCarousel = carousel
          delete currentCarousel?.sections[0]?.video?.additionalProperties
          delete currentCarousel?.sections[0]?.video?.preview_autostart
          return currentCarousel
        })
      }
      return currentMessage
    })
    const parsedDate = {
      broadcastName: data.broadcastName || data.name,
      messages: messagesParsed,
      startOn: data.startOn
        ? DateTime.fromISO(new Date(data.startOn).toISOString())
            .setLocale('en-SG')
            .toFormat('dd LLLL yyyy')
        : null,
      status: data.status,
      rulesets: rulesetsArray?.length ? rulesetsArray : null,
      recurringFrequencies:
        data.reccuringFrequencies || data.recurringFrequencies,
      endOn: data.endOn
        ? DateTime.fromISO(new Date(data.endOn).toISOString())
            .setLocale('en-SG')
            .toFormat('dd LLLL yyyy')
        : null,
    }
    if (parsedDate.recurringFrequencies?.length === 0) {
      parsedDate.recurringFrequencies = undefined
    }
    return parsedDate
  }

  const isDraftChanged = () => {
    const status = saveActionChanged
    let payload = null
    let currentFormSentOn = null
    const templateData = map(templateRef.current, (template) =>
      template.getTemplateData(false)
    )
    if (formRef?.current) {
      const formData = formRef.current.getFormData()
      currentFormSentOn = formData?.scheduledFor
        ? DateTime.fromISO(new Date(formData.scheduledFor).toISOString())
            .setLocale('en-SG')
            .toFormat('dd LLLL yyyy, t')
        : null
      payload = formData
        ? getBroadcastInput(formData, templateData, status)[0]
        : null
    }
    const currentDataParsed = parseDraftData(payload?.input)
    const defaultDataParsed = parseDraftData(defaultData)
    if (
      currentFormSentOn &&
      defaultData?.sentOn &&
      !defaultDataParsed?.recurringFrequencies
    ) {
      if (
        currentFormSentOn !==
        DateTime.fromISO(new Date(defaultData.sentOn).toISOString())
          .setLocale('en-SG')
          .toFormat('dd LLLL yyyy, t')
      ) {
        setDraftChanged(true)
        return null
      }
    }
    if (isEqual(currentDataParsed, defaultDataParsed)) {
      setDraftChanged(false)
    } else {
      setDraftChanged(true)
    }
    return null
  }

  const isDraftEmpty = () => {
    let currentDraft = true
    const templateData = map(templateRef.current, (template) =>
      template.getTemplateData(false)
    )
    const message = templateData?.[0]?.message
    // Checking for paragraph
    message?.paragraphs?.forEach((ele) => {
      if (ele[ele.type]) {
        currentDraft = false
      }
    })
    // checking for text
    if (message?.text) {
      currentDraft = false
    }
    if (
      formRef?.current?.formData?.broadcastName ||
      formRef?.current?.formData?.recipients ||
      formRef?.current?.formData?.scheduleMessage
    ) {
      currentDraft = false
    }
    setDraftEmpty(currentDraft)
  }
  const handleMessageChange = () => {
    const adminName = einsteinAdmin?.adminName || 'Name'
    let department = 'Administration'
    if (einsteinAdmin && einsteinAdmin.hospital) {
      department = `${einsteinAdmin?.hospital?.name} Administration`
    }

    let widgetDate = new Date().toISOString()

    const templateData = map(templateRef.current, (template) =>
      template.getTemplateData(false)
    )

    if (formRef.current) {
      const formData = formRef.current.getFormData()

      if (
        formData.scheduleMessage === 'scheduleLater' &&
        !isEmpty(formData.scheduledFor)
      ) {
        widgetDate = formData.scheduledFor
      }

      if (
        formData.scheduleMessage === 'scheduleLater' &&
        formData.scheduledFor &&
        typeof formData.scheduledFor.toISOString === 'function'
      ) {
        widgetDate = formData.scheduledFor.toISOString()
      }

      if (
        typeof defaultData === 'undefined' &&
        (typeof formData.broadcastName !== 'undefined' ||
          typeof formData.recipients !== 'undefined' ||
          typeof formData.scheduleMessage !== 'undefined' ||
          (templateData[0].message &&
            typeof templateData[0].message.text !== 'undefined' &&
            templateData[0].message.text !== '') ||
          (templateData[0].message &&
            typeof templateData[0].message.paragraphs !== 'undefined'))
      ) {
        setFormTouched(true)
      } else if (
        typeof defaultData !== 'undefined' &&
        (!isEqual(defaultData.messages, templateData) ||
          defaultData.broadcastName !== formData.broadcastName)
      ) {
        setFormTouched(true)
      } else {
        setFormTouched(false)
      }
      setTimeout(() => {
        isDraftEmpty()
      }, 300)
    }

    const messagesToRender = []
    messagesToRender.push(
      templateData.map((data, index) => {
        let messageUid = ''

        if (uidIndexMap[index]) {
          messageUid = uidIndexMap[index]
        } else {
          messageUid = generateRandomId(32)
          uidIndexMap[index] = messageUid
          setUidIndexMap(uidIndexMap)
        }

        const message = {
          node: {
            uid: messageUid,
            direction: 'outgoing',
            messageContent: data,
            metadata: {
              pusher: {
                status: 'sent',
              },
              hospital: {
                short_name: 'KTPH',
                delivery_by: 'bot',
              },
              variables: {
                full_name: adminName,
                department,
                designation: 'Administrator',
              },
            },
            createdOn: widgetDate,
          },
        }
        return message
      })
    )

    if (window.frames.length >= 1) {
      window?.frames?.[0]?.postMessage(messagesToRender, '*')
    }
    isDraftChanged()
  }

  const templateOnSort = (index, direction) => {
    if (isEqual(direction, 'up') && gt(index, 0)) {
      const newTemplateRef = templateRef.current
      const value = pullAt(newTemplateRef, [index])
      newTemplateRef.splice(index - 1, 0, value[0])
      templateRef.current = newTemplateRef
    }

    if (isEqual(direction, 'down') && lt(index, templates.length)) {
      const newTemplateRef = templateRef.current
      const value = pullAt(newTemplateRef, [index])
      newTemplateRef.splice(index + 1, 0, value[0])
      templateRef.current = newTemplateRef
    }
    setTemplates(
      map(templateRef.current, (template) => template.getRawFormData())
    )

    handleMessageChange()
  }

  const templateOnClear = (id) => {
    // Remove useRef for component
    templateRef.current[id].resetFormData()
  }

  const templateOnDelete = (id) => {
    // Remove useRef for component
    const newTemplateRef = filter(
      templateRef.current,
      (template, index) => !isEqual(index, id)
    )
    templateRef.current = newTemplateRef
    // Remove component
    setTemplates(
      map(templateRef.current, (template) => template.getRawFormData())
    )

    handleMessageChange()
  }

  const callback = () => {
    handleMessageChange()
  }

  const forms = useMemo(
    () => [
      {
        id: 'broadcastName',
        testId: 'broadcastName',
        title: 'Broadcast Name',
        description: 'Create a name that will be used for internal reference',
        type: 'input',
        fields: [
          {
            id: 'broadcastName',
            testId: 'broadcastName',
            type: 'input',
            visibility: true,
            label: '',
            placeholder: 'Enter broadcast name',
            limit: 36,
            required: true,
          },
        ],
      },
      {
        id: 'recipients',
        title: 'Recipients',
        testId: 'recipients',
        description:
          'Select your broadcast recipients from user groups. Create a new ',
        callbackText: 'User Group',
        callback: () => {
          openModal(
            <AlertModal
              title="Save changes before leaving"
              description="Do you want to save the changes before leaving?"
              handleClose={closeModal}
              // noImage
              renderCloseTrigger={() => (
                <Flex mt={4} justifyContent="center" alignItems="center">
                  <SecondaryOutlinedButton
                    borderRadius="8px"
                    mr={2}
                    onClick={() => closeModal()}
                  >
                    Cancel
                  </SecondaryOutlinedButton>
                  <PrimaryButton
                    borderRadius="8px"
                    mr={3}
                    onClick={() => {
                      setShouldNavigateToUserGroup(true)
                      setSaveAction('DRAFT')
                      setSaveActionChanged('DRAFT')
                    }}
                    data-testid="saveBroadCastDraftButtonPopup"
                    disabled={isDraft ? !draftChanged : draftEmpty}
                  >
                    {isDraft ? 'Save' : 'Save Draft'}
                  </PrimaryButton>
                </Flex>
              )}
            />
          )
        },
        fields: [
          {
            testId: 'recipients',
            id: 'recipients',
            type: 'custom',
            component: getUserGroupsDropdown(),
            required: true,
          },
        ],
      },
      {
        id: 'scheduleMessage',
        title: 'Schedule Broadcast',
        type: 'options',
        testId: 'options',
        fields: [
          {
            id: 'scheduleMessage',
            type: 'options',
            testId: 'options',
            label: '',
            options: [
              {
                id: 'sendNow',
                label: 'Send now',
                testId: 'sendNow',
                optionCallback: () => {
                  logPostHogEvent('broadcasts:send_dropdown_button', {
                    schedule: 'now ',
                  })
                },
              },
              {
                id: 'scheduleLater',
                label: 'Schedule one-time broadcast',
                testId: 'scheduleLater',
                optionCallback: () => {
                  setDefaultOnetimeBroadcastChanged(true)
                  logPostHogEvent('broadcasts:send_dropdown_button', {
                    schedule: 'one-time ',
                  })
                },
              },
              {
                id: 'recurringBroadcast',
                label: 'Schedule recurring broadcast',
                testId: 'recurringBroadcast',
                optionCallback: () => {
                  logPostHogEvent('broadcasts:send_dropdown_button', {
                    schedule: 'recurring ',
                  })
                },
              },
            ],
            required: true,
          },
        ],
      },
      {
        id: 'createMessage',
        title: 'Create Message',
        description: 'Customize and preview your broadcast message',
        testId: 'createMessage',
        fields: [
          {
            id: 'createMessage',
            testId: 'createMessage',
            type: 'custom',
            component: (
              <MessageComponent
                templates={templates}
                onTemplateSort={templateOnSort}
                onTemplateDelete={templateOnDelete}
                onFormChange={callback}
                onTemplateClear={templateOnClear}
                onSetTemplateRef={onSetTemplateRef}
                onAddTemplate={addTemplate}
              />
            ),
            required: false,
          },
        ],
      },
    ],
    [
      defaultData,
      templates,
      templateOnSort,
      callback,
      templateOnClear,
      addTemplate,
    ]
  )

  const STATUS_DRAFT = 'DRAFT'
  const STATUS_SCHEDULED = 'SCHEDULED'

  const handleSubmit = (statusText) => {
    // no need to revalidate if we're just going revert
    const formData = formRef.current.getFormData(
      statusText !== 'revert',
      statusText
    )
    logPostHogEvent('broadcasts:create_broadcast_save_draft_button')

    setTimeout(() => {
      const errorLabel = document.getElementsByClassName('error-message')
      if (errorLabel.length > 0) {
        errorLabel[0].scrollIntoView({ behavior: 'smooth' })
      }
    }, 300)

    const preProcessedData = map(templateRef.current, (template) =>
      template.getTemplateData(true)
    )

    if (isEmpty(preProcessedData) || isEmpty(preProcessedData[0])) {
      return
    }
    let templateData = null
    let isMessageEmpty = true
    let isTextMessagePresent = false
    let isImageAbsent = false
    if (!includes(preProcessedData, null)) {
      templateData = map(preProcessedData, (data) => {
        if (data?.message.type === 'text') {
          isTextMessagePresent = true
          if (data.message.text !== '') {
            isMessageEmpty = false
          }
        }
        const transform = data
        if (
          data.message.type === 'image' &&
          !isEmpty(data.message.image.stitchUrl)
        ) {
          transform.message.image.url = data.message.image.stitchUrl
          delete transform.message.image.stitchUrl
          return transform
        }
        if (transform.message.image) {
          delete transform.message.image.stitchUrl
        }
        if (
          data?.message?.type === 'image' &&
          isEmpty(data?.message?.image?.url)
        )
          isImageAbsent = true
        return transform
      })
    }
    if (isTextMessagePresent && isMessageEmpty) {
      return
    }
    if (isImageAbsent) {
      return
    }

    if (!formData) {
      return
    }

    // Determine what action is being taken: Save as Draft or Send Broadcast
    const isSaveAsDraft = statusText === STATUS_DRAFT
    const isSendBroadcast = statusText === STATUS_SCHEDULED

    // Determine the type of broadcast
    const isNewBroadcast = !defaultData || checkOnlyRecipientsExist(defaultData)
    const isDuplicateBroadcast = defaultData && defaultData.isDuplicate
    const isEditDraftBroadcast =
      defaultData && !defaultData.isDuplicate && defaultData.status === 'DRAFT'
    const isEditScheduledBroadcast =
      defaultData &&
      !defaultData.isDuplicate &&
      defaultData.status === 'SCHEDULED'
    // Scenario 1: New Broadcast
    if (isNewBroadcast) {
      // Save as draft or Send Broadcast
      const [payload, subsequentRequests] = getBroadcastInput(
        formData,
        templateData,
        statusText
      )
      if (subsequentRequests?.length > 0) {
        updateRequestsRef.current = subsequentRequests
      }
      eventLogDataBroadcastHelper(payload, formData.scheduleMessage)
      createBroadcastTask(payload)
      if (isSaveAsDraft && location.state.prevPath) {
        localStorage.setItem('selected_broadcast_tab', 'drafts')
      }
      return
    }

    // Scenario 2: Duplicate Broadcast
    if (isDuplicateBroadcast) {
      // Save as draft or Send Broadcast
      const payload = getBroadcastInput(formData, templateData, statusText)[0]
      eventLogDataBroadcastHelper(payload, formData.scheduleMessage)
      createBroadcastTask(payload)
      if (isSaveAsDraft && location.state.prevPath) {
        localStorage.setItem('selected_broadcast_tab', 'drafts')
      }
      return
    }

    // Scenario 3: Edit Draft Broadcast
    if (isEditDraftBroadcast) {
      if (isSaveAsDraft) {
        // Update draft

        const updateInput = getBroadcastInput(
          formData,
          templateData,
          statusText
        )[0]
        updateInput.input.uid = defaultData.uid
        eventLogDataBroadcastHelper(updateInput, formData.scheduleMessage)
        updateBroadcastTask(updateInput)
      } else if (isSendBroadcast) {
        // Send Broadcast
        const payload = getBroadcastInput(formData, templateData, statusText)[0]
        eventLogDataBroadcastHelper(payload, formData.scheduleMessage)
        createBroadcastTask(payload)
      }
      return
    }

    // Scenario 4: Edit Scheduled Broadcast
    if (isEditScheduledBroadcast) {
      // Update existing broadcast
      const updateInput = getBroadcastInput(
        formData,
        templateData,
        'SCHEDULED'
      )[0]
      updateInput.input.uid = defaultData.uid
      eventLogDataBroadcastHelper(updateInput, formData.scheduleMessage)
      updateBroadcastTask(updateInput)
    }
  }

  useEffect(() => {
    if (!isEmpty(saveAction) && saveAction !== 'revert') {
      handleSubmit(saveAction)
      setSaveAction('revert') // reset to revert, in order to handle state and in order to handle errors and submit same state again
    }
  }, [saveAction])

  useEffect(() => {
    const recipients = get(defaultData, 'recipients')
    if (recipients) {
      setTimeout(() => {
        formRef.current.updateFormData('recipients', recipients)
      }, 100)
    }

    const broadcastNameTemp = get(defaultData, 'broadcastName')
    if (!isEmpty(broadcastNameTemp)) {
      setTimeout(() => {
        formRef.current.updateFormData('broadcastName', broadcastNameTemp)
      }, 600)
    }
    if (
      defaultData &&
      defaultData.reccuringFrequencies &&
      !isEmpty(defaultData.reccuringFrequencies)
    ) {
      const uniqueUnit = [
        ...new Set(defaultData.reccuringFrequencies.map((item) => item.unit)),
      ]
      const uniqueTime = [
        ...new Set(
          defaultData.reccuringFrequencies.map((item) => item.time_of_day)
        ),
      ]
      setTimeout(() => {
        formRef.current.updateFormData('selectedFrequency', uniqueUnit[0])
      }, 150)
      if (defaultData.startOn && defaultData.endOn) {
        setTimeout(() => {
          formRef.current.updateFormData('recurringStartandEndDate', {
            startDate: defaultData.startOn,
            endDate: defaultData.endOn,
          })
        }, 50)
      } else if (defaultData.startOn) {
        formRef.current.updateFormData(
          'recurringStartDate',
          defaultData.startOn
        )
      }
      if (defaultData.reccuringFrequencies) {
        formRef.current.updateFormData('recurringBroadcastTime', uniqueTime[0])
        if (uniqueUnit[0] === 'week') {
          const selectedDays = [
            ...new Set(
              defaultData.reccuringFrequencies.map((item) => item.day_of_week)
            ),
          ]
          setTimeout(() => {
            formRef.current.updateFormData(
              'reccuringBroadcastWeek',
              selectedDays
            )
          }, 50)
        } else if (uniqueUnit[0] === 'month') {
          const selectedDays = [
            ...new Set(
              defaultData.reccuringFrequencies.map((item) => item.day_of_month)
            ),
          ]
          formRef.current.updateFormData(
            'reccuringBroadcastMonth',
            selectedDays
          )
        }
      }
      setTimeout(() => {
        formRef.current.updateFormData('scheduleMessage', 'recurringBroadcast')
      }, 300)
    } else if (defaultData && defaultData.sentOn) {
      setTimeout(() => {
        formRef.current.updateFormData(
          'scheduledFor',
          get(defaultData, 'sentOn', '')
        )
      }, 100)
      setTimeout(() => {
        formRef.current.updateFormData('scheduleMessage', 'scheduleLater')
      }, 100)
    }
  }, [])

  const toggleOpen = () => {
    const templateData = map(templateRef.current, (template) =>
      template.getRawFormData()
    )
    setTemplates([...templateData])

    setOpen(!open)
  }

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.closeWidget) {
        toggleOpen()
      }
      if (event.data && event.data.listener) {
        const previewMode = { previewMode: true }
        setTimeout(() => {
          window?.frames?.[0]?.postMessage(previewMode, '*')
        }, 600)
        setTimeout(() => {
          setRenderPreviewPane(true)
          callback()
        }, 300)
      }
    })
  }, [])

  return (
    <>
      <RouteLeaveGuard
        when={!savedSuccess && formTouched}
        onSaveThenLeave={(newPendingLocation) => {
          setPendingLocation(newPendingLocation)
          setSaveAction('DRAFT')
          setSaveActionChanged('DRAFT')
        }}
      />
      <Box mt={2}>
        <StatusBar
          testId="sendBroadcastStatusBar"
          showStatusBar={showStatusBarMessage.length > 0}
          status="FAILED"
          fontSize="14px"
          errorMessage={`${showStatusBarMessage} `}
          showCloseButton
          onCloseCallback={() => {
            setShowStatusBarMessage('')
          }}
          mb={2}
        />

        {/* Back Button */}
        <BackButton
          id="backBtn"
          alignItems="center"
          mb={1}
          ml="16px"
          hover={{ opacity: 0.6 }}
          cursor="pointer"
          width="fit-content"
          onClick={goBack}
        >
          <FAIcon
            icon={faChevronLeft}
            color="darkestShade"
            fontWeight="500"
            style={{ fontSize: 12, fontWeight: 500, marginRight: 4 }}
          />
          <H5 letterSpacing="-0.08px" fontSize={14} color="darkestShade">
            Back
          </H5>
        </BackButton>

        {/* Title */}
        <Flex justifyContent="space-between" alignItems="center">
          <Text m={2} mt={0} fontSize="32px" fontWeight="600">
            {isEditMode ? 'Edit Broadcast' : 'Create Broadcast'}
          </Text>
          <Flex mr={3} justifyContent="right" alignItems="center">
            <SecondaryOutlinedButton
              borderRadius="8px"
              mr={3}
              onClick={() => {
                setSaveAction('DRAFT')
                setSaveActionChanged('DRAFT')
              }}
              data-testid="saveBroadCastDraftButton"
              disabled={isDraft ? !draftChanged : draftEmpty}
            >
              {isDraft ? 'Save' : 'Save Draft'}
            </SecondaryOutlinedButton>
            <PrimaryButton
              borderRadius="8px"
              onClick={() => {
                setSaveAction('SCHEDULED')
                setSaveActionChanged('SCHEDULED')
              }}
              data-testid="sendBroadCastButton"
            >
              Send Broadcast
            </PrimaryButton>
          </Flex>
        </Flex>

        <Text m={2}>
          Send or schedule a broadcast for your hospital app users
        </Text>
        <Flex m={2}>
          <Box ml="-16px" maxWidth="50%">
            <Form
              formRef={onSetFormRef}
              forms={forms}
              defaultFormData={
                isEmpty(defaultData) ? { defaultDate: new Date() } : {}
              }
              startIcon={(index) => <CirclularChip label={index + 1} />}
              labelSize="12px"
              onFormChange={handleMessageChange}
              rerenderForm={rerenderForm}
              setRerenderForm={setRerenderForm}
            />
          </Box>

          {/* TODO: Preview message */}
          <BotWidget
            renderPreviewPane={renderPreviewPane}
            toggleOpen={toggleOpen}
            open={open}
          />
        </Flex>
      </Box>
    </>
  )
}

const MessageComponent = ({
  templates,
  onTemplateSort,
  onTemplateDelete,
  onFormChange,
  onTemplateClear,
  onSetTemplateRef,
  onAddTemplate,
}) => (
  <>
    {map(templates, (template, index) => (
      <BroadcastMessageTemplate
        key={template.tmpId}
        id={index}
        tmpId={template.tmpId}
        onSort={onTemplateSort}
        onDelete={onTemplateDelete}
        defaultFormData={template}
        selectedState={template.selected}
        onFormChange={onFormChange}
        onClear={onTemplateClear}
        templateRef={(ref) => onSetTemplateRef(ref, index)}
        hideDelete={templates.length <= 1}
        hideMoveUp={index === 0}
        hideMoveDown={index >= templates.length - 1}
      />
    ))}
    {templates.length >= MAX_TEMPLATE_COUNT ? (
      <Text
        ml={1}
        fontSize="14px"
        color="darkestShade"
        letterSpacing="-0.08px"
        lineHeight="24px"
        fontWeight="500"
      >
        <i>*You have reached the max number of messages for this broadcast</i>
      </Text>
    ) : (
      <Box mb={4} cursor="pointer" onClick={onAddTemplate}>
        <Flex mt={3} ml="12px" alignItems="center">
          <FAIcon fontSize="16px" icon={faPlus} style={{ color: '#256BF6' }} />
          <Text
            ml={1}
            color="#256BF6"
            fontSize="14px"
            letterSpacing="-0.08px"
            lineHeight="24px"
            fontWeight="500"
          >
            Add Content
          </Text>
        </Flex>
      </Box>
    )}
  </>
)

export default BroadcastMessageCreate
