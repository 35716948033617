import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  Flex,
  Text,
  Box,
  PrimaryButton,
  SecondaryOutlinedButton,
  useApi,
  sendLog,
} from '@fivehealth/botero'

import { useModal } from 'old-code/context/ModalContext'
import { useNavStateContext } from 'old-code/context/NavStateContext'
import { numberComma } from 'Utils'
import EventsConstant from '../../../../config/constants/events.constants'

const UserGroupsEditRowSelect = ({
  tableRef,
  selectedFlatRows,
  toggleAllRowsSelected,
  isEditView,
  groupsData,
  onUserAdded,
  showDashboard,
  setSelectAllVisibleRows,
  setSelectAllRows,
  selectedRows,
  toggleRowSelected,
  setResetTable,
  setSelectedRows,
  allSelectableRows,
  onEditRowStateChange,
}) => {
  const { openModal, closeModal } = useModal()
  const { expanded } = useNavStateContext()
  const [selectAll, setSelectAll] = useState(false)
  const [selectAllVisible, setSelectAllVisible] = useState(false)

  const selectAllRows = () => {
    setTimeout(() => {
      setSelectAll(true)
      setSelectedRows(selectedFlatRows)
      toggleAllRowsSelected(true)
    }, 200)
  }

  const deSelectAllRows = () => {
    setSelectAll(false)
    toggleAllRowsSelected(false)
  }

  const deSelect = () => {
    setTimeout(() => {
      setSelectAll(false)
      setSelectAllRows(false)
    }, 100)
    setTimeout(() => {
      setSelectAllVisible(false)
      setSelectAllVisibleRows(false)
    }, 100)
    toggleAllRowsSelected(false)
    setSelectedRows([])
    setResetTable(true)
    setTimeout(() => {
      setResetTable(false)
    }, 300)
  }

  const deSelectAllVisibleRows = () => {
    setSelectAllVisible(false)
    setSelectAllVisibleRows(false)
    toggleAllRowsSelected(false)
    setSelectedRows([])
  }

  const selectAllVisibleRows = () => {
    setTimeout(() => {
      setSelectAllVisible(true)
      setSelectAllVisibleRows(true)
      toggleAllRowsSelected(true)
    }, 200)
  }

  useEffect(() => {
    if (selectAll) {
      setTimeout(() => {
        setSelectedRows(selectedFlatRows)
      }, 100)
    } else {
      setSelectedRows([])
    }
  }, [selectAll])

  useEffect(() => {
    if (tableRef) {
      tableRef({
        selectAllRows,
        selectAllVisibleRows,
        deSelectAllVisibleRows,
        deSelectAllRows,
      })
    }
  }, [])

  const fetchSelectedUuids = () => {
    if (selectAll) {
      return _.map(allSelectableRows, (uid) => uid)
    }
    if (selectAllVisible) {
      return _.map(selectedFlatRows, (row) => row.original.uid)
    }
    return _.map(selectedRows, (row) => row.original.uid)
  }

  useEffect(() => {
    if (
      groupsData &&
      groupsData.ruleset &&
      groupsData.ruleset.directoryProfiles
    ) {
      const originalUids = groupsData.ruleset.directoryProfiles.map(
        (dir) => dir.uid
      )
      originalUids.forEach((uid) => {
        const isMemberSelected = _.find(
          selectedRows,
          (selectedRow) => selectedRow.original.uid === uid
        )
        if (isMemberSelected) {
          toggleRowSelected(uid, true)
        }
      })
    }
  }, [groupsData, toggleRowSelected, selectedRows])

  const {
    queries: { useProfilesGroupUpdate, useProfilesGroupDelete },
  } = useApi({
    queries: ['useProfilesGroupUpdate', 'useProfilesGroupDelete'],
  })

  const { mutateAsync: updateProfilesGroup } = useProfilesGroupUpdate({
    variables: {},
    onSuccess: ({ queryClient, data }) => {
      if (data?.errors?.length) {
        sendLog(
          {
            subSource: EventsConstant.USER_GROUP_BULK_ACTION_SOURCE,
            page: EventsConstant.USER_GROUP_DETAILS_PAGE,
            metaData: JSON.stringify(data.errors || []),
          },
          EventsConstant.USER_GROUP_ERROR_REMOVE
        )
      }
      if (queryClient) {
        sendLog(
          {
            subSource: EventsConstant.USER_GROUP_BULK_ACTION_SOURCE,
            page: EventsConstant.USER_GROUP_DETAILS_PAGE,
          },
          EventsConstant.USER_GROUP_REMOVED
        )
        queryClient.invalidateQueries('profilesGroup')
        queryClient.invalidateQueries('directoryProfiles')
        deSelect()
        onEditRowStateChange(false)

        if (onUserAdded) {
          closeModal()
          onUserAdded(data.einsteinProfilesGroupUpdate.einsteinProfilesGroup)
        } else {
          closeModal()
          showDashboard()
        }
      }
    },
  })

  useEffect(() => {
    if (selectAll && selectedFlatRows.length === 0) {
      setSelectAll(false)
      setSelectedRows([])
    }
  }, [selectedFlatRows])

  const { mutateAsync: deleteProfilesGroup } = useProfilesGroupDelete({
    variables: {},
    onSuccess: ({ queryClient, data }) => {
      if (data?.errors?.length) {
        sendLog(
          {
            subSource: EventsConstant.USER_GROUP_BULK_ACTION_SOURCE,
            page: EventsConstant.USER_GROUP_DETAILS_PAGE,
            metaData: JSON.stringify(data.errors || []),
          },
          EventsConstant.USER_GROUP_ERROR_DELETE
        )
      }
      if (queryClient) {
        sendLog(
          {
            subSource: EventsConstant.USER_GROUP_BULK_ACTION_SOURCE,
            page: EventsConstant.USER_GROUP_DETAILS_PAGE,
          },
          EventsConstant.USER_GROUP_DELETED
        )
        queryClient.invalidateQueries('profilesGroup')
        queryClient.invalidateQueries('directoryProfiles')
      }
      showDashboard()
    },
  })

  const handleUpdate = () => {
    const originalUids = groupsData.ruleset.directoryProfiles.map(
      (dir) => dir.uid
    )

    const newUids = selectAll
      ? allSelectableRows
      : _.map(selectedFlatRows || selectedRows, (row) => row.original.uid)

    const uids = isEditView
      ? _.difference(originalUids, newUids)
      : _.union(originalUids, newUids)

    updateProfilesGroup({
      input: {
        uid: groupsData.uid,
        ruleset: {
          directory_profile_uids: uids,
        },
      },
    })
  }

  const handleDeleteGroup = () => {
    deleteProfilesGroup({
      input: {
        uid: groupsData.uid,
      },
    })
    closeModal()
  }

  const handleOnClick = () => {
    if (isEditView) {
      const selectedRowsLength = selectAll
        ? allSelectableRows.length
        : selectedRows.length

      if (selectedRowsLength !== groupsData.ruleset.directoryProfiles.length) {
        return openModal(
          <Box width="640px" p={4}>
            <Text fontWeight="bold" fontSize={3} mb={6}>
              Remove members
            </Text>
            <Text mb={6} fontSize={2}>
              Are you sure that you want to remove the selected members group?
              You can add them back in anytime.
            </Text>
            <Flex justifyContent="right" alignItems="center">
              <SecondaryOutlinedButton
                borderRadius="8px"
                mr={2}
                onClick={() => {
                  deSelect()
                  closeModal()
                  onEditRowStateChange(false)
                }}
              >
                Cancel
              </SecondaryOutlinedButton>
              <PrimaryButton borderRadius="8px" onClick={handleUpdate}>
                Remove Members
              </PrimaryButton>
            </Flex>
          </Box>
        )
      }
      return openModal(
        <Box width="640px" p={4}>
          <Text fontWeight="bold" fontSize={3} mb={6}>
            Delete Group
          </Text>
          <Text mb={6} fontSize={2}>
            Removing all members from the group, will delete the group. Do you
            want to continue?
          </Text>
          <Flex justifyContent="right" alignItems="center">
            <SecondaryOutlinedButton
              borderRadius="8px"
              mr={2}
              onClick={() => {
                deSelect()
                closeModal()
                onEditRowStateChange(false)
              }}
            >
              Cancel
            </SecondaryOutlinedButton>
            <PrimaryButton borderRadius="8px" onClick={handleDeleteGroup}>
              Delete Group
            </PrimaryButton>
          </Flex>
        </Box>
      )
    }
    return handleUpdate()
  }

  return (
    <Box
      position="fixed"
      zIndex="1"
      bg="#E9F0FE"
      p={2}
      borderRadius="8px"
      bottom="18px"
      right={`${expanded ? '60px' : '150px'}`}
      left={`${expanded ? '400px' : '200px'}`}
      boxShadow="0px 6px 12px #98a2b326, 0px 4px 4px #98a2b326, 0px 2px 2px #98a2b326"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <Text m={1} textAlign="center" color="primary" fontWeight="bold">
            {fetchSelectedUuids().length
              ? numberComma(fetchSelectedUuids().length)
              : 0}{' '}
            users selected
          </Text>
          {!selectAll && (
            <SecondaryOutlinedButton
              bg="white"
              borderRadius="8px"
              onClick={() => {
                deSelect()
              }}
            >
              Deselect
            </SecondaryOutlinedButton>
          )}
        </Flex>
        <Flex alignItems="center" justifyContent="right">
          <SecondaryOutlinedButton
            mr={2}
            bg="white"
            borderRadius="8px"
            onClick={() => {
              deSelect()
              onEditRowStateChange(false)
            }}
          >
            Cancel
          </SecondaryOutlinedButton>
          <PrimaryButton
            disabled={!fetchSelectedUuids().length}
            borderRadius="8px"
            mr={2}
            onClick={handleOnClick}
          >
            {isEditView ? 'Remove from group' : 'Add to group'}
          </PrimaryButton>
        </Flex>
      </Flex>
    </Box>
  )
}

export default UserGroupsEditRowSelect
