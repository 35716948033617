/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'

import {
  Box,
  Flex,
  PrimaryButton,
  Text,
  H1,
  FAIcon,
  useApi,
  useDebounce,
} from '@fivehealth/botero'
import { faAngleDown, faSyncAlt } from '@fortawesome/pro-regular-svg-icons'
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons'
import DropdownMenu from 'old-code/components/DropdownMenu/DropdownMenu'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { chain } from 'lodash'
import { useModal } from 'old-code/context/ModalContext'
import { useOrganizationsContext } from 'old-code/context/OrganizationsContext'
import useExportFile from 'old-code/customHooks/useExportFile'
import usePostHog from 'old-code/customHooks/usePostHog'

import ChernobylDataSourceTable from 'old-code/components/ChernobylDataSource/ChernobylDataSourceTable'
import chernobylModuleMap from 'old-code/views/ChernobylDataSource/ChernobylModuleMap'
import AddEditChernobylEntryForm from 'old-code/components/ChernobylDataSource/AddEditChernobylEntryForm'
import { numberComma, tableConfigUtils } from '../../../Utils'
import ExportDropDown from '../../components/ExportDropDown/ExportDropDown'
import EventsConstant from '../../config/constants/events.constants'

const DrugFormularyDashboard = () => {
  const { openModal, closeModal } = useModal()
  const { t } = useTranslation()
  const history = useHistory()
  const queryClient = useQueryClient()
  const [searchQuery, setSearchQuery] = useState('')
  const [moduleDataSource, setModuleDataSource] = useState(null)
  const { isPublicHospital } = useOrganizationsContext()
  const [tableSettings, setTableSettings] = useState(null)
  const [filters, setFilter] = useState({
    searchQuery: '',
  })
  const lastUpdated = useRef(new Date())
  const debouncedSearchQuery = useDebounce(searchQuery, 600)
  const [outputFormat, setOutputFormat] = useState('CSV')
  const exportActionClicked = useRef(false)

  useEffect(() => {
    queryClient.invalidateQueries('einsteinAdministrator')
    queryClient.invalidateQueries('einsteinModules')
    queryClient.invalidateQueries('einsteinChernobylEntrys')
  }, [])
  const exportFileUsingLink = useExportFile()

  const {
    queries: {
      useEinsteinAdministrator,
      useEinsteinModules,
      useEinsteinChernobylEntrys,
      useEinsteinChernobylExport,
    },
  } = useApi({
    queries: [
      'useEinsteinAdministrator',
      'useEinsteinModules',
      'useEinsteinChernobylEntrys',
      'useEinsteinChernobylExport',
    ],
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  const { isFetching: isModuleLoading } = useEinsteinModules({
    enabled: !!currentAdmin,
    variables: { visible: true },
    onSuccess: ({ data }) => {
      const moduleEdges = data.pages[0].einsteinModules.edges
      const drugFormularyModule = moduleEdges?.find(
        ({ node }) =>
          (((node?.settings || {}).chernobyl || {}).key || {}) ===
          `${currentAdmin.hospital.organizationKey}-${chernobylModuleMap.drug_formulary.key}`
      )

      if (drugFormularyModule) {
        setModuleDataSource(drugFormularyModule.node)
        setTableSettings(
          drugFormularyModule.node?.settings.data_source_entries_table_settings
        )
      }
    },
  })

  useEffect(() => {
    if (debouncedSearchQuery.length >= 3) {
      setFilter({ searchQuery: debouncedSearchQuery })
    } else {
      setFilter({ searchQuery: '' })
    }
  }, [debouncedSearchQuery])

  const {
    data: drugFormularyData = null,
    isLoading: isEntriesLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch: refetchDrugFormulary,
    isFetching,
  } = useEinsteinChernobylEntrys({
    enabled: !!moduleDataSource,
    variables: {
      ...filters,
      uid: (moduleDataSource || {}).uid || '',
    },
    onSuccess: () => {
      lastUpdated.current = new Date()
    },
  })

  const { data: drugFormularyExportData = null } = useEinsteinChernobylExport({
    enabled: !!moduleDataSource,
    variables: {
      ...filters,
      uid: (moduleDataSource || {}).uid || '',
      outputFormat,
    },
    onSuccess: () => {
      lastUpdated.current = new Date()
    },
  })

  const canExport =
    drugFormularyExportData &&
    drugFormularyExportData.pages.length > 0 &&
    drugFormularyExportData.pages[0].einsteinChernobylEntrys

  const handleExport = () => {
    if (canExport) {
      const link =
        drugFormularyExportData.pages[0].einsteinChernobylEntrys.exportByUrl
      exportFileUsingLink(link, 'drugFormulary')
    }
  }

  useEffect(() => {
    if (canExport && exportActionClicked.current) {
      exportActionClicked.current = false
      handleExport()
    }
  }, [drugFormularyExportData])

  const logPostHogEvent = usePostHog()
  useEffect(() => {
    logPostHogEvent('dashboard:content_drug_formulary_view')
  }, [logPostHogEvent])

  const drugFormularies = chain(drugFormularyData)
    .get('pages', [])
    .flatMap((page) => page || [])
    .map((data) => {
      const rowsMapToColumns = tableSettings.columns.reduce((acc, value) => {
        acc[value.id] = data.variables[value.header]

        return acc
      }, {})

      return {
        uid: data.uid,
        ...rowsMapToColumns,
        misc: data,
      }
    })
    .value()

  const actions = [
    {
      id: 'addDrug',
      testId: 'addDrug',
      icon: faPlusCircle,
      label: 'Add drug',
      description: 'Add a single drug manually',
      onClick: () =>
        openModal(
          <AddEditChernobylEntryForm
            openModal={openModal}
            closeModal={closeModal}
            formHeader="Add drug"
            fieldTitle="Drug Information"
            currentChernobylModuleMap={chernobylModuleMap.drug_formulary}
            moduleDataSource={moduleDataSource}
            exportFileUsingLink={exportFileUsingLink}
          />,
          { overflow: 'scroll' }
        ),
      logEventProps: {
        subSource: EventsConstant.DRUG_FORMULARY_HEAD_SOURCE,
        eventName: EventsConstant.DRUG_FORMULARY_ADD_DRUG_OPENED,
        page: EventsConstant.DRUG_FORMULARY_PAGE,
      },
    },
    {
      id: 'fullSync',
      icon: faSyncAlt,
      divider: 'true',
      label: 'Full Sync',
      description: 'Update an Excel file that will override all drug items',
      onClick: () => history.push('/full_sync/drug_formulary'),
      logEventProps: {
        subSource: EventsConstant.DRUG_FORMULARY_HEAD_SOURCE,
        eventName: EventsConstant.DRUG_FORMULARY_FULL_SYNC_OPENED,
        page: EventsConstant.DRUG_FORMULARY_PAGE,
      },
    },
  ]

  const columns = [
    ...tableConfigUtils.mapCellRenderToConfig(
      tableSettings,
      currentAdmin ? currentAdmin.hospital.organizationKey : '',
      t
    ),
  ].filter((ele) => ele.id !== 'tags')
  return (
    <Box>
      {/* Title */}
      <Flex mt={2} p={2} justifyContent="space-between" alignItems="center">
        <H1>Drug Formulary</H1>

        <Flex alignItems="right" justifyContent="space-between">
          {!isPublicHospital && (
            <DropdownMenu
              testId="mutateDrugsButton"
              moveLeft="-84px"
              fontWeight="500"
              label={
                <PrimaryButton
                  borderRadius="8px"
                  fontWeight="500"
                  endIcon={<FAIcon icon={faAngleDown} color="lightShade" />}
                >
                  Add drugs
                </PrimaryButton>
              }
              actions={actions}
              width="300px"
            />
          )}

          <ExportDropDown
            testId="exportDrugsButton"
            label="Export drug list"
            canExport={canExport}
            totalCount={
              drugFormularyData ? numberComma(drugFormularyData.totalCount) : 0
            }
            outputFormat={outputFormat}
            handleExport={handleExport}
            exportActionClicked={exportActionClicked}
            setOutputFormat={setOutputFormat}
            parentPage={EventsConstant.DRUG_FORMULARY_PAGE}
            logEventPropsAll={{
              csvExport: {
                subSource: EventsConstant.DRUG_FORMULARY_HEAD_SOURCE,
                eventName: EventsConstant.DRUG_FORMULARY_EXPORT_CSV,
                page: EventsConstant.DRUG_FORMULARY_PAGE,
              },
              xlxsExport: {
                subSource: EventsConstant.DRUG_FORMULARY_HEAD_SOURCE,
                eventName: EventsConstant.DRUG_FORMULARY_EXPORT_XLSX,
                page: EventsConstant.DRUG_FORMULARY_PAGE,
              },
              templateExport: {
                subSource: EventsConstant.DRUG_FORMULARY_HEAD_SOURCE,
                eventName: EventsConstant.DRUG_FORMULARY_EXPORT_TEMPLATE,
                page: EventsConstant.DRUG_FORMULARY_PAGE,
              },
            }}
          />
        </Flex>
      </Flex>
      <Text
        pl={2}
        pt={1}
        pb={3}
        fontSize="16px"
        fontWeight="400"
        letterSpacing="-0.08px"
      >
        View and manage your pharmacy formulary
      </Text>
      <ChernobylDataSourceTable
        searchTestId="searchDrugsInput"
        testId="drugsTable"
        refreshButtonTestId="refreshDrugsButton"
        bulkActionTestId="bulkEditDrugsButton"
        columns={columns}
        data={drugFormularies}
        isLoading={isModuleLoading || isEntriesLoading}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        searchInputPlaceholder="Search drug by name"
        searchQuery={searchQuery}
        onChangeSearchQuery={(newSearchQuery) => setSearchQuery(newSearchQuery)}
        refetch={refetchDrugFormulary}
        lastUpdatedDate={lastUpdated.current}
        currentChernobylModuleMap={chernobylModuleMap.drug_formulary}
        hasStickyColumn
        moduleDataSource={moduleDataSource}
        parentPage={EventsConstant.DRUG_FORMULARY_PAGE}
        logEventPropsAll={{
          selectVisible: {
            subSource: EventsConstant.DRUG_FORMULARY_HEAD_SOURCE,
            eventName: EventsConstant.DRUG_FORMULARY_SELECT_VISIBLE,
            page: EventsConstant.DRUG_FORMULARY_PAGE,
          },
          customSelect: {
            subSource: EventsConstant.DRUG_FORMULARY_HEAD_SOURCE,
            eventName: EventsConstant.DRUG_FORMULARY_CUSTOM_SELECTION,
            page: EventsConstant.DRUG_FORMULARY_PAGE,
          },
          viewRow: {
            subSource: EventsConstant.DRUG_FORMULARY_TABLE_SOURCE,
            eventName: EventsConstant.DRUG_FORMULARY_DRUG_VIEWED,
            page: EventsConstant.DRUG_FORMULARY_PAGE,
          },
          editRow: {
            subSource: EventsConstant.DRUG_FORMULARY_TABLE_SOURCE,
            eventName: EventsConstant.DRUG_FORMULARY_DRUG_EDIT_FORM,
            page: EventsConstant.DRUG_FORMULARY_PAGE,
          },
          deleteRow: {
            subSource: EventsConstant.DRUG_FORMULARY_TABLE_SOURCE,
            eventName: EventsConstant.DRUG_FORMULARY_DRUG_DELETE,
            page: EventsConstant.DRUG_FORMULARY_PAGE,
          },
          bulkDelete: {
            subSource: EventsConstant.DRUG_FORMULARY_TABLE_SOURCE,
            eventName: EventsConstant.DRUG_FORMULARY_BULK_DELETE_ACTION,
            page: EventsConstant.DRUG_FORMULARY_PAGE,
          },
        }}
      />
    </Box>
  )
}

export default DrugFormularyDashboard
