import React from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  FAIcon,
  Flex,
  PrimaryButton,
  SecondaryOutlinedButton,
  Text,
} from '@fivehealth/botero'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import BotComplete from '../../assets/bot-complete-avatar.svg'

const AlertModal = ({
  title = 'Well Done!',
  description,
  primaryButton,
  secondaryButton,
  handleClose,
  inModal = false,
  renderCloseTrigger,
  botmdImage = null,
  descriptionLink = {},
  noImage = false,
  width = '720px',
  testId = '',
}) => (
  <Box width={width} p={4} data-testId={testId}>
    <Flex m={2} mb={4} justifyContent="flex-end">
      {primaryButton && (
        <FAIcon
          cursor="pointer"
          onClick={handleClose}
          icon={faTimes}
          fontSize={20}
          color="darkestShade"
        />
      )}
    </Flex>
    {!noImage ? (
      <Flex justifyContent="center" alignItems="center" m={2}>
        <Box as="img" src={botmdImage || BotComplete} />
      </Flex>
    ) : null}
    <Text fontWeight="600" fontSize={2} textAlign="center">
      {title}
    </Text>
    <Text fontWeight="400" mt={2} fontSize={2} textAlign="center">
      {description}
      {descriptionLink ? (
        <>
          &nbsp;
          <Link to={descriptionLink.link} onClick={handleClose}>
            {descriptionLink.label}
          </Link>
        </>
      ) : null}
    </Text>
    {!inModal && !renderCloseTrigger && (
      <Flex mt={2} justifyContent="center" alignItems="center">
        {secondaryButton && (
          <SecondaryOutlinedButton
            borderRadius="8px"
            mr={2}
            onClick={secondaryButton?.onClick}
            logEventProps={secondaryButton?.logEventProps}
          >
            {secondaryButton?.text}
          </SecondaryOutlinedButton>
        )}
        {primaryButton && (
          <PrimaryButton
            borderRadius="8px"
            onClick={primaryButton.onClick}
            logEventProps={primaryButton.logEventProps}
          >
            {primaryButton.text}
          </PrimaryButton>
        )}
      </Flex>
    )}
    {!inModal && !primaryButton && !secondaryButton && renderCloseTrigger
      ? renderCloseTrigger()
      : null}
  </Box>
)

export default AlertModal
