import {
  Box,
  Checkbox,
  CheckboxGroup,
  Dropdown,
  FAIcon,
  Flex,
  H5,
  PrimaryButton,
  Radio,
  SecondaryOutlinedButton,
  Text,
  theme,
} from '@fivehealth/botero'
import { Label } from '@fivehealth/botero/build/components/Checkbox/Checkbox'
import { faChevronDown, faSlidersH } from '@fortawesome/pro-regular-svg-icons'
import Chip from 'old-code/components/Chip/Chip'
import usePostHog from 'old-code/customHooks/usePostHog'
import { get, isArray, mapValues, orderBy, pick, some, truncate } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'

const borderStyle = {
  border: 'solid',
  borderColor:
    '#D5D9DE' /* Needs to be added in botero when theme change taks is done */,
}

const getActiveKeys = (obj) => {
  const resultArr = []
  if (!obj) {
    return null
  }
  const keys = Object.keys(obj)
  keys.forEach((ele) => {
    if (obj[ele]) {
      resultArr.push(ele)
    }
  })
  if (!resultArr.length) return null
  return resultArr
}

const getEventProps = (eventProps, filter, page, filterOptionsSorted) => {
  let metaData = {}
  switch (page) {
    case 'filterUserButton': {
      const department = getActiveKeys(filter.department)
      const designation = getActiveKeys(filter.designation)
      const profileStatus = getActiveKeys(filter.profileStatus)
      metaData = JSON.stringify({
        department,
        designation,
        profileStatus,
      })
      break
    }
    case 'documentsFilterButton': {
      const status = getActiveKeys(filter.status)
      const fileType = getActiveKeys(filter.mimeType)
      metaData = JSON.stringify({
        status,
        fileType,
      })
      break
    }
    case 'filterFavourtiesButton': {
      const contentType = getActiveKeys(filter.contentType)
      metaData = JSON.stringify({
        contentType,
      })
      break
    }
    case 'filterAdminUserButton': {
      const nameIdMap = {}
      filterOptionsSorted?.forEach((option) => {
        option.data?.forEach((ele) => {
          nameIdMap[ele.id] = ele.label
          return null
        })
      })
      const status = getActiveKeys(filter.status)
      const departmentIDs = getActiveKeys(filter.department)
      const designationIDs = getActiveKeys(filter.designation)
      const department = departmentIDs?.map((ele) => nameIdMap[ele])
      const designation = designationIDs?.map((ele) => nameIdMap[ele])
      metaData = JSON.stringify({
        status,
        department,
        designation,
      })
      break
    }
    case 'filterDialoguesButton': {
      const nameIdMap = {}
      filterOptionsSorted[0]?.data.forEach((option) => {
        nameIdMap[option.id] = option.label
        return null
      })
      const userIds = getActiveKeys(filter.admin)
      const users = userIds?.map((ele) => nameIdMap[ele])
      metaData = JSON.stringify({
        users,
      })
      break
    }
    default:
      return eventProps
  }
  return { ...eventProps, metaData }
}

export const FilterButton = withTranslation()(
  ({
    t,
    logEventPropsOpened,
    totalChecked,
    postHogModule,
    logPostHogEvent,
  }) => (
    <SecondaryOutlinedButton
      logEventProps={logEventPropsOpened}
      mr="-16px"
      borderRadius={8}
      startIcon={
        <FAIcon icon={faSlidersH} fontSize="14px" color="darkestShade" />
      }
      endIcon={
        <>
          <div style={{ display: 'flex' }}>
            {totalChecked > 0 && (
              <Chip
                fontWeight="600"
                borderRadius="8px"
                fontSize="12px"
                padding="2px 8px"
                height="24px"
                display="flex"
                alignItems="center"
                flexGrow="0"
                label={totalChecked}
                bg="lightShade"
                textColor="fullShade"
                style={{ marginRight: '8px' }}
              />
            )}

            <FAIcon icon={faChevronDown} fontSize="14px" color="darkestShade" />
          </div>
        </>
      }
      color="darkestShade"
      onClick={() => logPostHogEvent(`${postHogModule}:filter_dropdown_click`)}
    >
      {t('Filters')}
    </SecondaryOutlinedButton>
  )
)

const Filter = ({
  open,
  onOpen,
  onFilterChange,
  onResetFilters,
  onSave,
  onCancel = () => {},
  filterOptions,
  activeFilters,
  testId,
  showRadioOptions,
  logEventPropsOpened = {},
  logEventPropsApply = {},
  logEventPropsCancel = {},
  logEventPropsReset = {},
  postHogModule = '',
  hideVerticalBorder = false,
}) => {
  // TO SORT FILTER OPTIONS ALPHABETICALLY
  const newOptions = useCallback(
    filterOptions
      .map((ele) => {
        if (ele?.shouldSort === false) {
          return ele
        }
        if (ele) {
          const element = ele
          const data = orderBy(ele.data, (o) => o?.label?.toLowerCase())
          element.data = data
          return element
        }
        return null
      })
      .filter((ele) => ele),
    [filterOptions]
  )
  const filterOptionsSorted = newOptions

  const { t } = useTranslation()
  const [filterState, setFilterState] = useState(activeFilters)
  const [selectedCategory, setSelectedCategory] = useState('designation')
  const [maxHeight, setMaxHeight] = useState(window.innerHeight * 0.26)
  const prevFilterState = useRef(filterState)
  const totalChecked = useRef(0)
  const selectAll = useRef({})

  const logPostHogEvent = usePostHog()

  useEffect(() => {
    const handleResize = () => {
      setMaxHeight(window.innerHeight * 0.26)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const onHandleFilterChange = useCallback(
    (filter, data, label) => {
      const changedValue = pick(data, label)

      let nextFilterState
      if (showRadioOptions) {
        nextFilterState = {
          ...activeFilters,
          [filter.id]: filter.multiSelect
            ? data
            : { ...mapValues(data, () => false), ...changedValue },
        }
      } else {
        nextFilterState = {
          ...activeFilters,
          ...filterState,
          [filter.id]: filter.multiSelect
            ? data
            : { ...mapValues(data, () => false), ...changedValue },
        }
      }
      setFilterState(nextFilterState)
    },
    [onFilterChange, filterState, activeFilters]
  )

  function isValidObject(obj) {
    const keys = Object.keys(obj)
    if (
      typeof obj !== 'object' ||
      keys.length === 0 ||
      keys.includes('') ||
      keys.includes('undefined')
    ) {
      return false
    }

    return true
  }

  const setTotalCheckedCount = () => {
    totalChecked.current = Object.values(filterState).reduce(
      (count, filterItem) => {
        if (isValidObject(filterItem)) {
          const values = Object.values(filterItem)
          const numChecked = values.filter(Boolean).length

          return count + numChecked
        }

        return count
      },
      0
    )
  }

  const getSelectAllValue = (filterId) => {
    const value = selectAll.current[filterId] ?? true
    return value
  }

  const onHandleSelectAll = (filterId) => {
    const selectedFilterOptions = filterOptions.find(
      (option) => option.id === filterId
    )

    if (selectedFilterOptions) {
      const selectedState = selectedFilterOptions.data.reduce((acc, option) => {
        acc[option.id] = getSelectAllValue(filterId)
        return acc
      }, {})
      const updatedFilterState = {
        ...filterState,
        [filterId]: selectedState,
      }
      setFilterState(updatedFilterState)

      selectAll.current[filterId] = !getSelectAllValue(filterId)
    }
  }

  const onHandleReset = useCallback(() => {
    selectAll.current = {}
    totalChecked.current = 0
    setFilterState({})
    onResetFilters()
  }, [filterState])

  const mapFilterData = ({ data = [], id }) => {
    if (!isArray(data)) {
      return []
    }
    return data.map((filter) => ({
      ...filter,
      checked: get(filterState, `${id}['${filter.id}']`, false),
    }))
  }

  const hasFiltersSelected = some(filterOptionsSorted, ({ id }) => {
    const filter = filterState[id]
    if (!filter) {
      return false
    }
    return some(Object.values(filter), (v) => !!v)
  })

  const onHandleSave = () => {
    prevFilterState.current = filterState
    setTotalCheckedCount()
    onSave(filterState)
  }

  const switchTab = (tab) => {
    setSelectedCategory(tab)
  }

  const SelectAllButton = ({ selectAllValue, handleSelectAll, filterId }) => (
    <div
      style={{
        ...borderStyle,
        borderWidth: 0,
        borderRightWidth: showRadioOptions || hideVerticalBorder ? 0 : 1,
        whiteSpace: 'nowrap',
      }}
    >
      <H5
        color={theme.colors.primary}
        style={{
          cursor: 'pointer',
          marginRight: '2px',
          width: '90px',
        }}
        onClick={() => {
          handleSelectAll(filterId)
        }}
      >
        {t(selectAllValue ? 'Select all ' : 'Unselect all')}
      </H5>
    </div>
  )

  let filterDataToRender = null

  if (!showRadioOptions) {
    filterDataToRender = filterOptions.map((filter, i) => {
      if (!filter.id) {
        return null
      }
      return (
        <>
          <FilterSection
            testId={filter.testId}
            key={filter.id}
            filter={filter}
            data={mapFilterData(filter)}
            border={i === 0 ? 'none' : null}
            onFilterChange={onHandleFilterChange}
            maxHeight={maxHeight}
            selectAllValue={getSelectAllValue(filter.id)}
          />
          {filter.multiSelect ? (
            <SelectAllButton
              selectAllValue={getSelectAllValue(filter.id)}
              handleSelectAll={onHandleSelectAll}
              filterId={filter.id}
            />
          ) : null}
        </>
      )
    })
  } else if (selectedCategory === 'designation') {
    const finalFilterOptions = filterOptions.find(
      (filter) => filter.id === 'designation'
    )

    filterDataToRender = finalFilterOptions ? (
      <>
        <FilterSection
          testId={testId}
          filter={finalFilterOptions}
          data={mapFilterData(finalFilterOptions)}
          onFilterChange={onHandleFilterChange}
          maxHeight={maxHeight}
          selectedCategory
          selectAllValue={getSelectAllValue('designation')}
        />
        <SelectAllButton
          selectAllValue={getSelectAllValue('designation')}
          handleSelectAll={onHandleSelectAll}
          filterId="designation"
        />
      </>
    ) : null
  } else {
    const finalFilterOptions = filterOptions.find(
      (filter) => filter.id === 'department'
    )

    filterDataToRender = finalFilterOptions ? (
      <>
        <FilterSection
          testId={testId}
          filter={finalFilterOptions}
          data={mapFilterData(finalFilterOptions)}
          onFilterChange={onHandleFilterChange}
          maxHeight={maxHeight}
          selectAllValue={getSelectAllValue('department')}
        />
        <SelectAllButton
          selectAllValue={getSelectAllValue('department')}
          handleSelectAll={onHandleSelectAll}
          filterId="department"
        />
      </>
    ) : null
  }

  return (
    <Flex>
      <Box data-testid={testId}>
        <Dropdown
          label={
            <FilterButton
              logEventPropsOpened={logEventPropsOpened}
              totalChecked={totalChecked.current}
              postHogModule={postHogModule}
              logPostHogEvent={logPostHogEvent}
            />
          }
          open={open}
          onOpenChange={onOpen}
        >
          <Box
            data-testid="filterForm"
            bg="white"
            mx={3}
            boxShadow="medium"
            borderRadius={4}
            minWidth={400}
            maxWidth="80vw"
            {...borderStyle}
            borderWidth={1}
          >
            <Flex
              p={3}
              pb={showRadioOptions ? 2 : 3}
              justifyContent="space-between"
              alignItems="center"
            >
              <Text fontSize={16} fontWeight={600} color="fullShade">
                {t('Filter by')}
              </Text>
              <Flex
                alignItems="center"
                logEventProps={logEventPropsReset}
                onClick={onHandleReset}
              >
                <H5
                  m={0}
                  ml={2}
                  color={hasFiltersSelected ? theme.colors.primary : '#A3A9B1'}
                  style={{ cursor: 'pointer' }}
                >
                  {t('Reset filters')}
                </H5>
              </Flex>
            </Flex>
            {showRadioOptions ? (
              <Flex
                p={3}
                paddingTop="0px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex alignItems="center">
                  <Label>
                    <Radio
                      checked={selectedCategory === 'designation'}
                      onChange={() => switchTab('designation')}
                    />
                    <Text fontSize="14px">Designation</Text>
                  </Label>
                </Flex>

                <Flex alignItems="center">
                  <Label>
                    <Radio
                      checked={selectedCategory === 'department'}
                      onChange={() => {
                        switchTab('department')
                      }}
                    />
                    <Text fontSize="14px">Department</Text>
                  </Label>
                </Flex>
              </Flex>
            ) : null}
            <div
              style={{
                overflowX: 'auto',
                backgroundColor: theme.colors.lightestShade,
                ...borderStyle,
                borderWidth: 0,
                borderTopWidth: 1,
                borderBottomWidth: 1,
              }}
            >
              <Flex
                py={3}
                pl={1}
                bg="lightestShade"
                width={filterOptions.length > 3 ? '1400px' : 'auto'}
              >
                {filterDataToRender}
              </Flex>
            </div>

            <Flex p={3} justifyContent="flex-end">
              <SecondaryOutlinedButton
                logEventProps={logEventPropsCancel}
                onClick={() => {
                  logPostHogEvent(`${postHogModule}:filter_cancel_button_click`)
                  setFilterState(prevFilterState.current)
                  onCancel()
                  onOpen(false)
                }}
              >
                {t('Cancel')}
              </SecondaryOutlinedButton>
              <PrimaryButton
                ml={3}
                data-testid="applyFilterButton"
                onClick={() => {
                  const { metaData } = getEventProps(
                    logEventPropsApply,
                    filterState,
                    testId,
                    filterOptionsSorted
                  )
                  logPostHogEvent(
                    `${postHogModule}:filter_apply_button_click`,
                    { active_condition: metaData }
                  )
                  onHandleSave()
                }}
                logEventProps={getEventProps(
                  logEventPropsApply,
                  filterState,
                  testId,
                  filterOptionsSorted
                )}
              >
                {t('Apply filters')}
              </PrimaryButton>
            </Flex>
          </Box>
        </Dropdown>
      </Box>
    </Flex>
  )
}

const FilterSection = ({
  filter,
  data,
  onFilterChange,
  maxHeight,
  selectAllValue,
  testId,
  ...props
}) => {
  const { t } = useTranslation()
  const renderLabel = ({ icon }) => (
    <Flex alignItems="center">
      <Flex
        height={22}
        width={22}
        mr="6px"
        alignItems="center"
        justifyContent="center"
      >
        {icon}
      </Flex>
      <Text color="DarkestShade" fontSize={14}>
        {t(filter.title)}
      </Text>
    </Flex>
  )

  const isColumnLayout = data.length > 8
  const containerProps = isColumnLayout
    ? {
        display: 'inline-flex',
        maxHeight,
        flexWrap: 'wrap',
        style: {
          writingMode: 'rl-tb',
          flexFlow: 'wrap',
          overflow: 'scroll',
        },
      }
    : {
        display: 'block',
      }

  const CustomLabel = ({ text }) => (
    <div
      style={{
        lineHeight: '1.4em',
        maxHeight: '2.8em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        width: '100%',
      }}
    >
      {text}
    </div>
  )

  return (
    <Box
      data-testid={testId}
      pl={2}
      mr={isColumnLayout ? 1 : 3}
      borderWidth={0}
      borderLeftWidth={1}
      maxWidth={200}
      {...props}
    >
      <H5 letterSpacing="-0.08px" mb={1} color="darkestShade">
        {t(filter.title)}
      </H5>
      <Box {...containerProps}>
        <CheckboxGroup
          onChange={(val, label) => {
            onFilterChange(filter, val, label)
          }}
          key={`${filter.id} ${selectAllValue}`}
        >
          {data.map((item) => (
            <Checkbox
              mb={1}
              data-testid={item.testId}
              key={item.id}
              name={item.id}
              style={{ position: 'relative' }}
              checked={item.checked}
              label={<CustomLabel text={truncate(t(item.label))} />}
              labelProps={{
                mb: -2,
                width: 250, // Adjust as needed
                style: isColumnLayout ? { writingMode: 'horizontal-tb' } : {},
              }}
              renderLabel={item.icon ? () => renderLabel(item) : null}
              textProps={{ fontSize: 14, flex: 1, marginBottom: '-15px' }}
              color="primary"
            />
          ))}
        </CheckboxGroup>
      </Box>
    </Box>
  )
}

FilterSection.defaultProps = {
  onFilterChange: () => {},
}

export default Filter
