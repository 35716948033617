import { Box, Flex, H1, PrimaryButton, Text } from '@fivehealth/botero'
import DropdownMenu from 'old-code/components/DropdownMenu/DropdownMenu'
import React from 'react'
import UserGroupsTable from './UserGroupsTable'
import EventsConstant from '../../../../config/constants/events.constants'

const UserGroupsDashboard = ({
  showEditView,
  showAddView,
  showGroupEditView,
}) => (
  <Box>
    {/* Title */}
    <Flex mt={2} p={2} justifyContent="space-between" alignItems="center">
      <H1>User Groups</H1>

      {/* Dropdown */}
      <DropdownMenu
        label={
          <PrimaryButton
            borderRadius="8px"
            onClick={() => showAddView()}
            logEventProps={{
              subSource: EventsConstant.USER_GROUP_HEAD_SOURCE,
              eventName: EventsConstant.CREATE_USER_GROUP_OPENED,
              page: EventsConstant.USER_GROUP_PAGE,
            }}
          >
            Create User Group
          </PrimaryButton>
        }
        width="300px"
      />
    </Flex>
    <Text
      pl={2}
      pt={1}
      pb={2}
      fontSize="16px"
      fontWeight="400"
      letterSpacing="-0.08px"
    >
      View and manage your user groups for app users
    </Text>

    <Box m={2} mt={4}>
      <UserGroupsTable
        showGroupEditView={showGroupEditView}
        showEditView={showEditView}
      />
    </Box>
  </Box>
)

export default UserGroupsDashboard
