import { trim } from 'lodash'

import DropdownMenu from 'old-code/components/DropdownMenu/DropdownMenu'
import BasicDeleteModal from 'old-code/components/Modals/BasicDeleteModal'
import BasicEditModal from '../../../components/Modals/BasicEditModal'

const SettingsTableRow = ({
  data,
  openModal,
  updateMutation,
  partialEditModalProps,
  typeCaps,
  deleteMutation,
  partialDeleteModalProps,
}) => {
  const editAction = [
    {
      id: `edit${typeCaps}Name`,
      label: 'Edit Name',
      onClick: () =>
        openModal(
          <BasicEditModal
            onSubmit={({ uid, updatedValue }) => {
              updateMutation({
                update: {
                  uid,
                  name: trim(updatedValue.name || updatedValue),
                },
              })
            }}
            data={data}
            {...partialEditModalProps(typeCaps)}
          />
        ),
    },
    {
      id: `delete${typeCaps}`,
      label: 'Delete',
      color: 'red',
      divider: true,
      onClick: () =>
        openModal(
          <BasicDeleteModal
            data={data}
            onSubmit={({ uid, reassignName, createNewTitle }) => {
              deleteMutation({
                uid,
                reassignName,
                createNewTitle,
              })
            }}
            {...partialDeleteModalProps(typeCaps, data)}
          />
        ),
    },
  ]

  return <DropdownMenu fontWeight="normal" actions={editAction} />
}

export default SettingsTableRow
