import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useModal } from 'old-code/context/ModalContext'
import usePostHog from 'old-code/customHooks/usePostHog'

import UserGroupsDashboard from './components/UserGroups/UserGroupsDashboard'
import UserGroupsEdit from './components/UserGroups/UserGroupsEdit'
import UserGroupsAdd from './components/UserGroups/UserGroupsAdd'
import EditUserGroupModal from './components/UserGroups/EditUserGroupModal'

const UserGroups = () => {
  const [view, setView] = useState('dashboard')
  const [viewData, setViewData] = useState({})
  const { openModal, closeModal } = useModal()

  const logPostHogEvent = usePostHog()
  useEffect(() => {
    logPostHogEvent('dashboard:user_groups_view')
  }, [logPostHogEvent])

  const updateView = (id, data) => {
    setViewData({
      ...viewData,
      [id]: data,
    })

    setView(id)
  }

  const showDashboard = (data) => updateView('dashboard', data)
  const showEditView = (data) => updateView('groupEdit', data)
  const showAddView = (data) => updateView('groupAdd', data)
  const showAddMembersView = (data) => updateView('groupAddMembers', data)

  const showGroupEditView = (group) => {
    openModal(<EditUserGroupModal group={group} closeModal={closeModal} />, {
      overflow: 'auto',
      minWidth: 640,
      width: 640,
    })
  }

  switch (view) {
    case 'groupEdit':
      return (
        <UserGroupsEdit
          data={_.get(viewData, 'groupEdit', {})}
          showDashboard={showDashboard}
          showAddMembersView={showAddMembersView}
        />
      )

    case 'groupAdd':
      return (
        <UserGroupsAdd
          data={_.get(viewData, 'groupAdd', {})}
          showDashboard={showDashboard}
          showEditView={showEditView}
          clearSelectBar={() => {}}
        />
      )

    case 'groupAddMembers':
      return (
        <UserGroupsAdd
          data={_.get(viewData, 'groupAddMembers', {})}
          showDashboard={showDashboard}
          showEditView={showEditView}
          clearSelectBar={() => {}}
          skipGroupDetails
        />
      )

    default:
      return (
        <UserGroupsDashboard
          showEditView={showEditView}
          showAddView={showAddView}
          showGroupEditView={showGroupEditView}
        />
      )
  }
}

export default UserGroups
