import React, { useState } from 'react'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

import {
  Box,
  FAIcon,
  Flex,
  H2,
  PrimaryButton,
  SecondaryOutlinedButton,
  theme,
  Select,
  Label,
  useApi,
  Input,
} from '@fivehealth/botero'
import { chain } from 'lodash'
import ErrorMessage from 'old-code/components/ErrorMessage/ErrorMessage'
import useCompleteDepartments from 'old-code/customHooks/useCompleteDepartments'

const INIT_DEFAULT_FETCH = 25

const BasicDeleteModal = (payload) => {
  // States
  const [selectAssignTo, setSelectAssignTo] = useState({
    label: null,
    value: null,
  })
  const [userCount, setUserCount] = useState(null)
  const [newDepDesName, setNewDepDesName] = useState('')
  // Queries
  const {
    queries: { useDepartments, useDesignations },
  } = useApi({
    queries: ['useDepartments', 'useDesignations'],
  })
  // Prop deconstruction
  const {
    onSubmit,
    data,
    closeModal,
    testId,
    title,
    description,
    renderDescription = null,
    buttonLabels = null,
    type,
  } = payload
  let existingList = []
  if (type === 'Department') {
    const allDepartmentsOptions = useCompleteDepartments({
      uidInValue: true,
    })
    existingList = [
      { label: 'Add department', value: 'newField' },
      ...allDepartmentsOptions,
    ]
  } else {
    const { data: dataDesignations } = useDesignations().paginated({
      variables: {
        first: INIT_DEFAULT_FETCH,
        search: newDepDesName,
      },
    })
    existingList = chain(dataDesignations)
      .get('pages', [])
      .flatMap((page) => page || [])
      .value()
      .map((ele) => ({
        label: ele.name,
        value: ele.uid,
      }))
    existingList = [
      { label: 'Add designation', value: 'newField' },
      ...existingList,
    ]
  }

  // Checking for department / designation user count
  if (type === 'Department' && data && data.uid) {
    const departmentUserCountResponse = useDepartments().countSingle({
      variables: { uid: data.uid },
    })
    if (
      departmentUserCountResponse &&
      departmentUserCountResponse.data &&
      departmentUserCountResponse.data.hospitalDepartment &&
      departmentUserCountResponse.data.hospitalDepartment.uid
    ) {
      const count =
        departmentUserCountResponse.data.hospitalDepartment.userCount
      if (count !== userCount) {
        setUserCount(count)
      }
    }
  } else if (type === 'Designation' && data && data.uid) {
    const designationUserCountResponse = useDesignations().countSingle({
      variables: { uid: data.uid },
    })
    if (
      designationUserCountResponse &&
      designationUserCountResponse.data &&
      designationUserCountResponse.data.hospitalDesignation &&
      designationUserCountResponse.data.hospitalDesignation.uid
    ) {
      const count =
        designationUserCountResponse.data.hospitalDesignation.userCount
      if (count !== userCount) {
        setUserCount(count)
      }
    }
  }

  const isDisables = () => {
    if (!userCount) {
      return false
    }
    if (selectAssignTo.label && selectAssignTo.value !== 'newField') {
      return false
    }
    return !(selectAssignTo.value === 'newField' && newDepDesName)
  }

  const alreadyExist = () => {
    const result = existingList.find((e) => e.label === newDepDesName)
    if (result) {
      return true
    }
    return false
  }

  return (
    <Box width={600} p={4} data-test={`delete_modal-${testId}`} overFlow="auto">
      <Flex justifyContent="space-between" alignItems="center">
        <H2>{title}</H2>
        <FAIcon
          cursor="pointer"
          onClick={() => {
            closeModal()
          }}
          icon={faTimes}
          fontSize={20}
          color="darkestShade"
        />
      </Flex>
      {/* Users present, select reassign location */}
      {!description && renderDescription
        ? renderDescription({ totalCount: userCount, action: type })
        : description}
      {userCount ? (
        <Flex flexDirection="column" flex="0 0 50%" key="existing">
          <Label
            fontWeight="700"
            color="#697481"
            fontSize="14px"
            mt="16px"
            mb="8px"
          >
            Reassign users with this {type.toLowerCase()} to
          </Label>
          <Select
            classNamePrefix="workspace"
            options={existingList.filter((ele) => ele.value !== data.uid)}
            onChange={(e) => {
              setSelectAssignTo(e)
            }}
            menuPlacement="auto"
          />
        </Flex>
      ) : null}
      {/* Create new location */}
      {selectAssignTo?.value === 'newField' ? (
        <Flex flexDirection="column" flex="0 0 50%" key="new">
          <Input
            name="name"
            label={`Add new ${type.toLowerCase()}`}
            fontSize={12}
            fontWeight={500}
            value={newDepDesName}
            inputProps={{
              maxLength: 50,
            }}
            placeholder={`Enter ${type.toLowerCase()} name`}
            onChange={({ target }) => {
              setNewDepDesName(target.value)
            }}
          />
          {alreadyExist() ? (
            <ErrorMessage
              error={`${type} with name "${newDepDesName}" already exists.`}
            />
          ) : null}
        </Flex>
      ) : null}

      <Flex mt={4} justifyContent="end" alignItems="end">
        <SecondaryOutlinedButton
          borderRadius="8px"
          mr={2}
          onClick={() => {
            closeModal()
          }}
        >
          {(buttonLabels && buttonLabels[0]) || 'Cancel'}
        </SecondaryOutlinedButton>
        <PrimaryButton
          disabled={isDisables() || alreadyExist()}
          borderRadius="8px"
          onClick={() => {
            onSubmit({
              uid: data?.uid,
              reassignName:
                selectAssignTo?.value === 'newField'
                  ? null
                  : selectAssignTo?.label,
              createNewTitle: newDepDesName,
            })
          }}
          style={{ backgroundColor: theme.colors.danger }}
          disa
        >
          {(buttonLabels && buttonLabels[1]) ||
            (userCount ? `Delete and Reassign ${type}` : `Delete ${type}`)}
        </PrimaryButton>
      </Flex>
    </Box>
  )
}

export default BasicDeleteModal
