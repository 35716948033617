import {
  Body,
  Box,
  FAIcon,
  Flex,
  theme,
  Tooltip,
  Text as BaseText,
} from '@fivehealth/botero'
import styled from 'styled-components'
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons'
import { useState } from 'react'
import { isEmpty, isEqual, map } from 'lodash'
import { generateTextIcon } from 'old-code/views/CustomModule/CustomModuleUtils'

const Text = styled(BaseText)`
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(
      255,
      255,
      255,
      0.1
    ); // black background with 10% opacity
    border-radius: 5px; // rounded corners
  }
`

const WrapInTooltip = ({
  showTooltip = false,
  item,
  isSubmenu = false,
  children,
  handleSubMenuClick,
  handleItemClick,
}) => {
  if (showTooltip) {
    return (
      <Tooltip
        tooltip={
          item.subMenu ? (
            <Flex flexDirection="column" width="244px" py={1} px={1}>
              <Body
                fontWeight="500"
                fontSize="16px"
                color="white"
                lineHeight="24px"
                mb="6px"
              >
                {item.label}
              </Body>
              {item.subMenu && (
                <Box
                  backgroundColor="rgba(163, 169, 177, 0.8)"
                  width={2}
                  ml="4px"
                  mt={1}
                >
                  <Flex flexDirection="column" width="300px">
                    {item.subMenu.map((subMenu, index) => (
                      <Text
                        cursor="pointer"
                        key={index}
                        color="#FFFFFF"
                        fontWeight="500"
                        fontSize="16px"
                        width="175px"
                        paddingTop="5px"
                        paddingLeft="10px"
                        paddingRight="10px"
                        paddingBottom="5px"
                        lineHeight="24px"
                        onClick={() => {
                          handleItemClick(true)
                          handleSubMenuClick(subMenu)
                        }} // Add click handler
                        ml={3}
                        mt={index !== 0 && 2}
                      >
                        {subMenu.label}
                      </Text>
                    ))}
                  </Flex>
                </Box>
              )}
            </Flex>
          ) : (
            <Flex width="244px" py={1} px={1}>
              <Body small color="white" width="max-content">
                {item.label}
              </Body>
            </Flex>
          )
        }
        style={{ textAlign: 'left' }}
        toolTipElementProps={{
          ...item.toolTipElementProps,
        }}
        toolTipOptions={{
          strategy: 'fixed',
          placement: 'right',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, isSubmenu ? 22 : 32],
              },
            },
          ],
        }}
        logEventProps={item.logEventProps}
      >
        {children}
      </Tooltip>
    )
  }

  return children
}

const SubMenuItems = ({
  item,
  activeTab,
  expand,
  handleSubMenuClick,
  logEventProps,
}) => {
  const [hover, setHover] = useState(false)

  const isActive = isEqual(item.id, activeTab)
  const active = isActive || hover

  const getJustifySetting = () => {
    if (expand) {
      return 'left'
    }
    return 'center'
  }

  const getBackgroundColor = () => {
    if (expand) {
      return active ? theme.colors.lightShade : 'transparent'
    }
    return theme.colors.lightShade
  }

  return (
    <Flex direction="row" alignItems="center" justifyContent="right">
      {expand && (
        <Box backgroundColor="#A3A9B1" width={2} height={52} mr={20} ml={28} />
      )}
      <Flex
        bg={getBackgroundColor()}
        borderRadius={8}
        py={expand ? '10px' : 0}
        px={expand ? 2 : 0}
        width={expand ? '100%' : '44px'}
        height="44px"
        m="auto"
        mt={1}
        cursor={item.onClick ? 'pointer' : 'default'}
        alignItems="center"
        justifyContent={getJustifySetting()}
        onMouseEnter={() => (item.onClick ? setHover(true) : null)}
        onMouseLeave={() => (item.onClick ? setHover(false) : null)}
        onClick={() => {
          handleSubMenuClick(item)
        }}
        logEventProps={logEventProps}
      >
        {item.icon && (
          <Flex
            data-testid={item.testId}
            justifyContent="center"
            alignItems="center"
            width={24}
            height={24}
          >
            <FAIcon
              icon={item.icon}
              style={{
                fontSize: 17,
                color: isActive
                  ? theme.colors.primary
                  : theme.colors.darkestShade,
                ...item.iconProps,
              }}
            />
          </Flex>
        )}
        {item.iconName && !expand ? (
          <Body
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: isActive
                ? theme.colors.primary
                : theme.colors.darkestShade,
              ...item.iconProps,
            }}
          >
            {generateTextIcon(item.label)}
          </Body>
        ) : null}
        {expand && item.label && (
          <Body ml="14px" {...item.labelProps}>
            <BaseText
              fontSize="16px"
              fontWeight="500"
              color={isActive ? theme.colors.primary : 'darkestShade'}
              letterSpacing="-0.08px"
              lineHeight="24px"
            >
              {item.label}
            </BaseText>
          </Body>
        )}
      </Flex>
    </Flex>
  )
}

const SideBarSubMenuItem = ({
  item,
  activeTab,
  handleSubMenuClick,
  expand,
  logEventProps,
  subMenuExpand,
  getSubMenuExpand,
  ...props
}) => {
  if (!subMenuExpand) {
    return null
  }
  const active = item.subMenu
    ? item.subMenu.find((subMenu) => subMenu.id === activeTab)
    : false

  const handleItemClick = (keepOpen = false) => {
    if (isEmpty(item.subMenu)) {
      return
    }
    if (!expand) {
      if (!keepOpen) {
        getSubMenuExpand(!subMenuExpand.value)
        return
      }
      getSubMenuExpand(true)
    }
  }

  const renderSubMenuItems = () => {
    const subMenuItems = item.subMenu
    return (
      <>
        {map(
          subMenuItems,
          (subMenuItem, index) =>
            !subMenuItem.disabled && (
              <WrapInTooltip
                key={index}
                showTooltip={!expand}
                item={subMenuItem}
                handleSubMenuClick={handleSubMenuClick}
                handleItemClick={handleItemClick}
                isSubmenu
              >
                <SubMenuItems
                  key={index}
                  item={subMenuItem}
                  activeTab={activeTab}
                  expand={expand}
                  handleItemClick={handleItemClick}
                  handleSubMenuClick={handleSubMenuClick}
                  logEventProps={subMenuItem.logEventProps}
                />
              </WrapInTooltip>
            )
        )}
      </>
    )
  }

  const getBackgroundColor = () => {
    if (expand) {
      return active ? theme.colors.lightShade : 'transparent'
    }
    return active ? theme.colors.lightShade : 'transparent'
  }

  const getIconColor = () => {
    if (!subMenuExpand.value && active) {
      return theme.colors.primary
    }

    return theme.colors.fullShade
  }

  return (
    <Flex
      flexDirection="column"
      borderRadius={expand ? 0 : 8}
      width={expand ? '100%' : '44px'}
      m="auto"
    >
      <Flex
        bg={getBackgroundColor()}
        borderRadius={8}
        py={expand ? '10px' : 0}
        px={expand ? 2 : 0}
        width={expand ? '100%' : '44px'}
        height="44px"
        m="auto"
        cursor="pointer"
        alignItems="center"
        justifyContent={expand ? 'space-between' : 'center'}
        onClick={() => getSubMenuExpand(!subMenuExpand.value)}
        {...props}
      >
        <WrapInTooltip
          handleItemClick={handleItemClick}
          handleSubMenuClick={handleSubMenuClick}
          showTooltip={!expand}
          item={item}
        >
          <Flex
            width="100%"
            onClick={handleItemClick}
            justifyContent={expand ? 'left' : 'center'}
          >
            {item.icon && (
              <Flex
                data-testid={item.testId}
                justifyContent="center"
                alignItems="center"
                width={24}
                height={24}
              >
                <FAIcon
                  icon={item.icon}
                  style={{
                    cursor: 'pointer',
                    fontSize: 20,
                    color: getIconColor(),
                    ...item.iconProps,
                  }}
                />
              </Flex>
            )}

            {expand && item.label && (
              <Body ml="14px" medium {...item.labelProps}>
                <BaseText color={getIconColor()}>{item.label}</BaseText>
              </Body>
            )}
          </Flex>
        </WrapInTooltip>
        {expand && item.label && (
          <Box ml={1}>
            <FAIcon
              icon={subMenuExpand.value ? faAngleUp : faAngleDown}
              style={{
                fontSize: 16,
                color: theme.colors.fullShade,
                marginleft: 16,
              }}
            />
          </Box>
        )}
      </Flex>
      {subMenuExpand.value && item.label && renderSubMenuItems()}
    </Flex>
  )
}

const SideBarSubMenu = (props) => {
  const { item } = props
  return <Box mb={1}>{item.label && <SideBarSubMenuItem {...props} />}</Box>
}

export default SideBarSubMenu
