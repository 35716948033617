import React, { useState, useRef, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import * as XLSX from 'xlsx/xlsx.mjs'

import {
  Box,
  Checkbox,
  Dialog,
  Flex,
  FAIcon,
  Text,
  PrimaryButton,
  SecondaryOutlinedButton,
  H5,
  useApi,
} from '@fivehealth/botero'

import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { useModal } from '../../../../context/ModalContext'
import BotExcelAvatar from '../../../../assets/bot-excel-avatar.svg'
import {
  generateRandomId,
  getNodesStartedWithinLastXMinutes,
  checkTemplate,
} from '../../../../../Utils'

import UserManagementUploadLoading from './UserManagementUploadLoading'
import UserManagementPopup from './UserManagementPopup'

const DialogTitle = ({ label, onClick, ...props }) => (
  <Flex justifyContent="space-between" mb={3} {...props}>
    <Text fontWeight={600} fontSize={3}>
      {label}
    </Text>
    ``
  </Flex>
)

const DialogSubTitle = ({ label, onClick, ...props }) => (
  <Flex justifyContent="space-between" mb={3} {...props}>
    <Text fontWeight={400} fontSize={2}>
      {label}
    </Text>
  </Flex>
)

const BackButton = styled(Flex)`
  &:hover {
    opacity: 0.8;
  }
`

const UserManagementUpload = ({
  label,
  showDashboard,
  showUploadStats,
  isFullSync,
}) => {
  const hiddenFileInput = useRef()
  const { openModal, closeModal } = useModal()
  const [variable, setVariable] = useState(generateRandomId())
  const [uploadFile, setUploadFile] = useState(null)
  const [uploadError, setUploadError] = useState('')
  const [uploadFEError, setUploadFEError] = useState('')
  const [uploadedData, setUploadedData] = useState({ uploadID: '' })
  const [triggerSync, setTriggerSync] = useState(false)
  const [orgKey, setOrgKey] = useState('')
  const [openExcelOption, setOpenExcelOption] = useState(false)
  const [startUserPolling, setStartUserPolling] = useState(false)
  const queryClient = useQueryClient()
  const [timeOutCounter, setTimeOutCounter] = useState(1)

  const [excelOptions, setExcelOptions] = useState({
    sheetNames: [],
  })

  const handleLoading = (action, errMsg = null) => {
    openModal(
      <UserManagementUploadLoading
        closeModal={closeModal}
        showDashboard={showDashboard}
        action={action}
        errorMessage={errMsg}
      />,
      { width: '650px' },
      false
    )
  }

  const {
    queries: {
      useStitchUpload,
      useEinsteinSyncUserDataSource,
      useEinsteinExcelDataSources,
      useEinsteinAdministrator,
    },
  } = useApi({
    queries: [
      'useStitchUpload',
      'useEinsteinSyncUserDataSource',
      'useEinsteinExcelDataSources',
      'useEinsteinAdministrator',
    ],
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  const { data: userSourceStatus } = useEinsteinExcelDataSources({
    variables: { random: variable, key: orgKey },
  })

  const { mutateAsync: uploadFileToStitch } = useStitchUpload({
    variables: {},
    onSuccess: () => {},
  })

  useEffect(() => {
    if (currentAdmin) {
      const key = ((currentAdmin || {}).hospital || {}).organizationKey || ''
      if (_.isEmpty(key)) return

      setOrgKey(`${key}_template`)
    }
  }, [currentAdmin])

  const { mutateAsync: uploadEinstienSyncDataSource } =
    useEinsteinSyncUserDataSource({
      variables: {},
      onSuccess: ({ data }) => {
        if (data.einsteinSyncExcelUserDataSource) {
          queryClient.invalidateQueries('einsteinExcelDataSources')
          queryClient.invalidateQueries('allDirectoryProfiles')
          setTriggerSync(false)
          setStartUserPolling(true)
        }
      },
      onError: () => {
        queryClient.invalidateQueries('einsteinExcelDataSources')
        queryClient.invalidateQueries('allDirectoryProfiles')
        setTriggerSync(false)
        setStartUserPolling(false)
        closeModal()
      },
    })

  const readExcelFile = (file, options) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (event) => {
        const wb = XLSX.read(event.target.result, {
          type: 'binary',
          ...options,
        })
        resolve(wb)
      }

      reader.onerror = (err) => {
        reject(err)
      }

      reader.readAsBinaryString(file)
    })

  useEffect(() => {
    if (!_.isEmpty(uploadedData.uploadID) && triggerSync) {
      uploadEinstienSyncDataSource({
        input: {
          syncMode: isFullSync ? 'SYNC' : 'ADD',
          preview: true,
          inputFormat: 'TEMPLATE',
          fileId: uploadedData.uploadID.split('/')[1],
        },
      })
    }
  }, [triggerSync])

  const handleUpload = () => {
    if (!_.isNull(uploadFile)) {
      handleLoading('pending')
      uploadFileToStitch({
        input: {
          key: 'einstein',
          mimeType: uploadFile.type,
        },
      })
        .then(({ stitchCreateUploadUrl }) => {
          const body = new FormData()
          const uploadedFile = uploadFile
          _.map(stitchCreateUploadUrl.fields, (value, key) => {
            body.append(key, value)
          })
          body.append('file', uploadedFile)
          setUploadedData({
            ...uploadedData,
            uploadID: stitchCreateUploadUrl.uploadId,
          })
          return fetch(stitchCreateUploadUrl.url, {
            method: 'post',
            body,
          })
        })
        .then(() => {
          setTimeout(() => setTriggerSync(true), 1500) // to allow time for sync to start after upload
        })
        .catch(() => {
          handleLoading('error')
        })
    } else {
      setUploadError('Error: Please select file for upload.')
    }
  }

  const getUploadStatus = () => {
    if (uploadFile) {
      return (
        <Flex alignItems="center" justifyContent="center" mt={2}>
          <Text color="green">{`Selected: ${uploadFile.name}`}</Text>
        </Flex>
      )
    }

    if (uploadError) {
      return (
        <Flex
          alignItems="center"
          justifyContent="center"
          mt={2}
          data-testid="uploadErrorMessage"
        >
          <Text color="danger">{uploadError}</Text>
        </Flex>
      )
    }

    return null
  }

  const checkFileSize = (size) =>
    _.chain(size)
      .divide(1024) // KB
      .divide(1024) // MB
      .inRange(5) // 5MB
      .value()

  const getSheetNames = (file) => {
    if (file) {
      readExcelFile(file, { bookSheets: true }).then((workbook) =>
        setExcelOptions({
          ...excelOptions,
          sheetNames: _.map(workbook.SheetNames, (name) => ({
            name,
            selected: false,
          })),
        })
      )
    }
  }

  const nextTimeout = (timeoutStep) => {
    let maxTimeOutStep = timeoutStep
    if (timeoutStep > 6) {
      maxTimeOutStep = 6
    }

    const timeout = (maxTimeOutStep * (1 + Math.sqrt(5))) / 2.0
    return Math.round(timeout) * 1000
  }

  useEffect(() => {
    if (startUserPolling && !userSourceStatus) {
      queryClient.invalidateQueries('einsteinExcelDataSources')
      setTimeout(() => {
        setVariable(generateRandomId(10))
      }, nextTimeout(1))
    }
  }, [startUserPolling])

  useEffect(() => {
    // Call the function to get the nodes started within the last X minutes
    const xMinutes = 5 // Set this to the desired number of minutes
    const recentNodes = getNodesStartedWithinLastXMinutes(
      userSourceStatus?.einsteinUserDataSources?.edges || [],
      xMinutes
    )

    // Use the first of the selected nodes, if any
    const selectedNode = recentNodes.length > 0 ? recentNodes[0].node : null

    if (
      selectedNode &&
      selectedNode.syncStatus !== 'FAILED' &&
      !_.isEmpty(selectedNode.metadata) &&
      startUserPolling
    ) {
      setStartUserPolling(false)
      const uploadResponse = selectedNode.metadata
      uploadResponse.fileId = uploadedData.uploadID
      showUploadStats({
        label,
        uploadResponse,
        excelOptions,
      })
      closeModal()
    } else if (
      selectedNode &&
      selectedNode.syncStatus === 'FAILED' &&
      startUserPolling
    ) {
      setStartUserPolling(false)
      const emptyErrMsg = selectedNode.error
      if (emptyErrMsg.includes('No entry found')) {
        handleLoading('error', emptyErrMsg.replace(/[`'[\]/]/gi, ''))
      } else {
        handleLoading('error')
      }
    } else if (startUserPolling) {
      setTimeout(() => {
        setVariable(generateRandomId(10))
      }, nextTimeout(timeOutCounter))
      setTimeOutCounter(timeOutCounter + 1)
    }
  }, [userSourceStatus])

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      // checkTemplate(acceptedFiles[0])
      checkTemplate(
        acceptedFiles[0],
        '/templates/userManagementTemplate.xlsx',
        setUploadFile,
        setUploadError
      )
      if (!_.endsWith(acceptedFiles[0].name, '.xlsx')) {
        setUploadFile(null)
        setUploadError('Error: Only .xlsx file is allowed.')
      } else if (!checkFileSize(acceptedFiles[0].size)) {
        setUploadFile(null)
        setUploadError('Error: Maximum file size allowed is 5MB.')
      } else {
        getSheetNames(acceptedFiles[0])
        setUploadFile(acceptedFiles[0])
        setUploadError('')
      }
    } else {
      setUploadFile(null)
      setUploadError('Error: Only one file is allowed.')
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const getDescription = () => {
    if (isFullSync) {
      return (
        <Text>
          Download and fill in&nbsp;
          <Link
            to="/templates/userManagementTemplate.xlsx"
            target="_blank"
            download
          >
            this template
          </Link>
          . Current user list will be replaced with the new list of hospital app
          users.
        </Text>
      )
    }

    return (
      <Text>
        Download and fill in&nbsp;
        <Link
          to="/templates/userManagementTemplate.xlsx"
          target="_blank"
          download
        >
          this template
        </Link>
        . Hospital app user accounts will be updated based on the instructions
        in the sheet.
      </Text>
    )
  }

  const handleCheckboxChange = (id, value) => {
    setExcelOptions({
      ...excelOptions,
      sheetNames: _.map(excelOptions.sheetNames, (sheet) => {
        if (_.isEqual(sheet.name, id)) {
          return {
            ...sheet,
            selected: value,
          }
        }
        return sheet
      }),
    })
  }
  const handleClick = () => {
    if (uploadFile) {
      handleUpload()
    }
  }
  return (
    <Box>
      {/* Back Button */}
      <BackButton
        id="backBtn"
        alignItems="center"
        mb={1}
        ml="16px"
        hover={{ opacity: 0.6 }}
        cursor="pointer"
        onClick={() => showDashboard()}
      >
        <FAIcon
          icon={faChevronLeft}
          color="darkestShade"
          fontWeight="500"
          style={{ fontSize: 12, fontWeight: 500, marginRight: 4 }}
        />
        <H5 fontSize={14} color="darkestShade">
          Back
        </H5>
      </BackButton>
      {/* Title */}
      <Flex alignItems="center" justifyContent="space-between">
        <Text m={2} mt={0} fontSize={5} fontWeight="600">
          {label}
        </Text>
        <Flex justifyContent="right">
          <PrimaryButton
            borderRadius="8px"
            m={2}
            disabled={!uploadFile}
            onClick={() => {
              if (isFullSync) {
                openModal(
                  <UserManagementPopup
                    closeModal={() => {
                      closeModal()
                      showDashboard()
                    }}
                    onClick={handleClick}
                  />
                )
              } else {
                handleClick()
              }
            }}
            data-testid="UploadContinueButton"
          >
            Continue
          </PrimaryButton>
        </Flex>
      </Flex>

      {/* Description */}
      <Box m={2} mt={3}>
        {getDescription()}
      </Box>

      <Flex
        m={2}
        mt={10}
        alignItems="center"
        justifyContent="center"
        data-testid="userManagementUpload"
      >
        <Box>
          <Text fontSize={1}>Upload file</Text>
          <Flex
            mt={1}
            width="600px"
            border="dashed"
            borderWidth={2}
            borderColor="#00000030"
            alignItems="center"
            justifyContent="center"
          >
            <Box p={8} {...getRootProps()}>
              <Flex alignItems="center" justifyContent="center" mb={3}>
                <Box as="img" pl={2} src={BotExcelAvatar} />
              </Flex>
              <Flex alignItems="center" justifyContent="center">
                <input
                  type="file"
                  style={{ display: 'none' }}
                  {...getInputProps()}
                  ref={hiddenFileInput}
                  accept=".xlsx"
                />
              </Flex>
              <Flex alignItems="center" justifyContent="center">
                <Text fontWeight="400" fontSize="14px" mr={1}>
                  Drag & Drop user list sheet here or
                </Text>
                <SecondaryOutlinedButton
                  borderRadius="8px"
                  onClick={() => hiddenFileInput.current.click()}
                >
                  Browse Files
                </SecondaryOutlinedButton>
              </Flex>
              <Flex alignItems="center" justifyContent="center" mt={1}>
                <Text fontWeight="400" fontSize="11px" color="darkShade">
                  Allowed file types: .XLSX. Maximum size: 5MB.
                </Text>
              </Flex>
              {getUploadStatus()}
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Dialog type="basic" open={openExcelOption} onClose={() => {}}>
        <Box width="440px" p="8px">
          <DialogTitle
            label={`${
              ((excelOptions || {}).sheetNames || []).length
            } sheets found in ${(uploadFile || {}).name || ''}`}
            onClick={() => setOpenExcelOption(!openExcelOption)}
          />
          <DialogSubTitle
            label="Select the sheets you would like to sync:"
            onClick={() => setOpenExcelOption(!openExcelOption)}
          />
          {uploadFEError && (
            <Text color="danger" fontSize={12} mt={1} mb={1}>
              {uploadFEError}
            </Text>
          )}
          {_.map(excelOptions.sheetNames, ({ name, selected }, index) => (
            <Box mb={2} key={index}>
              <Checkbox
                label={name}
                checked={selected}
                onChange={(ev) => handleCheckboxChange(name, ev.target.checked)}
              />
            </Box>
          ))}
          <Flex justifyContent="right" mt={2}>
            <SecondaryOutlinedButton
              borderRadius="8px"
              mr={2}
              onClick={() => {
                setUploadFEError(null)
                setOpenExcelOption(false)
              }}
            >
              Cancel
            </SecondaryOutlinedButton>
            <PrimaryButton
              data-testid="UploadSubmitButton"
              borderRadius="8px"
              onClick={() => {
                if (excelOptions && excelOptions.sheetNames) {
                  const selectedSheet = _.filter(
                    excelOptions.sheetNames,
                    (sheet) => sheet.selected === true
                  )
                  if (!_.isEmpty(selectedSheet)) {
                    setOpenExcelOption(!openExcelOption)
                    handleUpload()
                  } else {
                    setUploadFEError(
                      'Please choose at least one sheet to continue'
                    )
                  }
                } else {
                  setUploadFEError(
                    'Please upload excel sheet sheet to continue'
                  )
                }
              }}
            >
              Continue
            </PrimaryButton>
          </Flex>
        </Box>
      </Dialog>
    </Box>
  )
}

export default UserManagementUpload
