import { Flex, H1, Body, Box, PrimaryButton, FAIcon } from '@fivehealth/botero'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import DownloadingTaco from '../../assets/downloadingTaco.gif'

const DownloadSection = (props) => (
  <Flex
    style={{
      padding: '2.5rem',
      borderRadius: '12px',
    }}
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
  >
    <Box
      onClick={props.closeModal}
      style={{ position: 'absolute', top: 30, right: 30 }}
    >
      <FAIcon cursor="pointer" icon={faTimes} color="darkestShade" />
    </Box>
    <img src={DownloadingTaco} alt="File Ready" height={300} width={300} />
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt="-10px"
    >
      <H1 color="#0a0a0a!" mb={4}>
        File ready for download!
      </H1>
      <Body color="#4a4a4a">
        Your download should start in few seconds.
        <br />
        If not click the link to download your file
        <br />
        <br />
      </Body>
    </Flex>
    <Flex mt={1}>
      <PrimaryButton
        disabled={props.disabled}
        onClick={() => props.downloadFile()}
        borderRadius="8px"
        fontWeight="500"
      >
        Download File
      </PrimaryButton>
    </Flex>
  </Flex>
)

export default DownloadSection
