import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Box,
  Flex,
  FAIcon,
  SecondaryOutlinedButton,
  Text,
  H4,
  H5,
  useApi,
} from '@fivehealth/botero'
import { useParams, useHistory } from 'react-router-dom'

import { faEdit } from '@fortawesome/pro-regular-svg-icons'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'

import { useQueryClient } from 'react-query'
import { isEmpty, map, startCase, get } from 'lodash'
import { format, parseISO } from 'date-fns'
import EinsteinAdminAddEditForm from './EinsteinAdminAddEditForm'
import BotmdCryBaby from '../../assets/bot-crying-avatar.svg'
import { useEinsteinAdministratorProfile } from '../../context/EinsteinAdministratorContext'
import Chip from '../../components/Chip/Chip'
import { AlertModalConfirmation } from './helper'
import EventsConstant from '../../config/constants/events.constants'
import { useModal } from '../../context/ModalContext'

const EinsteinAdminInfo = () => {
  const { openModal, closeModal } = useModal()
  const { uid } = useParams()
  const history = useHistory()
  const [showEditButton, setShowEditButton] = useState(true)
  const queryClient = useQueryClient()

  const currentUserLoggedIn = get(
    useEinsteinAdministratorProfile(),
    'einsteinAdmin',
    {}
  )

  const {
    queries: {
      useEinsteinAdmin,
      useDepartments,
      useDesignations,
      useDivisions,
    },
  } = useApi({
    queries: [
      'useEinsteinAdmin',
      'useDepartments',
      'useDesignations',
      'useDivisions',
    ],
  })

  const { data: dataAdministrator } = useEinsteinAdmin().getByUid({
    variables: {
      uid,
    },
    select: ({ einsteinAdministrator }) => einsteinAdministrator,
  })

  const { data: dataDepartments } = useDepartments().list({
    variables: {},
    select: ({ hospitalDepartments }) =>
      hospitalDepartments?.edges?.map((o) => o?.node),
  })

  const { data: dataDesignations } = useDesignations().list({
    variables: {},
    select: ({ hospitalDesignations }) =>
      hospitalDesignations?.edges?.map((o) => o?.node),
  })

  const { data: dataDivisions } = useDivisions().list({
    variables: {},
    select: ({ hospitalDivisions }) =>
      hospitalDivisions?.edges?.map((o) => o?.node),
  })

  const { mutateAsync: updateAdminMutation } = useEinsteinAdmin().update({
    variables: {},
    onSuccess: ({ data }) => {
      const modalProps =
        data?.errors?.length > 0
          ? {
              title: data?.errors[0]?.message.replace(/[`'[\]/]/gi, ''),
              description: data?.errors[0]?.message.includes(
                'Insufficient administrator licenses'
              )
                ? null
                : 'Please contact another Super Admin to perform this action.',
              botmdImage: BotmdCryBaby,
            }
          : {
              title: 'Admin profile was updated successfully.',
              description: 'Please check the updates the admin list',
            }

      openModal(
        <AlertModalConfirmation
          logEventProps={{
            subSource: EventsConstant.ADMIN_INFO_PAGE,
            eventName: EventsConstant.ADMIN_PROFILE_EDITED_SUCCESSFULLY,
            page: EventsConstant.ADMIN_INFO_PAGE,
          }}
          closeModal={() => {
            closeModal()
            queryClient.invalidateQueries('einsteinAdminGetByUid')
          }}
          {...modalProps}
        />
      )
    },
  })

  useEffect(() => {
    if (dataAdministrator) {
      if (dataAdministrator.uid === currentUserLoggedIn.uid) {
        setShowEditButton(false)
      }
    }
  }, [dataAdministrator])

  const BackButton = styled(Flex)`
    &:hover {
      opacity: 0.8;
    }
  `
  const getDisplayDetails = (data) => {
    let accountType = 'Super Admin'
    let division = null
    let departments = null
    if (!data.isSuper) {
      if (data.divisions.length) {
        accountType = 'Division Admin'
        division = data.divisions?.[0]?.name
      } else {
        accountType = 'Department Admin'
      }
      if (data.divisions.length) {
        departments = data.divisions?.[0].departments
          .map((e) => e.name)
          .join(', ')
      } else {
        departments = data.departments.map((e) => e.name).join(', ')
      }
    }
    const details = {
      name: data?.firstName,
      mobile_number: data?.identifiableInformations?.find(
        (d) => d.type === 'PHONE'
      )?.encryptedValue,
      email: data?.identifiableInformations?.find((d) => d.type === 'EMAIL')
        ?.encryptedValue,
      department: data?.department?.name,
      designation: data?.designation?.name,
      account_status: (
        <Chip
          cursor="pointer"
          label={data?.isActivated ? 'Activated' : 'Deactivated'}
          borderRadius="6px"
          fontSize="14px"
          bg={data?.isActivated ? '#DCF7E8' : '#F4F6F8'}
          textColor={data?.isActivated ? '#27AE60' : '#697481'}
        />
      ),
      account_type: accountType,
      division,
      departments,
      created_on: format(
        parseISO(data?.createdOn || ''),
        'dd MMMM yyyy, h:mm a'
      ),
      created_by: data?.createdBy?.firstName || '-',
    }
    if (!details.division) {
      delete details.division
    }
    if (!details.departments) {
      delete details.departments
    }
    return details
  }

  return (
    <Box>
      <BackButton
        id="backBtn"
        alignItems="center"
        mb={1}
        ml="16px"
        hover={{ opacity: 0.6 }}
        cursor="pointer"
        onClick={() => history.push('/einstein_admin')}
      >
        <FAIcon
          icon={faChevronLeft}
          color="darkestShade"
          fontWeight="500"
          style={{ fontSize: 12, fontWeight: 500, marginRight: 4 }}
        />
        <H5 fontSize={14} color="darkestShade">
          Back to Einstein Admin
        </H5>
      </BackButton>

      {!isEmpty(dataAdministrator) && (
        <>
          <Text ml={2} mb={2} mr={2} mt={1} fontSize={5} fontWeight="bold">
            {`${dataAdministrator?.firstName}`}
          </Text>

          <Box>
            <Flex p={2} justifyContent="space-between" alignItems="center">
              <H4 fontWeight="bold">Admin Details</H4>
              {showEditButton && (
                <Flex justifyContent="right" alignItems="center">
                  <SecondaryOutlinedButton
                    endIcon={<FAIcon icon={faEdit} />}
                    icon={faEdit}
                    color="darkestShade"
                    fontWeight="700"
                    fontSize="14px"
                    borderRadius="8px"
                    onClick={() =>
                      openModal(
                        <EinsteinAdminAddEditForm
                          closeModal={closeModal}
                          label="Edit User"
                          enableAddUser={false}
                          dataDepartments={dataDepartments}
                          dataDesignations={dataDesignations}
                          dataDivisions={dataDivisions}
                          updateAdminMutation={updateAdminMutation}
                          currentProfile={dataAdministrator || {}}
                        />
                      )
                    }
                  >
                    Edit
                  </SecondaryOutlinedButton>
                </Flex>
              )}
            </Flex>
          </Box>

          <Box pr={2} pb={2} pl={2}>
            {!isEmpty(dataAdministrator) &&
              map(getDisplayDetails(dataAdministrator), (value, key) => (
                <Flex key={key} pt={2} pr={2} pb={2}>
                  <H5
                    width="200px"
                    mr={4}
                    fontWeight="600"
                    color="darkestShade"
                  >
                    {startCase(key)}
                  </H5>
                  <Text width="400px" fontWeight="400" fontSize="14px">
                    {value}
                  </Text>
                </Flex>
              ))}
          </Box>
        </>
      )}
    </Box>
  )
}

export default EinsteinAdminInfo
