/* eslint-disable no-template-curly-in-string */
import {
  Box,
  FAIcon,
  Flex,
  H1,
  PrimaryButton,
  Text,
  useApi,
  useDebounce,
} from '@fivehealth/botero'
import {
  faAngleDown,
  faPlusCircle,
  faSyncAlt,
} from '@fortawesome/pro-regular-svg-icons'
import AddEditChernobylEntryForm from 'old-code/components/ChernobylDataSource/AddEditChernobylEntryForm'
import ChernobylDataSourceTable from 'old-code/components/ChernobylDataSource/ChernobylDataSourceTable'
import DropdownMenu from 'old-code/components/DropdownMenu/DropdownMenu'
import { useModal } from 'old-code/context/ModalContext'
import useExportFile from 'old-code/customHooks/useExportFile'
import { chain } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import usePostHog from 'old-code/customHooks/usePostHog'
import chernobylModuleMap from 'old-code/views/ChernobylDataSource/ChernobylModuleMap'
import { useOrganizationsContext } from 'old-code/context/OrganizationsContext'
import ExportDropDown from '../../components/ExportDropDown/ExportDropDown'
import { tableConfigUtils } from '../../../Utils'
import EventsConstant from '../../config/constants/events.constants'

const DirectoryDashboard = () => {
  const { openModal, closeModal } = useModal()
  const { t } = useTranslation()
  const history = useHistory()
  const queryClient = useQueryClient()
  const [searchQuery, setSearchQuery] = useState('')
  const [moduleDataSource, setModuleDataSource] = useState(null)
  const [tableSettings, setTableSettings] = useState(null)
  const { isPublicHospital } = useOrganizationsContext()
  const exportFileUsingLink = useExportFile()

  const lastUpdated = useRef(new Date())
  const debouncedSearchQuery = useDebounce(searchQuery, 600)
  const [outputFormat, setOutputFormat] = useState('CSV')
  const [filters, setFilter] = useState({
    searchQuery: '',
  })
  const exportActionClicked = useRef(false)

  useEffect(() => {
    queryClient.invalidateQueries('einsteinAdministrator')
    queryClient.invalidateQueries('einsteinModules')
    queryClient.invalidateQueries('einsteinChernobylEntrys')
  }, [])

  const {
    queries: {
      useEinsteinAdministrator,
      useEinsteinModules,
      useEinsteinChernobylEntrys,
      useEinsteinChernobylExport,
    },
  } = useApi({
    queries: [
      'useEinsteinAdministrator',
      'useEinsteinModules',
      'useEinsteinChernobylEntrys',
      'useEinsteinChernobylExport',
    ],
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  const { isFetching: isModuleLoading } = useEinsteinModules({
    enabled: !!currentAdmin,
    variables: { visible: true },
    onSuccess: ({ data }) => {
      const moduleEdges = data.pages[0].einsteinModules.edges
      const directoryModule = moduleEdges.find(
        ({ node }) =>
          (((node.settings || {}).chernobyl || {}).key || {}) ===
          `${currentAdmin.hospital.organizationKey}-${chernobylModuleMap.hospital_directory.key}`
      )

      setModuleDataSource(directoryModule.node)
      setTableSettings(
        directoryModule.node.settings.data_source_entries_table_settings
      )
    },
  })

  useEffect(() => {
    if (debouncedSearchQuery.length >= 3) {
      setFilter({ searchQuery: debouncedSearchQuery })
    } else {
      setFilter({ searchQuery: '' })
    }
  }, [debouncedSearchQuery])

  const logPostHogEvent = usePostHog()
  useEffect(() => {
    logPostHogEvent('dashboard:directory_view')
  }, [logPostHogEvent])

  const {
    data: directoryData = null,
    isLoading: isEntriesLoading,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
    fetchNextPage,
    refetch: refetchDirectory,
  } = useEinsteinChernobylEntrys({
    enabled: !!moduleDataSource,
    variables: {
      ...filters,
      uid: (moduleDataSource || {}).uid || '',
    },
    onSuccess: () => {
      lastUpdated.current = new Date()
    },
  })

  const { data: directoryExportData = null } = useEinsteinChernobylExport({
    enabled: !!moduleDataSource,
    variables: {
      ...filters,
      uid: (moduleDataSource || {}).uid || '',
      outputFormat,
    },
    onSuccess: () => {
      lastUpdated.current = new Date()
    },
  })

  const directories = chain(directoryData)
    .get('pages', [])
    .flatMap((page) => page || [])
    .map((data) => {
      const rowsMapToColumns = tableSettings.columns.reduce((acc, value) => {
        acc[value.id] = data.variables[value.header] || data.variables[value.id]
        return acc
      }, {})

      return {
        uid: data.uid,
        ...rowsMapToColumns,
        misc: data,
      }
    })
    .value()

  const actions = [
    {
      id: 'addDirectory',
      testId: 'addDirectory',
      icon: faPlusCircle,
      label: 'Add contact',
      description: 'Add a new directory contact',
      onClick: () =>
        openModal(
          <AddEditChernobylEntryForm
            openModal={openModal}
            closeModal={closeModal}
            formHeader="Add contact"
            fieldTitle="User Information"
            currentChernobylModuleMap={chernobylModuleMap.hospital_directory}
            moduleDataSource={moduleDataSource}
            exportFileUsingLink={exportFileUsingLink}
          />,
          { overflow: 'scroll' }
        ),
      logEventProps: {
        subSource: EventsConstant.HOSPITAL_DIRECTORY_HEAD_SOURCE,
        eventName: EventsConstant.HOSPITAL_DIRECTORY_ADD_CONTACT_OPENED,
        page: EventsConstant.HOSPITAL_DIRECTORY_PAGE,
      },
    },
    {
      id: 'fullSync',
      testId: 'fullSync',
      icon: faSyncAlt,
      divider: 'true',
      label: 'Full Sync',
      description:
        'Update an Excel file that will override entire hospital directory',
      onClick: () => history.push('/full_sync/hospital_directory'),
      logEventProps: {
        subSource: EventsConstant.HOSPITAL_DIRECTORY_HEAD_SOURCE,
        eventName: EventsConstant.HOSPITAL_DIRECTORY_FULL_SYNC_OPENED,
        page: EventsConstant.HOSPITAL_DIRECTORY_PAGE,
      },
    },
  ]

  const columns = [
    ...tableConfigUtils.mapCellRenderToConfig(
      tableSettings,
      currentAdmin ? currentAdmin.hospital.organizationKey : '',
      t
    ),
  ]

  const canExport =
    directoryExportData &&
    directoryExportData.pages.length > 0 &&
    directoryExportData.pages[0].einsteinChernobylEntrys
  const handleExport = () => {
    if (canExport) {
      const link =
        directoryExportData.pages[0].einsteinChernobylEntrys.exportByUrl
      exportFileUsingLink(link, 'hospitalDirectory')
    }
  }
  useEffect(() => {
    if (canExport && exportActionClicked.current) {
      exportActionClicked.current = false
      handleExport()
    }
  }, [directoryExportData])

  return (
    <Box>
      {/* Title */}
      <Flex mt={2} p={2} justifyContent="space-between" alignItems="center">
        <H1>Hospital Directory</H1>

        <Flex alignItems="right" justifyContent="space-between">
          {!isPublicHospital && (
            <DropdownMenu
              moveLeft="-96px"
              fontWeight="500"
              label={
                <PrimaryButton
                  borderRadius="8px"
                  fontWeight="500"
                  data-testid="addContactsButton"
                  endIcon={<FAIcon icon={faAngleDown} color="lightShade" />}
                >
                  Add contacts
                </PrimaryButton>
              }
              actions={actions}
              width="300px"
            />
          )}
          {!isPublicHospital && (
            <ExportDropDown
              testId="exportDirectoryButton"
              label="Export directory"
              canExport={canExport}
              totalCount={directoryData ? directoryData.totalCount : 0}
              outputFormat={outputFormat}
              handleExport={handleExport}
              exportActionClicked={exportActionClicked}
              setOutputFormat={setOutputFormat}
              logEventPropsAll={{
                csvExport: {
                  subSource: EventsConstant.HOSPITAL_DIRECTORY_HEAD_SOURCE,
                  eventName: EventsConstant.HOSPITAL_DIRECTORY_EXPORT_CSV,
                  page: EventsConstant.HOSPITAL_DIRECTORY_PAGE,
                },
                xlxsExport: {
                  subSource: EventsConstant.HOSPITAL_DIRECTORY_HEAD_SOURCE,
                  eventName: EventsConstant.HOSPITAL_DIRECTORY_EXPORT_XLSX,
                  page: EventsConstant.HOSPITAL_DIRECTORY_PAGE,
                },
                templateExport: {
                  subSource: EventsConstant.HOSPITAL_DIRECTORY_HEAD_SOURCE,
                  eventName: EventsConstant.HOSPITAL_DIRECTORY_EXPORT_TEMPLATE,
                  page: EventsConstant.HOSPITAL_DIRECTORY_PAGE,
                },
              }}
            />
          )}
        </Flex>
      </Flex>
      <Text
        pl={2}
        pt={1}
        pb={3}
        fontSize="16px"
        fontWeight="400"
        letterSpacing="-0.08px"
        width="65%"
        style={{ lineHeight: '1.6' }}
      >
        View and manage your hospital directory contacts. Note that this
        directory is different from the User Management list and may also
        contain non-user numbers e.g. Pharmacy or Lab contacts.
      </Text>
      <ChernobylDataSourceTable
        testId="hospitalDirectoryTable"
        searchTestId="hospitalDirectorySearchInput"
        refreshButtonTestId="refreshHospitalDirectoryButton"
        columns={columns}
        data={directories}
        isLoading={isModuleLoading || isEntriesLoading}
        isFetchingNextPage={isFetchingNextPage}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        searchInputPlaceholder="Search by name, mobile number or email"
        searchQuery={searchQuery}
        onChangeSearchQuery={(newSearchQuery) => setSearchQuery(newSearchQuery)}
        refetch={refetchDirectory}
        lastUpdatedDate={lastUpdated.current}
        currentChernobylModuleMap={chernobylModuleMap.hospital_directory}
        moduleDataSource={moduleDataSource}
        allowModifications={!isPublicHospital}
        searchSideLabel="Total contacts"
        searchSideLabelValue={directoryData ? directoryData.totalCount : 0}
        logEventPropsAll={{
          selectVisible: {
            subSource: EventsConstant.HOSPITAL_DIRECTORY_HEAD_SOURCE,
            eventName: EventsConstant.HOSPITAL_DIRECTORY_SELECT_VISIBLE,
            page: EventsConstant.HOSPITAL_DIRECTORY_PAGE,
          },
          customSelect: {
            subSource: EventsConstant.HOSPITAL_DIRECTORY_HEAD_SOURCE,
            eventName: EventsConstant.HOSPITAL_DIRECTORY_CUSTOM_SELECTION,
            page: EventsConstant.HOSPITAL_DIRECTORY_PAGE,
          },
          viewRow: {
            subSource: EventsConstant.HOSPITAL_DIRECTORY_TABLE_SOURCE,
            eventName: EventsConstant.HOSPITAL_DIRECTORY_CONTACT_VIEWED,
            page: EventsConstant.HOSPITAL_DIRECTORY_PAGE,
          },
          editRow: {
            subSource: EventsConstant.HOSPITAL_DIRECTORY_TABLE_SOURCE,
            eventName: EventsConstant.HOSPITAL_DIRECTORY_CONTACT_EDIT_FORM,
            page: EventsConstant.HOSPITAL_DIRECTORY_PAGE,
          },
          deleteRow: {
            subSource: EventsConstant.HOSPITAL_DIRECTORY_TABLE_SOURCE,
            eventName: EventsConstant.HOSPITAL_DIRECTORY_CONTACT_DELETE,
            page: EventsConstant.HOSPITAL_DIRECTORY_PAGE,
          },
          bulkDelete: {
            subSource: EventsConstant.HOSPITAL_DIRECTORY_TABLE_SOURCE,
            eventName: EventsConstant.HOSPITAL_DIRECTORY_BULK_DELETE_ACTION,
            page: EventsConstant.HOSPITAL_DIRECTORY_PAGE,
          },
        }}
      />
    </Box>
  )
}

export default DirectoryDashboard
