import React, { useEffect } from 'react'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'

import {
  Flex,
  Text,
  Box,
  PrimaryButton,
  FAIcon,
  SecondaryOutlinedButton,
  useApi,
} from '@fivehealth/botero'

import { useNavStateContext } from '../../context/NavStateContext'
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu'
import { useModal } from '../../context/ModalContext'
import BotmdCryBaby from '../../assets/bot-crying-avatar.svg'
import { AlertModalConfirmation, handleUpdateConfirmationModal } from './helper'

const EinsteinAdminBulkUpdate = ({
  selectedFlatRows,
  toggleAllRowsSelected,
  onCancelRowEditMode,
  queryClient,
  toggleRowSelected,
  data,
  debouncedSearchQuery,
  setSelectedRowUids,
}) => {
  const {
    queries: { useEinsteinAdmin },
  } = useApi({
    queries: ['useEinsteinAdmin'],
  })

  useEffect(() => {
    data.forEach((admin) => {
      const isAdminSelected = _.find(
        selectedFlatRows,
        (selectedRow) => selectedRow.uid === admin.uid
      )
      if (isAdminSelected) {
        toggleRowSelected(admin.uid, true)
      } else {
        toggleRowSelected(admin.uid, false)
      }
    })
  }, [data, toggleRowSelected, selectedFlatRows, debouncedSearchQuery])

  const { expanded } = useNavStateContext()
  const { openModal, closeModal } = useModal()

  const { mutateAsync: updateAdminMutationBulk } =
    useEinsteinAdmin().bulkUpdate({
      onSuccess: ({ data: resData }) => {
        const modalProps =
          resData?.errors?.length > 0
            ? {
                title: resData?.errors[0]?.message.replace(/[`'[\]/]/gi, ''),
                description: resData?.errors[0]?.message.includes(
                  'Insufficient administrator licenses'
                )
                  ? null
                  : 'Please contact another Super Admin to perform this action.',
                botmdImage: BotmdCryBaby,
              }
            : {
                title: 'Admin profile bulk update was successfully.',
                description: 'Please check the updates the admin list',
              }
        queryClient.invalidateQueries('einsteinAdminList')
        queryClient.invalidateQueries('einsteinAdminActiveCount')
        onCancelRowEditMode()
        toggleAllRowsSelected(false)
        setSelectedRowUids([])
        openModal(
          <AlertModalConfirmation
            closeModal={() => {
              closeModal()
              queryClient.invalidateQueries('einsteinAdminList')
            }}
            {...modalProps}
          />
        )
      },
    })

  const getSelectedIds = selectedFlatRows.map((m) => m?.uid)
  const getAllRowForActivation = () =>
    selectedFlatRows
      ?.filter((o) => o?.deactivatedOn !== null)
      ?.map((m) => m?.uid)

  const getAllRowForDeactivation = () =>
    selectedFlatRows
      ?.filter((o) => o?.deactivatedOn === null)
      ?.map((m) => m?.uid)

  const bulkActions = [
    {
      id: 'admin_bulk_select_action_activate',
      label: `Activate ${getSelectedIds?.length || ''} User${
        getSelectedIds?.length <= 1 ? '' : 's'
      }`,
      isDisabled: true,
      color: getAllRowForActivation()?.length === 0 ? '#bebebe' : 'black',
      onClick: () => {
        if (getAllRowForActivation()?.length === 0) return
        const param = {
          input: {
            allUids: getSelectedIds,
            opType: 'ACTIVATE',
          },
        }

        handleUpdateConfirmationModal({
          openModal,
          closeModal,
          onUpdate: updateAdminMutationBulk,
          actionType: 'activate',
          updateInputParam: param,
        })
      },
    },
    {
      id: 'admin_bulk_select_action_deactivate',
      label: `Deactivate ${getSelectedIds?.length || ''} User${
        getSelectedIds?.length <= 1 ? '' : 's'
      }`,
      color: getAllRowForDeactivation()?.length === 0 ? '#bebebe' : 'red',
      divider: true,

      onClick: () => {
        if (getAllRowForDeactivation()?.length === 0) return

        const param = {
          input: {
            allUids: getSelectedIds,
            opType: 'DEACTIVATE',
          },
        }

        handleUpdateConfirmationModal({
          openModal,
          closeModal,
          onUpdate: updateAdminMutationBulk,
          actionType: 'deactivate',
          updateInputParam: param,
        })
      },
    },
  ]

  return (
    <Box
      position="fixed"
      zIndex="1"
      bg="#E9F0FE"
      p={2}
      borderRadius="8px"
      bottom="18px"
      right={`${expanded ? '60px' : '150px'}`}
      left={`${expanded ? '400px' : '200px'}`}
      boxShadow="0px 6px 12px #98a2b326, 0px 4px 4px #98a2b326, 0px 2px 2px #98a2b326"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <Text m={1} textAlign="center" color="primary" fontWeight="bold">
            {selectedFlatRows.length} user
            {selectedFlatRows.length > 1 ? 's' : ''} selected
          </Text>
          {selectedFlatRows.length > 0 && (
            <SecondaryOutlinedButton
              borderRadius="8px"
              bg="white"
              onClick={() => {
                toggleAllRowsSelected(false)
                setSelectedRowUids([])
              }}
            >
              Deselect
            </SecondaryOutlinedButton>
          )}
        </Flex>
        <Flex>
          <SecondaryOutlinedButton
            mr={2}
            borderRadius="8px"
            bg="white"
            onClick={() => {
              onCancelRowEditMode()
              toggleAllRowsSelected(false)
              setSelectedRowUids([])
            }}
          >
            Cancel
          </SecondaryOutlinedButton>
          <DropdownMenu
            fontWeight="normal"
            actions={bulkActions}
            label={
              <PrimaryButton
                borderRadius="8px"
                mr={2}
                endIcon={<FAIcon icon={faAngleDown} color="lightShade" />}
                disabled={Number(selectedFlatRows) === 0}
              >
                Bulk actions
              </PrimaryButton>
            }
          />
        </Flex>
      </Flex>
    </Box>
  )
}

export default EinsteinAdminBulkUpdate
