import React, { useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import {
  Flex,
  Text,
  Box,
  FAIcon,
  PrimaryButton,
  SecondaryOutlinedButton,
  useApi,
  sendLog,
} from '@fivehealth/botero'
import { useHistory } from 'react-router-dom'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import Input from '../../components/Input/Input'
import BotSelect from '../../components/BotSelect/BotSelect'
import AlertModal from '../../components/Modals/AlertModal'
import PermissionsField from '../../components/PermissionsField/PermissionsFieldFavourites'
import OutlinedBox from '../../components/Box/OutlinedBox'
import { constructAclDnfCollection } from '../../../Utils'
import FavouritesFormContentTypeFields from './FavouritesFormContentTypeFields'
import EventsConstant from '../../config/constants/events.constants'
import BotmdCryBaby from '../../assets/bot-crying-avatar.svg'
import { AlertModalConfirmation } from '../AdminSettings/helper'

import favoritesFormSettings from './FavouritesFormSettings'

const DEFAULT_CONTENT_TYPE_OPTION = {
  value: 'website_link',
  label: 'Website link',
}

const CONTENT_TYPE_OPTIONS = [
  {
    value: 'document',
    label: 'Document',
  },
  {
    value: 'custom_clinical_module',
    label: 'Custom clinical module',
  },
  { ...DEFAULT_CONTENT_TYPE_OPTION },
]

const FavouritesForm = ({
  favorite = null,
  openModal,
  closeModal,
  document = null,
  fromFileManager = false,
}) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const [selectedContentType, setSelectedContentType] = useState(
    DEFAULT_CONTENT_TYPE_OPTION
  )
  const [formData, setFormData] = useState({})
  const [formErrorMessage, setFormErrorMessage] = useState()
  const [selectedDepartmentForm, setSelectedDepartmentForm] = useState([])
  const [selectedDesignationForm, setSelectedDesignationForm] = useState([])

  const {
    queries: { useEinsteinAdministrator, useHospitalFavorites },
  } = useApi({
    queries: ['useEinsteinAdministrator', 'useHospitalFavorites'],
  })

  const { data: currentAdmin } = useEinsteinAdministrator()
  const { data: hospitalFavorites } = useHospitalFavorites()

  const getACL = () => {
    const filteredDepartment = selectedDepartmentForm.filter(
      (ele) => ele.uid !== 'allDepartment'
    )
    const filteredDesignation = selectedDesignationForm.filter(
      (ele) => ele.uid !== 'allDesignation'
    )
    const aclDnfCollection = constructAclDnfCollection(
      filteredDepartment,
      filteredDesignation
    )
    const orgKey = currentAdmin.hospital.organizationKey

    if (aclDnfCollection.length) {
      const aclDnfString = aclDnfCollection.map(
        (aclDnfRow) =>
          `hospital:hospital=${orgKey}^${
            Array.isArray(aclDnfRow) ? aclDnfRow.join('^') : aclDnfRow
          }`
      )

      return aclDnfString
    }
    return [`hospital:hospital=${orgKey}`]
  }

  const fetchedHospitalFavorites = (
    ((hospitalFavorites || {}).hospitalFavourites || {}).edges || []
  ).map(({ node }) => node)

  useEffect(() => {
    if (document) {
      setSelectedContentType({
        value: 'document',
        label: 'Document',
      })
      setFormData({
        url: document.misc.originalFileStitchUrl || document.misc.webUrl,
        subtitle: document.misc.title,
      })
    }
  }, [document])

  useEffect(() => {
    if (favorite) {
      const defaultContentType =
        CONTENT_TYPE_OPTIONS.find(
          (contentTypeOption) =>
            contentTypeOption.value === favorite.contentType
        ) || DEFAULT_CONTENT_TYPE_OPTION
      setSelectedContentType(defaultContentType)

      setFormData({
        title: favorite.title || '',
        subtitle: favorite.subtitle || '',
        url: favorite.url || '',
        aclDnf: favorite.aclDnf || [],
      })
    }
  }, [favorite])

  const {
    queries: { useCreateHospitalFavorite, useUpdateHospitalFavorite },
  } = useApi({
    queries: ['useCreateHospitalFavorite', 'useUpdateHospitalFavorite'],
  })

  const { mutateAsync: createFavorite } = useCreateHospitalFavorite({
    variables: {},
    onSuccess: ({ data }) => {
      if (data?.hospitalFavouriteCreate) {
        sendLog(
          {
            subSource: EventsConstant.FAVORITE_FORM_SOURCE,
            page: EventsConstant.FAVORITE_PAGE,
            metaData: JSON.stringify(data.hospitalFavouriteCreate),
          },
          EventsConstant.FAVORITE_CREATED_SUCCESSFULLY
        )
        openModal(
          <AlertModal
            description="You have successfully created new Favorite"
            handleClose={closeModal}
            inModal={!fromFileManager}
            primaryButton={{
              text: 'View Favorites',
              onClick: () => {
                history.push('/favorites')
                closeModal()
              },
            }}
            secondaryButton={{
              text: 'Close',
              onClick: () => closeModal(),
            }}
          />
        )
      }
      if (data?.errors && data?.errors.length) {
        sendLog(
          {
            subSource: EventsConstant.FAVORITE_FORM_SOURCE,
            page: EventsConstant.FAVORITE_PAGE,
            metaData: JSON.stringify(data.errors || []),
          },
          EventsConstant.FAVORITE_CREATE_ERROR
        )
        const modalProps = {
          title: 'Could not Create Favourite',
          description: data?.errors[0]?.message.includes(
            'Favourite with this Hospital and Key already exists.'
          )
            ? 'Favorite for the same url already exists. Try changing the URL'
            : data?.errors[0]?.message.replace(/[`'[\]/]/gi, ''),
          botmdImage: BotmdCryBaby,
        }
        openModal(
          <AlertModalConfirmation
            closeModal={() => {
              closeModal()
              queryClient.invalidateQueries('einsteinAdminGetByUid')
            }}
            {...modalProps}
          />
        )
      }

      queryClient.invalidateQueries('einsteinAdministrator')
      queryClient.invalidateQueries('hospitalFavorites')
    },
  })

  const { mutateAsync: updateFavorite } = useUpdateHospitalFavorite({
    variables: {},
    onSuccess: ({ data }) => {
      if (data?.hospitalFavouriteUpdate) {
        sendLog(
          {
            subSource: EventsConstant.FAVORITE_FORM_SOURCE,
            page: EventsConstant.FAVORITE_PAGE,
            metaData: JSON.stringify(data.hospitalFavouriteUpdate),
          },
          EventsConstant.FAVORITE_UPDATED_SUCCESSFULLY
        )
        openModal(
          <AlertModal
            description="Successfully updated Favorite"
            handleClose={closeModal}
            primaryButton={{
              text: 'Back to Favorites',
              onClick: () => {
                closeModal()
              },
            }}
            inModal
          />
        )
      }
      if (data?.errors && data?.errors.length) {
        sendLog(
          {
            subSource: EventsConstant.FAVORITE_FORM_SOURCE,
            page: EventsConstant.FAVORITE_PAGE,
            metaData: JSON.stringify(data.errors || []),
          },
          EventsConstant.FAVORITE_UPDATE_ERROR
        )
        const modalProps = {
          title: 'Could not update Favourite',
          description: data?.errors[0]?.message.replace(/[`'[\]/]/gi, ''),
          botmdImage: BotmdCryBaby,
        }
        openModal(
          <AlertModalConfirmation
            closeModal={() => {
              closeModal()
              queryClient.invalidateQueries('einsteinAdminGetByUid')
            }}
            {...modalProps}
          />
        )
      }

      queryClient.invalidateQueries('einsteinAdministrator')
      queryClient.invalidateQueries('hospitalFavorites')
    },
  })

  const handleOnChangeFormData = (value, formKey) => {
    const newFormData = { ...formData }
    const newFormErrorMessage = { ...formErrorMessage }
    delete newFormErrorMessage[formKey]

    if (formKey === 'selectedDoc') {
      newFormData.url = value.value
      newFormData.subtitle = value.label
    } else {
      newFormData[formKey] = value
    }

    setFormData(newFormData)
    setFormErrorMessage(newFormErrorMessage)
  }

  const handleSaveFavorite = () => {
    setFormErrorMessage({})

    // validate
    const requiredFields = Object.keys(favoritesFormSettings).reduce(
      (acc, value) => {
        const isRequired =
          ((favoritesFormSettings[value] || {}).scope || {}).required || false

        if (isRequired) {
          acc.push(value)
        }

        return acc
      },
      []
    )

    const mappedRequiredErrorMessage = requiredFields.reduce((acc, value) => {
      if (!formData[value] || !formData[value].length) {
        acc[value] = `${favoritesFormSettings[value].label} is required`
      }

      return acc
    }, {})

    const input = {
      key: (formData.title || '').replaceAll(' ', '_'),
      title: formData.title,
      subtitle: formData.subtitle,
      contentType: selectedContentType.value,
      url: formData.url,
      aclDnf: getACL(),
    }

    const existingFavoriteUrl = fetchedHospitalFavorites.find(
      (fetchedHospitalFavorite) =>
        input.url === fetchedHospitalFavorite.url &&
        fetchedHospitalFavorite.contentType === 'custom_clinical_module'
    )

    if (existingFavoriteUrl) {
      let moduleInUse = true
      if (favorite && favorite.url === existingFavoriteUrl.url) {
        moduleInUse = false
      }

      if (moduleInUse) {
        mappedRequiredErrorMessage.module =
          'Module already in used. Please select other module'
      }
    }

    if (selectedContentType.value === 'website_link' && !formData.url) {
      mappedRequiredErrorMessage.websiteLink = 'Website Link is required'
    }

    const existingKeyTitle = fetchedHospitalFavorites.find(
      (fetchedHospitalFavorite) => input.key === fetchedHospitalFavorite.key
    )

    if (existingKeyTitle) {
      let titleInUse = true
      if (favorite && favorite.key === existingKeyTitle.key) {
        titleInUse = false
      }

      if (titleInUse) {
        mappedRequiredErrorMessage.title = 'Title already in used'
      }
    }

    if (Object.keys(mappedRequiredErrorMessage).length) {
      setFormErrorMessage(mappedRequiredErrorMessage)
      return
    }

    if (!favorite) {
      createFavorite({
        input,
      })
    } else {
      updateFavorite({
        input: {
          ...input,
          uid: favorite.uid,
        },
      })
    }
  }

  const maxHeight = (window.screen.height * 90) / 100

  return (
    <OutlinedBox style={{ maxHeight: `${maxHeight}px`, overflowY: 'auto' }}>
      <Box m={4} width="580px">
        {/* Title */}
        <Flex justifyContent="space-between" alignItems="center">
          <Flex>
            <Box>
              <Text fontSize="24px" fontWeight="600" mb={2}>
                {!favorite ? 'Add' : 'Edit'} Favorite
              </Text>
              {(formErrorMessage || {}).main && (
                <Text color="danger" fontSize={12} mt={1}>
                  {formErrorMessage.main}
                </Text>
              )}
            </Box>
          </Flex>
          <Flex cursor="pointer" alignItems="center" justifyContent="right">
            <FAIcon
              icon={faTimes}
              hover={{ opacity: 0.6 }}
              onClick={() => closeModal()}
            />
          </Flex>
        </Flex>

        {/* Form */}
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexDirection="column"
          mt={3}
          mb={8}
        >
          <Box width="100%">
            <Input
              label={favoritesFormSettings.title.label}
              fontSize="14px"
              placeholder={favoritesFormSettings.title.placeholder}
              inputLimit={favoritesFormSettings.title.scope.inputLimit}
              value={formData.title || ''}
              onChange={(e) => handleOnChangeFormData(e.target.value, 'title')}
              tooltipText={favoritesFormSettings.title.tooltipText}
              labelRight={
                favoritesFormSettings.title.scope.required
                  ? undefined
                  : '(Optional)'
              }
              error={!!(formErrorMessage || {}).title}
              errorLabel={(formErrorMessage || {}).title}
            />
          </Box>
          <Box width="100%">
            <Input
              label={favoritesFormSettings.subtitle.label}
              fontSize="14px"
              placeholder={favoritesFormSettings.subtitle.placeholder}
              inputLimit={90}
              disabled={selectedContentType.value === 'document'}
              tooltipText={favoritesFormSettings.subtitle.tooltipText}
              labelRight={
                favoritesFormSettings.subtitle.scope.required
                  ? undefined
                  : '(Optional)'
              }
              value={formData.subtitle || ''}
              onChange={(e) =>
                handleOnChangeFormData(e.target.value, 'subtitle')
              }
            />
          </Box>
          <Box width="100%" mb={3} mt={2}>
            <Text fontSize="14px" fontWeight="bold" color="darkestShade" mb={1}>
              Owner
            </Text>
            <BotSelect
              isMulti={false}
              isClearable={false}
              options={[
                favorite?.owner ||
                  'Will be set based on the department of the admin',
              ]}
              onChange={(selected) => setSelectedContentType(selected)}
              value={{
                value:
                  favorite?.owner ||
                  'Will be set based on the department of the admin',
                label:
                  favorite?.owner ||
                  'Will be set based on the department of the admin',
              }}
              isDisabled
            />
          </Box>
          <Box width="100%" mb={3} mt={2}>
            <Text fontSize="14px" fontWeight="bold" color="darkestShade" mb={1}>
              Content type
            </Text>
            <BotSelect
              isMulti={false}
              isClearable={false}
              options={CONTENT_TYPE_OPTIONS}
              onChange={(selected) => setSelectedContentType(selected)}
              value={selectedContentType}
              isDisabled={!!document}
            />
          </Box>
          <FavouritesFormContentTypeFields
            contentTypeValue={
              selectedContentType ? selectedContentType.value : ''
            }
            isPrefillMode={!!(document || favorite)}
            onChange={handleOnChangeFormData}
            formData={formData}
            formErrorMessage={formErrorMessage}
            disabled={!!document}
          />
          <PermissionsField
            onChange={handleOnChangeFormData}
            aclDnf={formData.aclDnf}
            orgKey={currentAdmin.hospital.organizationKey}
            setSelectedDepartmentForm={setSelectedDepartmentForm}
            setSelectedDesignationForm={setSelectedDesignationForm}
          />
        </Flex>

        {/* Button */}
        <Flex alignItems="center" justifyContent="right" mt={3}>
          <SecondaryOutlinedButton
            borderRadius="8px"
            mr={3}
            onClick={() => closeModal()}
          >
            Cancel
          </SecondaryOutlinedButton>
          <PrimaryButton borderRadius="8px" onClick={handleSaveFavorite}>
            Save
          </PrimaryButton>
        </Flex>
      </Box>
    </OutlinedBox>
  )
}

export default FavouritesForm
