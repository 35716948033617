import React from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Flex,
  Text,
  PrimaryButton,
  SecondaryOutlinedButton,
  FAIcon,
} from '@fivehealth/botero'
import { format } from 'date-fns'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { capitalize } from 'lodash'

import BotCryingAvatar from '../../../assets/bot-crying-avatar.svg'
import BotSearchAvatar from '../../../assets/bot-search-avatar.svg'
import BotCompleteAvatar from '../../../assets/bot-complete-avatar.svg'
import ProgressBar from '../../../components/ProgressBar/ProgressBar'

const ChernobylDataSourceUploadLoading = ({
  action,
  showDashboard,
  closeModal,
  totalUpdate = 0,
  chernobylModuleMap,
  errorMessages = [],
}) => {
  const handleClose = () => {
    closeModal()
    showDashboard()
  }

  const renderError = () => (
    <Box p={4}>
      <Flex justifyContent="center" alignItems="center" m={2}>
        <Box as="img" src={BotCryingAvatar} />
      </Flex>
      <Text fontWeight="bold" m={2} textAlign="center">
        Oh no, no {chernobylModuleMap.fullLabel.toLocaleLowerCase()} found!
      </Text>
      <Text textAlign="center" m={3}>
        Please ensure that your sheet is formatted correctly. You can download
        our template to ensure that the format is correct.
      </Text>
      <Flex alignItems="center" justifyContent="center">
        <SecondaryOutlinedButton
          borderRadius="8px"
          m={1}
          mr={2}
          fontSize={14}
          fontWeight={600}
          style={{ padding: '11px 15px', color: '#697481' }}
          onClick={handleClose}
        >
          Back to {chernobylModuleMap.fullLabel}
        </SecondaryOutlinedButton>
        <Link
          style={{ textDecoration: 'none' }}
          to={chernobylModuleMap.templatePath}
          target="_blank"
          download
        >
          <PrimaryButton borderRadius="8px">Download Template</PrimaryButton>
        </Link>
      </Flex>
    </Box>
  )

  const renderPending = () => (
    <Box p={4}>
      <Flex justifyContent="center" alignItems="center" m={2}>
        <Box as="img" src={BotSearchAvatar} />
      </Flex>
      <Text textAlign="center" m={3}>
        Uploading {chernobylModuleMap.fullLabel}
      </Text>
      <ProgressBar ml={10} mr={10} />
    </Box>
  )

  const renderSuccess = () => (
    <Box p={2}>
      <Flex
        cursor="pointer"
        alignItems="center"
        justifyContent="flex-end"
        mr={2}
        mt={2}
      >
        <FAIcon
          icon={faTimes}
          hover={{ opacity: 0.6 }}
          onClick={() => handleClose()}
        />
      </Flex>
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <Flex justifyContent="center" alignItems="center" m={2}>
          <Box as="img" src={BotCompleteAvatar} />
        </Flex>
        <Text textAlign="center" m={3} fontSize={3} fontWeight="600">
          {chernobylModuleMap.syncCompleteLabel} sync complete
        </Text>
        <Text fontSize={2} mb={1}>
          {/* {totalUpdate}{' '} */}
          {totalUpdate > 1
            ? chernobylModuleMap.shortLabelPlural
            : chernobylModuleMap.shortLabel}{' '}
          updated successfully
        </Text>
        <Text color="darkestShade" mb={2}>
          {format(new Date(), 'dd LLL Y, h:mm bbb')}
        </Text>

        {errorMessages.length > 0 && (
          <Box mt={1} mb={2}>
            <Text fontSize={2} mb={1} textAlign="center">
              {errorMessages.length}{' '}
              {errorMessages > 1
                ? capitalize(chernobylModuleMap.shortLabelPlural)
                : capitalize(chernobylModuleMap.shortLabel)}{' '}
              failed to update:
            </Text>
            <ul>
              {errorMessages.map((errorMessage, index) => (
                <li key={`${index}-error`}>
                  <Text fontSize={2} mb="4px" color="danger">
                    {errorMessage}
                  </Text>
                </li>
              ))}
            </ul>
          </Box>
        )}

        <PrimaryButton
          borderRadius="8px"
          fontSize="14px"
          onClick={handleClose}
          style={{ padding: '14px 20px' }}
          mb={2}
        >
          View {chernobylModuleMap.syncCompleteViewLabel}
        </PrimaryButton>
      </Flex>
    </Box>
  )

  switch (action) {
    case 'error':
      return renderError()
    case 'success':
      return renderSuccess()
    default:
      return renderPending()
  }
}

export default ChernobylDataSourceUploadLoading
