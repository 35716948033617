import React, { useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  H1,
  Box,
  useApi,
  Flex,
  Text,
  PrimaryButton,
  FAIcon,
  useDebounce,
} from '@fivehealth/botero'
import { chain, isEmpty } from 'lodash'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'
import ChernobylDataSourceTable from 'old-code/components/ChernobylDataSource/ChernobylDataSourceTable'
import DropdownMenu from 'old-code/components/DropdownMenu/DropdownMenu'
import { useModal } from 'old-code/context/ModalContext'
import useExportFile from 'old-code/customHooks/useExportFile'

import { tableConfigUtils } from '../../../Utils'
import { getActions, preProcessFunctions } from './CustomModuleUtils'
import ExportDropDown from '../../components/ExportDropDown/ExportDropDown'

const CustomModuleDashboard = ({ moduleData }) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const [tableSettings, setTableSettings] = useState(null)
  const [moduleDataSource, setModuleDataSource] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [filters, setFilter] = useState({
    searchQuery: '',
  })
  const { openModal, closeModal } = useModal()
  const [moduleEdgesData, setModuleEdgesData] = useState(null)
  const [outputFormat, setOutputFormat] = useState('CSV')
  const { t } = useTranslation()
  const debouncedSearchQuery = useDebounce(searchQuery, 600)

  const { chernobylModuleMap } = moduleData
  useEffect(() => {
    localStorage.setItem(
      'currentCustomModuleChernobylMap',
      JSON.stringify(chernobylModuleMap)
    )
  }, [chernobylModuleMap])

  const lastUpdated = useRef(new Date())

  useEffect(() => {
    queryClient.invalidateQueries('einsteinAdministrator')
    queryClient.invalidateQueries('einsteinModules')
  }, [])

  useEffect(() => {
    if (debouncedSearchQuery.length >= 3) {
      setFilter({ searchQuery: debouncedSearchQuery })
    } else {
      setFilter({ searchQuery: '' })
    }
  }, [debouncedSearchQuery])

  const {
    queries: {
      useEinsteinAdministrator,
      useEinsteinModules,
      useEinsteinChernobylEntrys,
      useEinsteinChernobylExport,
    },
  } = useApi({
    queries: [
      'useEinsteinAdministrator',
      'useEinsteinModules',
      'useEinsteinChernobylEntrys',
      'useEinsteinChernobylExport',
    ],
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  const { isFetching: isModuleLoading } = useEinsteinModules({
    enabled: !!currentAdmin,
    variables: { visible: true },
    onSuccess: ({ data }) => {
      const moduleEdges = data.pages[0].einsteinModules.edges
      setModuleEdgesData(moduleEdges)
    },
  })

  const { data: moduleExportData = null } = useEinsteinChernobylExport({
    enabled: !!moduleDataSource,
    variables: {
      ...filters,
      uid: (moduleDataSource || {}).uid || '',
      outputFormat,
    },
    onSuccess: () => {
      lastUpdated.current = new Date()
    },
  })

  const exportFileUsingLink = useExportFile()

  const canExport =
    moduleExportData &&
    moduleExportData.pages.length > 0 &&
    moduleExportData.pages[0].einsteinChernobylEntrys
  const handleExport = () => {
    if (canExport) {
      const link = moduleExportData.pages[0].einsteinChernobylEntrys.exportByUrl
      exportFileUsingLink(link, chernobylModuleMap.key)
    }
  }

  const exportActionClicked = useRef(false)
  useEffect(() => {
    if (canExport && exportActionClicked.current) {
      exportActionClicked.current = false
      handleExport()
    }
  }, [moduleExportData])

  const {
    data: moduleTableData = null,
    isLoading: isEntriesLoading,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
    fetchNextPage,
    refetch: refetchDirectory,
  } = useEinsteinChernobylEntrys({
    enabled: !!moduleDataSource,
    variables: {
      ...filters,
      uid: (moduleDataSource || {}).uid || '',
    },
    onSuccess: () => {
      lastUpdated.current = new Date()
    },
  })

  const modulesEntries = chain(moduleTableData)
    .get('pages', [])
    .flatMap((page) => page || [])
    .map((data) => {
      if (!isEmpty(data.variables)) {
        const rowsMapToColumns = tableSettings.columns.reduce((acc, value) => {
          acc[value.id] =
            data.variables[value.header] || data.variables[value.id]
          return acc
        }, {})

        return {
          uid: data.uid,
          ...rowsMapToColumns,
          misc: data,
        }
      }
      return null
    })
    .filter((e) => !isEmpty(e))
    .value()

  const getpreProcessFunctions = preProcessFunctions(chernobylModuleMap?.key)

  const columns = [
    ...tableConfigUtils.mapCellRenderToConfig(
      getpreProcessFunctions?.preProcessTableData
        ? getpreProcessFunctions?.preProcessTableData(tableSettings)
        : tableSettings,
      currentAdmin ? currentAdmin.hospital.organizationKey : '',
      t
    ),
  ]

  const customModuleData = moduleDataSource?.settings?.custom_modules

  useEffect(() => {
    if (moduleEdgesData) {
      const moduleEdgesDataSorted = moduleEdgesData.find(
        ({ node }) =>
          (((node.settings || {}).chernobyl || {}).key || {}) ===
          `${currentAdmin.hospital.organizationKey}-${chernobylModuleMap.key}`
      )
      setModuleDataSource(moduleEdgesDataSorted.node)
      setTableSettings(
        moduleEdgesDataSorted.node.settings.data_source_entries_table_settings
      )
    }
  }, [moduleEdgesData, customModuleData])

  if (!customModuleData) {
    return null
  }

  const actions = getActions({
    openModal,
    closeModal,
    moduleDataSource,
    history,
    chernobylModuleMap,
    moduleActions: customModuleData?.primaryCTA?.actions,
    currentAdmin,
    saveModalTitle: customModuleData?.saveModalTitle,
    exportFileUsingLink,
  })

  return (
    <Box m={4} data-testid={moduleData.moduleId}>
      {/* Title */}
      <Flex mt={2} p={2} justifyContent="space-between" alignItems="center">
        <H1>{customModuleData?.title}</H1>

        <Flex alignItems="right" justifyContent="space-between">
          {customModuleData?.primaryCTA ? (
            <DropdownMenu
              moveLeft="-96px"
              fontWeight="500"
              label={
                <PrimaryButton
                  borderRadius="8px"
                  fontWeight="500"
                  data-testid="addContactsButton"
                  endIcon={<FAIcon icon={faAngleDown} color="lightShade" />}
                >
                  {customModuleData?.primaryCTA.label}
                </PrimaryButton>
              }
              actions={actions}
              width="300px"
            />
          ) : null}
          <ExportDropDown
            testId="exportDirectoryButton"
            label="Export Database"
            canExport={canExport}
            totalCount={moduleTableData ? moduleTableData.totalCount : 0}
            outputFormat={outputFormat}
            handleExport={handleExport}
            exportActionClicked={exportActionClicked}
            setOutputFormat={setOutputFormat}
          />
        </Flex>
      </Flex>
      <Text
        pl={2}
        pb={4}
        fontSize="16px"
        fontWeight="400"
        letterSpacing="-0.08px"
        width="65%"
        style={{ lineHeight: '1.6' }}
      >
        {customModuleData?.description}
      </Text>
      <ChernobylDataSourceTable
        testId={customModuleData?.dataTable?.testId}
        searchTestId={customModuleData?.dataTable?.searchTestId}
        refreshButtonTestId={customModuleData?.dataTable?.refreshButtonTestId}
        columns={columns}
        data={modulesEntries}
        isLoading={isModuleLoading || isEntriesLoading}
        isFetchingNextPage={isFetchingNextPage}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        searchInputPlaceholder={
          customModuleData?.dataTable?.searchInputPlaceholder
        }
        hasStickyColumn
        searchQuery={searchQuery}
        onChangeSearchQuery={(newSearchQuery) => setSearchQuery(newSearchQuery)}
        refetch={refetchDirectory}
        lastUpdatedDate={lastUpdated.current}
        currentChernobylModuleMap={chernobylModuleMap}
        moduleDataSource={moduleDataSource}
        allowModifications
        searchSideLabelValue={moduleTableData ? moduleTableData.totalCount : 0}
        preProcessFunctions={getpreProcessFunctions}
        quickEditEnabled={customModuleData?.quickEditEnabled}
        actionOnLeft
        editLabel={customModuleData?.dataTable?.editLabel}
        quickEditLabel={customModuleData?.dataTable?.quickEditLabel}
        deleteLabel={customModuleData?.dataTable?.deleteLabel}
        quickEditFormTitle={customModuleData?.dataTable?.quickEditFormTitle}
        editFormTitle={customModuleData?.dataTable?.editFormTitle}
        saveModalTitle={customModuleData?.saveModalTitle}
      />
    </Box>
  )
}

export default CustomModuleDashboard
