import React from 'react'
import _ from 'lodash'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'
import { useHistory } from 'react-router-dom'

import {
  ActionMenu,
  ActionMenuItem,
  Flex,
  Box,
  FAIcon,
  Text,
  useAuth,
  useApi,
} from '@fivehealth/botero'

import Logo from '../../assets/avatar-badge.svg'
import { useEinsteinAdministratorProfile } from '../../context/EinsteinAdministratorContext'
import EventsConstant from '../../config/constants/events.constants'

const Navbar = () => {
  const { logout } = useAuth()
  const history = useHistory()

  const einsteinAdmin = _.get(
    useEinsteinAdministratorProfile(),
    'einsteinAdmin',
    {}
  )

  const {
    queries: { useHeimdallLogout },
  } = useApi({
    queries: ['useHeimdallLogout'],
  })

  const { mutateAsync: logoutMutateAsync } = useHeimdallLogout()

  const handleLogout = () => {
    logoutMutateAsync({ input: {} })
    logout()
  }

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box p={2} width={1 / 2}>
        <Text
          data-testid="hospital-name"
          cursor="pointer"
          fontSize="16px"
          fontWeight="600"
          onClick={() => {
            history.push('/overview')
          }}
        >
          {_.isEmpty(einsteinAdmin) ? '' : einsteinAdmin.hospital.name}
        </Text>
      </Box>
      <Box p={2} width={1 / 2}>
        <Flex alignItems="center" justifyContent="right">
          <Text data-testid="user-name" fontSize="14px" fontWeight="600">
            {_.isEmpty(einsteinAdmin) ? '' : einsteinAdmin.fullName}
          </Text>
          <Box
            as="img"
            pl={1}
            pr={1}
            height={40}
            width={40}
            src={Logo}
            data-testid="avatar"
            onClick={() => {
              history.push('/overview')
            }}
          />
          <ActionMenu
            label={
              <FAIcon
                icon={faAngleDown}
                data-testid="user-menu"
                style={{ cursor: 'pointer' }}
              />
            }
          >
            <ActionMenuItem onClick={handleLogout} data-testid="logout-button">
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                logEventProps={{
                  subSource: EventsConstant.NAV_BAR,
                  eventName: EventsConstant.LOGOUT,
                  page: EventsConstant.EINSTEIN_DASHBOARD,
                }}
                onClick={() => {}}
              >
                <Text fontSize={14} color="fullShade">
                  Logout
                </Text>
              </Flex>
            </ActionMenuItem>
          </ActionMenu>
        </Flex>
      </Box>
    </Flex>
  )
}

export default Navbar
