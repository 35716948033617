import {
  Box,
  FAIcon,
  Flex,
  PrimaryButton,
  SecondaryOutlinedButton,
  Text,
  useApi,
  H5,
  sendLog,
} from '@fivehealth/botero'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import BotWidget from 'old-code/components/BotWidget/BotWidget'
import Chip from 'old-code/components/Chip/Chip'
import Form from 'old-code/components/Form/Form'
import { useModal } from 'old-code/context/ModalContext'
import _, { compact, isEmpty, uniqBy, get, map, isEqual } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { generateRandomId } from 'Utils'
import { useEinsteinAdministratorProfile } from 'old-code/context/EinsteinAdministratorContext'
import BroadcastMessageTemplate from '../../BroadcastManager/components/BroadcastMessageTemplate'
import DialogueTriggers from './DialogueTriggers'
import { mapTemplateMessage } from './helper'
import QuickOptions from './QuickOptions'
import SuccessDialog from '../../../components/SuccessDialog/SuccessDialog'
import ErrorDialog from '../../../components/ErrorDialog/ErrorDialog'
import { getCarouselTemplateData } from '../../BroadcastManager/components/Utils'
import EventsConstant from '../../../config/constants/events.constants'

const MAX_TEMPLATE_COUNT = 3

const BackButton = styled(Flex)`
  &:hover {
    opacity: 0.8;
  }
`

const CirclularChip = ({ label }) => (
  <Chip
    label={label}
    color="primary"
    bg="#BEDBFD50"
    borderRadius="50%"
    fontSize={12}
    fontWeight="bold"
    width="28px"
    height="28px"
    style={{
      boxSizing: 'content-box',
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  />
)

const DialogueCreate = () => {
  const formRef = useRef()
  const quickOptionRef = useRef()
  const templateRef = useRef([])
  const [templates, setTemplates] = useState([{ tmpId: generateRandomId() }])
  const [uidIndexMap, setUidIndexMap] = useState({})
  const [selectedTags, setSelectedTags] = useState([])
  const [renderPreviewPane, setRenderPreviewPane] = useState(false)
  const [triggersError, setTriggersError] = useState(false)
  const { openModal, closeModal } = useModal()
  const updatedTemplates = useRef([{}])
  const [open, setOpen] = useState(true)
  const history = useHistory()
  const location = useLocation()
  const defaultData = location.state ? location.state.defaultData : null
  const [selectedQuickOptions, setSelectedQuickOptions] = useState([])
  const selectedQuickOptionsRef = useRef([])
  const einsteinAdmin = get(
    useEinsteinAdministratorProfile(),
    'einsteinAdmin',
    {}
  )
  const [botResponse, setBotResponse] = useState(null)

  const shouldUpdate = (data) =>
    data && !(Object.keys(data).length === 1 && 'isUserGenerated' in data)

  const toggleOpen = () => {
    const templateData = _.map(templateRef.current, (template) =>
      template.getRawFormData()
    )
    setTemplates([...templateData])

    setOpen(!open)
  }

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.closeWidget) {
        toggleOpen()
      }
      if (event.data && event.data.listener) {
        const previewMode = { previewMode: true }
        setTimeout(() => {
          window?.frames?.[0]?.postMessage(previewMode, '*')
        }, 600)
        setTimeout(() => {
          setRenderPreviewPane(true)
        }, 300)
      }
    })
  }, [])

  const areAllMessagesOfType = (messages, type) =>
    messages.every((message) => message.message.type === type)

  const getTemplateDataFromMessages = (messages) => {
    const savedTemplates = []
    if (areAllMessagesOfType(messages, 'quick_replies')) {
      const templateData = _.map(templateRef.current, () => ({
        tmpId: generateRandomId(32),
        button: null,
        selected: 'text',
        switch: false,
        template: {
          message: 'This is a System Generated Message',
        },
      }))
      templateData.selected = 'text'
      savedTemplates.push(templateData[0])
    }
    if (!_.isEmpty(messages)) {
      messages.map((item) => {
        if (!item) {
          return false
        }
        const { message } = item
        if (message.type === 'quick_replies') {
          const quickOptions = message.quick_replies.map((option) => ({
            tmpId: generateRandomId(32),
            label: option.quick_reply.text,
            value: option.quick_reply.text,
          }))
          setSelectedQuickOptions(quickOptions)
          selectedQuickOptionsRef.current = quickOptions
        } else if (message.type === 'paragraphs') {
          const templateData = _.map(templateRef.current, () => ({
            tmpId: generateRandomId(32),
            button: null,
            selected: 'text',
            switch: false,
            template: {
              title: message.paragraphs[0].title,
              message: message.paragraphs[1].text,
            },
          }))
          templateData.selected = 'text'
          savedTemplates.push(templateData[0])
        } else if (message.type === 'sections') {
          const templateData = _.map(templateRef.current, () => ({
            tmpId: generateRandomId(32),
            button: {
              buttonText:
                message.sections.length > 1
                  ? message.sections[1].actions[0].button.text
                  : '',
              websiteURL:
                message.sections.length > 1
                  ? message.sections[1].actions[0].button.url
                  : '',
            },
            selected: 'text',
            switch: true,
            template: {
              title:
                message.sections[0].paragraphs.length > 1
                  ? message.sections[0].paragraphs[0].title
                  : '',
              message:
                message.sections[0].paragraphs.length > 1
                  ? message.sections[0].paragraphs[1].text
                  : message.sections[0].paragraphs[0].text,
            },
          }))
          templateData.selected = 'text'
          savedTemplates.push(templateData[0])
        } else if (message.type === 'image') {
          const templateData = _.map(templateRef.current, () => ({
            tmpId: generateRandomId(32),
            button: null,
            switch: false,
            selected: 'image',
            url_upload: message.image.url,
            image_source: 'websiteLink',
            template: {
              url_upload: message.image.url,
              image_source: 'websiteLink',
            },
          }))
          templateData.selected = 'image'
          savedTemplates.push(templateData[0])
        } else if (message.type === 'text') {
          const templateData = _.map(templateRef.current, () => ({
            tmpId: generateRandomId(32),
            button: null,
            selected: 'text',
            switch: false,
            template: {
              message: message.text,
            },
          }))
          templateData.selected = 'text'
          savedTemplates.push(templateData[0])
        } else if (message.type === 'carousel') {
          const carouselTemplateData = getCarouselTemplateData(
            message,
            templateRef
          )

          savedTemplates.push(carouselTemplateData)
        }
        return true
      })
      return savedTemplates
    }

    const templateData = _.map(templateRef.current, () => ({
      tmpId: generateRandomId(32),
      button: null,
      selected: 'text',
      switch: false,
      template: {
        message: 'This is a system generated response',
      },
    }))
    templateData.selected = 'text'
    savedTemplates.push(templateData[0])

    return savedTemplates
  }

  useEffect(() => {
    if (selectedTags.length > 0) {
      setTriggersError(false)
    }
  }, [selectedTags])

  const onSetFromRef = (ref) => {
    formRef.current = ref
    updatedTemplates.current = [...updatedTemplates.current]
    setTemplates([...updatedTemplates.current])
  }

  const {
    queries: {
      useEinsteinDialogueCreate,
      useEinsteinDialogueUpdate,
      useHospitalQuery,
    },
  } = useApi({
    queries: [
      'useEinsteinDialogueCreate',
      'useEinsteinDialogueUpdate',
      'useHospitalQuery',
    ],
  })

  const { mutateAsync: queryBot } = useHospitalQuery({
    variables: {},
    onSuccess: ({ data }) => {
      const metadata = data?.hospitalJarvisQuery?.metadata?.variables || []

      let strObj = JSON.stringify(data)

      Object.keys(metadata).forEach((key) => {
        const escapedKey = key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // escape special regex characters
        const pattern = new RegExp(`\\$\\{${escapedKey}\\}`, 'g')
        strObj = strObj.replace(pattern, metadata[key])
      })

      const replacedObj = JSON.parse(strObj)
      const botReplies = replacedObj?.hospitalJarvisQuery?.messages
      botReplies.forEach((message) => {
        if (message.favorite) {
          // eslint-disable-next-line no-param-reassign
          delete message.favorite
        }
      })
      const filteredMessages = botReplies.filter(
        (message) =>
          !(
            message.message &&
            message.message.type === 'text' &&
            message.message.text.toLowerCase() ===
              'this is a system generated message'
          )
      )
      setBotResponse(filteredMessages)
    },
  })

  const goBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if (defaultData && !defaultData?.isUserGenerated) {
      const dialogue = _.get(defaultData, 'dialogue')
      const inp = {
        query: dialogue.title,
      }
      queryBot({ input: inp })
    }

    if (defaultData) {
      const dialogue = _.get(defaultData, 'dialogue')
      if (dialogue) {
        formRef.current.updateFormData('dialogueName', dialogue.title)
      }

      const edges = _.get(defaultData, 'triggers.edges', [])
      if (!_.isEmpty(edges)) {
        const triggers = edges.map((edge) => {
          const trigger = edge.node.entities[0].phrases[0]
          return {
            label: trigger,
            value: trigger,
          }
        })
        setSelectedTags(uniqBy(compact(triggers), 'value'))
      }

      const messages = _.get(defaultData, 'messages', [])
      if (!_.isEmpty(messages)) {
        const savedTemplates = getTemplateDataFromMessages(messages)
        updatedTemplates.current = savedTemplates
        setTemplates(savedTemplates)
      }
    }
  }, [defaultData])

  useEffect(() => {
    if (!_.isEmpty(botResponse)) {
      const baseObject = {
        uid: generateRandomId(32),
        direction: 'outgoing',
        metadata: {
          pusher: {
            status: 'sent',
          },
          hospital: {
            short_name: 'KTPH',
            delivery_by: 'bot',
          },
        },
        createdOn: '2023-09-14T06:37:17.631Z',
      }

      const baseArray = botResponse.map((message) => ({
        ...baseObject,
        messageContent: message,
      }))

      const result = baseArray.map((item) => [
        {
          node: {
            uid: item.uid,
            direction: item.direction,
            metadata: item.metadata,
            createdOn: item.createdOn,
            messageContent: item.messageContent,
          },
        },
      ])

      setTimeout(() => {
        window?.frames?.[0]?.postMessage(result, '*')
      }, 300)
    }
  }, [botResponse])

  const showSuccessDialog = (title) => {
    openModal(
      <SuccessDialog
        testId="saveWelcomeMenuSuccessToast"
        title={title}
        primaryButton={{
          text: 'Back to dialogues',
          onClick: () => {
            closeModal()
            goBack()
          },
        }}
      />
    )
  }

  const showErrorDialog = (title, errorInfo = null) => {
    openModal(
      <ErrorDialog
        title={title}
        errorInfo={errorInfo}
        primaryButton={{
          text: 'Close',
          onClick: () => {
            closeModal()
          },
        }}
      />
    )
  }

  const { mutateAsync: createDialogueEntry } = useEinsteinDialogueCreate({
    variables: {},
    onSuccess: ({
      data: { errors, einsteinDialogueEntryCreate },
      queryClient,
    }) => {
      if (errors && errors.length) {
        showErrorDialog(
          'Please fix the following errors:',
          <ul>
            {errors.map((err, i) => (
              // eslint-disable-next-line no-underscore-dangle
              <li key={i}>
                {err?.message.replace(/[^\w\s]/gi, '').replace('__all__', '')}
              </li>
            ))}
          </ul>
        )
        sendLog(
          {
            subSource: EventsConstant.DIALOG_FORM_SOURCE,
            page: EventsConstant.DIALOG_PAGE,
            metaData: JSON.stringify(errors || []),
          },
          EventsConstant.DIALOG_ERROR_CREATE
        )
      } else if (!einsteinDialogueEntryCreate) {
        sendLog(
          {
            subSource: EventsConstant.DIALOG_FORM_SOURCE,
            page: EventsConstant.DIALOG_PAGE,
            metaData: 'FE: Something went wrong. Please try again',
          },
          EventsConstant.DIALOG_ERROR_CREATE
        )
      } else {
        sendLog(
          {
            subSource: EventsConstant.DIALOG_FORM_SOURCE,
            page: EventsConstant.DIALOG_PAGE,
            metaData: JSON.stringify(einsteinDialogueEntryCreate || {}),
          },
          EventsConstant.DIALOG_SUCCESS_CREATE
        )
        if (queryClient) {
          queryClient.invalidateQueries('einsteinDialogues')
        }
        showSuccessDialog('Dialogue has been saved')
      }
    },
  })

  const { mutateAsync: updateDialogueEntry } = useEinsteinDialogueUpdate({
    variables: {},
    onSuccess: ({
      data: { errors, einsteinDialogueEntryUpdate },
      queryClient,
    }) => {
      if (errors && errors.length) {
        showErrorDialog(
          'Please fix the following errors:',
          <ul>
            {errors.map((err, i) => (
              // eslint-disable-next-line no-underscore-dangle
              <li key={i}>
                {err?.message.replace(/[^\w\s]/gi, '').replace('__all__', '')}
              </li>
            ))}
          </ul>
        )
        sendLog(
          {
            subSource: EventsConstant.DIALOG_FORM_SOURCE,
            page: EventsConstant.DIALOG_PAGE,
            metaData: JSON.stringify(errors || []),
          },
          EventsConstant.DIALOG_ERROR_UPDATE
        )
      } else if (!einsteinDialogueEntryUpdate) {
        sendLog(
          {
            subSource: EventsConstant.DIALOG_FORM_SOURCE,
            page: EventsConstant.DIALOG_PAGE,
            metaData: 'Something went wrong. Please try again',
          },
          EventsConstant.DIALOG_ERROR_UPDATE
        )
        showErrorDialog('Something went wrong. Please try again')
      } else {
        sendLog(
          {
            subSource: EventsConstant.DIALOG_FORM_SOURCE,
            page: EventsConstant.DIALOG_PAGE,
            metaData: JSON.stringify(einsteinDialogueEntryUpdate || {}),
          },
          EventsConstant.DIALOG_SUCCESS_UPDATE
        )
        if (queryClient) {
          queryClient.invalidateQueries('einsteinDialogues')
        }
        showSuccessDialog('Dialogue has been saved')
      }
    },
  })

  const mapQuickOptionsMessage = () => {
    const quickOptions = selectedQuickOptionsRef.current.map((option) => ({
      type: 'quick_reply',
      quick_reply: {
        url: `botmd://hospital/postMessage?text=${option.label}`,
        text: option.label,
      },
    }))

    return {
      type: 'message',
      message: {
        type: 'quick_replies',
        quick_replies: quickOptions,
      },
    }
  }

  const handleMessageChange = () => {
    const templateData = _.map(templateRef.current, (template) =>
      template.getTemplateData(false)
    )

    const messagesToRender = []
    const templateMessages = templateData.map((data, index) => {
      let messageUid = ''

      if (uidIndexMap[index]) {
        messageUid = uidIndexMap[index]
      } else {
        messageUid = generateRandomId(32)
        uidIndexMap[index] = messageUid
        setUidIndexMap(uidIndexMap)
      }

      return mapTemplateMessage(einsteinAdmin, data, 'outgoing', messageUid)
    })

    if (!isEmpty(selectedQuickOptionsRef.current)) {
      const quickOptionsMessage = mapQuickOptionsMessage()
      const message = mapTemplateMessage(
        einsteinAdmin,
        quickOptionsMessage,
        'incoming'
      )

      templateMessages.push(message)
    }

    messagesToRender.push(templateMessages)

    if (window.frames.length >= 1) {
      window?.frames?.[0]?.postMessage(messagesToRender, '*')
    }
  }

  const templateOnSort = (index, direction) => {
    if (_.isEqual(direction, 'up') && _.gt(index, 0)) {
      const newTemplateRef = templateRef.current
      const value = _.pullAt(newTemplateRef, [index])
      newTemplateRef.splice(index - 1, 0, value[0])
      templateRef.current = newTemplateRef
    }

    if (_.isEqual(direction, 'down') && _.lt(index, templates.length)) {
      const newTemplateRef = templateRef.current
      const value = _.pullAt(newTemplateRef, [index])
      newTemplateRef.splice(index + 1, 0, value[0])
      templateRef.current = newTemplateRef
    }
    updatedTemplates.current = _.map(templateRef.current, (template) =>
      template.getRawFormData()
    )
    setTemplates(
      _.map(templateRef.current, (template) => template.getRawFormData())
    )
    handleMessageChange()
  }

  const templateOnDelete = (id) => {
    // Remove useRef for component
    const newTemplateRef = _.filter(
      templateRef.current,
      (template, index) => !_.isEqual(index, id)
    )
    templateRef.current = newTemplateRef

    // Remove component
    updatedTemplates.current = _.filter(
      templates,
      (template, index) => !_.isEqual(index, id)
    )
    setTemplates(
      _.filter(templates, (template, index) => !_.isEqual(index, id))
    )

    handleMessageChange()
  }

  if (window.frames[0] && !window.frames[0].closed) {
    handleMessageChange()
  }

  const callback = () => {
    handleMessageChange()
  }

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.closeWidget) {
        toggleOpen()
      }
      if (event.data && event.data.listener) {
        const previewMode = { previewMode: true }
        setTimeout(() => {
          window?.frames?.[0]?.postMessage(previewMode, '*')
        }, 400)
        setTimeout(() => {
          setRenderPreviewPane(true)
        }, 600)
      }
    })
  }, [])

  const onSetTemplateRef = (ref, index) => {
    templateRef.current[index] = ref
  }

  const addTemplate = () => {
    const templateData = _.map(templateRef.current, (template) =>
      template.getRawFormData()
    )
    updatedTemplates.current = [...templateData, {}]
    setTemplates([...templateData, { tmpId: generateRandomId(32) }])
  }

  const templateOnClear = (id) => {
    templateRef.current[id].resetFormData()
  }

  const forms = [
    {
      id: 'dialogueName',
      title: 'Dialogue name',
      description:
        'Enter a name for your dialogue so you can search this easily',
      type: 'input',
      fields: [
        {
          id: 'dialogueName',
          testId: 'dialogueName',
          type: 'input',
          visibility: true,
          label: 'Dialogue name',
          editable: defaultData?.isUserGenerated,
          placeholder: 'Enter dialogue name',
          limit: 50,
          required: true,
        },
      ],
    },
    {
      id: 'dialogueTriggers',
      title: 'Dialogue triggers',
      description:
        'Enter keywords and phrases that users will use to trigger the dialogue response. Example: “hello Bot”, “contact ICU”, “who is on call”',
      fields: [
        {
          id: 'dialogueTriggers',
          testId: 'dialogueTriggers',
          type: 'custom',
          component: (
            <DialogueTriggers
              editable={!defaultData?.isUserGenerated}
              selectedTags={selectedTags}
              setSelectedTags={(tags) => {
                setTemplates([...updatedTemplates.current])
                setSelectedTags(tags)
              }}
              triggersError={triggersError}
            />
          ),
          required: false,
        },
      ],
    },
    {
      id: 'botResponse',
      title: 'Create bot response',
      description:
        'Enter the response for your dialogue trigger. Bot responses can be text only, images, text with images or text with button links to files and websites.',
      fields: [
        {
          id: 'botResponse',
          testId: 'botResponse',
          type: 'custom',
          component: (
            <MessageComponent
              templates={templates}
              onTemplateSort={templateOnSort}
              onTemplateDelete={templateOnDelete}
              isDisabled={!defaultData?.isUserGenerated}
              onFormChange={callback}
              onTemplateClear={templateOnClear}
              onSetTemplateRef={onSetTemplateRef}
              onAddTemplate={addTemplate}
            />
          ),
          required: false,
        },
      ],
    },
    {
      id: 'quickOptions',
      title: 'Add Quick Options',
      description:
        'You can attach Quick Options buttons in your broadcast to guide your users to take more action. You must have added at least 1 Text content. ',
      fields: [
        {
          id: 'quickOptions',
          testId: 'quickOptions',
          type: 'custom',
          component: (
            <QuickOptions
              isDisabled={!defaultData?.isUserGenerated}
              selectedQuickOptions={selectedQuickOptions}
              onSelected={(selected) => {
                if (
                  !quickOptionRef.current ||
                  quickOptionRef.current.length <= selected.length
                ) {
                  sendLog(
                    {
                      subSource: EventsConstant.DIALOG_FORM_SOURCE,
                      page: EventsConstant.DIALOG_PAGE,
                      metaData: JSON.stringify(
                        selected[selected.length - 1] || {}
                      ),
                    },
                    EventsConstant.DIALOG_ADDED_QUICK_OPTION
                  )
                }
                setTemplates([...updatedTemplates.current])
                setSelectedQuickOptions(selected)
                selectedQuickOptionsRef.current = selected
                quickOptionRef.current = selected
              }}
            />
          ),
          required: false,
        },
      ],
    },
  ]

  const getDialogueInput = (formData, templateData) => {
    const triggers = selectedTags.map((tag) => ({
      isRequired: true,
      phrases: [tag.value],
    }))
    const triggersSet = triggers.map((trigger) => [trigger])

    const messages = templateData
    if (!isEmpty(selectedQuickOptions)) {
      const quickOptionsMessage = mapQuickOptionsMessage()
      messages.push(quickOptionsMessage)
    }

    if (shouldUpdate(defaultData)) {
      return {
        updateInput: {
          uid: defaultData.uid,
          title: formData.dialogueName,
          syncJarvis: true,
          messages,
          triggersSet,
        },
      }
    }
    return {
      createInput: {
        title: formData.dialogueName,
        syncJarvis: true,
        messages,
        triggersSet,
      },
    }
  }

  const onSaveChanges = () => {
    if (_.isEmpty(selectedTags)) {
      setTriggersError(true)
    }
    setTimeout(() => {
      const errorLabel = document.getElementsByClassName('error-message')
      if (errorLabel.length > 0) {
        errorLabel[0].scrollIntoView({ behavior: 'smooth' })
      }
    }, 300)

    const formData = formRef?.current?.getFormData(true)

    const preProcessedData = _.map(templateRef.current, (template) =>
      template.getTemplateData(true)
    )

    if (!formData) return
    if (_.includes(preProcessedData, null)) return
    const templateData = _.map(preProcessedData, (data) => {
      const transform = data
      if (
        data.message.type === 'image' &&
        !_.isEmpty(data.message.image.stitchUrl)
      ) {
        transform.message.image.url = data.message.image.stitchUrl
        delete transform.message.image.stitchUrl
        return transform
      }
      if (transform.message.image) {
        delete transform.message.image.stitchUrl
      }
      return transform
    })
    const input = getDialogueInput(formData, templateData)
    if (
      !_.isNull(formData) &&
      !_.includes(templateData, null) &&
      !_.isEmpty(selectedTags)
    ) {
      if (shouldUpdate(defaultData)) {
        updateDialogueEntry(input)
      } else {
        createDialogueEntry(input)
      }
    }
  }

  const isDialogueEmpty = () => {
    let dialogueEmpty = true
    const templateData = map(templateRef.current, (template) =>
      template.getTemplateData(false)
    )
    templateData.forEach((message) => {
      const messageData = message[message.type]
      if (messageData[messageData.type]) {
        dialogueEmpty = false
      }
    })
    if (
      formRef?.current?.formData?.dialogueName ||
      selectedTags?.length ||
      formRef?.current?.formData?.scheduleMessage
    ) {
      dialogueEmpty = false
    }
    if (selectedQuickOptions?.length) {
      dialogueEmpty = false
    }
    return dialogueEmpty
  }
  const isDialogueChanged = () => {
    let dialogueChanged = false
    const templateData = map(templateRef.current, (template) =>
      template.getTemplateData(false)
    )
    const newTemplateData = templateData
    templateData.forEach((messages, index) => {
      if (messages.message.image) {
        delete newTemplateData[index].message.image.stitchUrl
      }
    })
    const defaultMessageTemplate = []
    const defaukrQuickRepliesArr = []
    defaultData?.messages?.forEach((template) => {
      if (template.message.type === 'quick_replies') {
        template.message.quick_replies?.forEach((qr) => {
          defaukrQuickRepliesArr.push(qr.quick_reply.text)
        })
      } else {
        defaultMessageTemplate.push(template)
      }
    })
    if (!isEqual(defaultMessageTemplate, templateData)) {
      dialogueChanged = true
    }
    let defaultSelectedTags = []
    defaultData.triggers.edges.forEach((edge) => {
      const tag = edge?.node?.entities?.[0]?.phrases[0]
      defaultSelectedTags.push({ label: tag, value: tag })
      defaultSelectedTags = _.uniqBy(defaultSelectedTags, 'label')
    })
    if (!isEqual(selectedTags, defaultSelectedTags)) {
      dialogueChanged = true
    }

    if (
      formRef?.current?.formData?.dialogueName !== defaultData.dialogue.title
    ) {
      dialogueChanged = true
    }
    const selectedQuickRepliesArr = selectedQuickOptions.map((ele) => ele.label)
    if (!isEqual(selectedQuickRepliesArr, defaukrQuickRepliesArr)) {
      dialogueChanged = true
    }
    return dialogueChanged
  }
  const onDiscardChanges = () => {
    let showModal = false
    if (defaultData?.dialogue?.title) {
      showModal = isDialogueChanged()
    } else {
      showModal = !isDialogueEmpty()
    }

    if (!showModal) {
      goBack()
      return null
    }
    return openModal(
      <Box width="720px" p={4}>
        <Text fontWeight="bold" fontSize={3}>
          Are you sure?
        </Text>
        <Text mt={3} fontSize={2}>
          Your changes haven’t been saved. <br /> <br /> Are you sure you want
          to leave this page?
        </Text>
        <Flex mt={3} justifyContent="right" alignItems="center">
          <SecondaryOutlinedButton
            borderRadius="8px"
            mr={2}
            onClick={() => {
              closeModal()
              onSaveChanges()
            }}
          >
            Save changes
          </SecondaryOutlinedButton>
          <PrimaryButton
            borderRadius="8px"
            style={{ backgroundColor: '#E05138' }}
            onClick={() => {
              closeModal()
              goBack()
            }}
          >
            Discard and leave
          </PrimaryButton>
        </Flex>
      </Box>
    )
  }

  return (
    <Box mt={2}>
      {/* Back Button */}
      <BackButton
        id="backBtn"
        alignItems="center"
        mb={1}
        ml="16px"
        hover={{ opacity: 0.6 }}
        cursor="pointer"
        onClick={onDiscardChanges}
      >
        <FAIcon
          icon={faChevronLeft}
          color="darkestShade"
          fontWeight="500"
          style={{ fontSize: 12, fontWeight: 500, marginRight: 4 }}
        />
        <H5 fontSize={14} color="darkestShade">
          Back
        </H5>
      </BackButton>
      {/* Title */}
      <Flex justifyContent="space-between" alignItems="center">
        <Text m={2} mt={0} fontSize="32px" fontWeight="600">
          {!defaultData?.uid ? 'Add dialogue' : 'Edit dialogue'}
        </Text>
        <Flex justifyContent="right" alignItems="center">
          <SecondaryOutlinedButton
            borderRadius="8px"
            mr={3}
            disabled={!defaultData?.isUserGenerated}
            onClick={onDiscardChanges}
          >
            Discard
          </SecondaryOutlinedButton>
          <PrimaryButton
            borderRadius="8px"
            onClick={onSaveChanges}
            disabled={!defaultData?.isUserGenerated}
            data-testid="dialogueSaveButton"
          >
            Save
          </PrimaryButton>
        </Flex>
      </Flex>
      <Text m={2}>Create Bot dialogues for your hospital app users</Text>

      <Flex m={2}>
        <Box width="50%" ml="-16px">
          <Form
            formRef={onSetFromRef}
            forms={forms}
            labelSize="12px"
            startIcon={(index) => <CirclularChip label={index + 1} />}
          />
        </Box>

        {/* TODO: Preview message */}
        <BotWidget
          renderPreviewPane={renderPreviewPane}
          toggleOpen={toggleOpen}
          open={open}
        />
      </Flex>
    </Box>
  )
}

const MessageComponent = ({
  templates,
  onTemplateSort,
  onTemplateDelete,
  onFormChange,
  templateForms,
  onTemplateClear,
  onSetTemplateRef,
  isDisabled = false,
  onAddTemplate,
}) => (
  <>
    {_.map(templates, (template, index) => (
      <BroadcastMessageTemplate
        key={template.tmpId}
        id={index}
        tmpId={template.tmpId}
        onSort={onTemplateSort}
        onDelete={onTemplateDelete}
        defaultFormData={template}
        selectedState={template.selected}
        onFormChange={onFormChange}
        onClear={onTemplateClear}
        templateRef={(ref) => onSetTemplateRef(ref, index)}
        templateForms={templateForms}
        hideDelete={templates.length <= 1}
        isDisabled={isDisabled}
        hideMoveUp={index === 0}
        hideMoveDown={index >= templates.length - 1}
        isBroadcastMessage={false}
      />
    ))}
    {templates.length >= MAX_TEMPLATE_COUNT ? (
      <Text
        ml={1}
        fontSize="14px"
        color="darkestShade"
        letterSpacing="-0.08px"
        lineHeight="24px"
        fontWeight="500"
      >
        <i>*You have reached the max number of messages for this dialogue</i>
      </Text>
    ) : (
      !isDisabled && (
        <Box mb={4} cursor="pointer" onClick={onAddTemplate}>
          <Flex mt={3} ml="12px" alignItems="center">
            <FAIcon
              fontSize="16px"
              icon={faPlus}
              style={{ color: '#256BF6' }}
            />
            <Text
              ml={1}
              color="#256BF6"
              fontSize="14px"
              letterSpacing="-0.08px"
              lineHeight="24px"
              fontWeight="500"
            >
              Add Content
            </Text>
          </Flex>
        </Box>
      )
    )}
  </>
)

export default DialogueCreate
