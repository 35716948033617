import React from 'react'
import _ from 'lodash'
import { Box, Flex, Text } from '@fivehealth/botero'

import OutlinedBox from 'old-code/components/Box/OutlinedBox'

import getCell from './Utils'

const BroadcastManagerTaskInfoDelivery = ({ data }) => {
  const deliveryRate =
    data.totalProfiles > 0
      ? _.chain(data.deliveredProfilesCount)
          .divide(data.totalProfiles)
          .multiply(100)
          .value()
      : 0
  return (
    <Box mt={2}>
      <Box m={2} mt={4}>
        <Flex alignItems="center">
          <OutlinedBox p={2} width="26%">
            <Text fontWeight="600" fontSize={4}>
              {data.totalProfiles}
            </Text>
            <Text mt={2} color="darkestShade">
              Recipients
            </Text>
          </OutlinedBox>
          <OutlinedBox ml={6} p={2} width="26%">
            <Text fontWeight="600" fontSize={4} data-testid="recipientCount">
              {`${
                _.isNaN(deliveryRate) || deliveryRate === 0
                  ? 'N/A'
                  : `${deliveryRate.toFixed(2)} %`
              }`}
            </Text>
            <Text mt={2} color="darkestShade" data-testid="deliveryRate">
              Delivery rate
            </Text>
          </OutlinedBox>
          {/* <OutlinedBox p={2} width="26%">
            <Text fontWeight="600" fontSize={4}>
              {data.deliveredProfilesCount}
            </Text>
            <Text mt={2} color="darkestShade">
              Recipients acknowledged
            </Text>
          </OutlinedBox> */}
        </Flex>
      </Box>

      {/* Details */}
      <Text m={2} mt={5} fontWeight="700" fontSize="24px">
        Delivery Summary
      </Text>
      <Box m={2}>
        <Flex m={1} alignItems="center">
          <Text
            width="200px"
            mr={1}
            fontSize="14px"
            fontWeight="500"
            color="darkestShade"
            data-testid="deliveryStatus"
          >
            Status
          </Text>
          <Text fontSize="14px">{getCell('status', data.status)}</Text>
        </Flex>
        <Flex m={1} alignItems="center">
          <Text
            width="200px"
            mr={1}
            fontSize="14px"
            fontWeight="500"
            color="darkestShade"
            data-testid="deliverySentDate"
          >
            Sent on
          </Text>
          <Text fontSize="14px">{getCell('sentOn', data.sentOn)}</Text>
        </Flex>
        <Flex m={1} mt={3} alignItems="center">
          <Text
            width="200px"
            mr={1}
            fontSize="14px"
            fontWeight="500"
            color="darkestShade"
            data-testid="deliveryRate"
          >
            Delivery Rate
          </Text>
          <Flex alignItems="center">
            <Text fontSize="14px">
              {_.isNaN(deliveryRate) ? 0 : deliveryRate.toFixed(2)} %
            </Text>
          </Flex>
        </Flex>
        {/* <Flex m={1} mt={3} alignItems="center">
          <Text
            width="200px"
            mr={1}
            fontSize="14px"
            fontWeight="500"
            color="darkestShade"
          >
            Read receipt
          </Text>
          <Flex alignItems="center">
            <Text fontSize="14px">{data.deliveredProfilesCount}</Text>
          </Flex>
        </Flex> */}
        <Flex m={1} mt={3} alignItems="center">
          <Text
            width="200px"
            mr={1}
            fontSize="14px"
            fontWeight="500"
            color="darkestShade"
            data-testid="deliveryRecipientCount"
          >
            Recipients
          </Text>
          <Flex alignItems="center">
            <Text fontSize="14px">{`${data.totalProfiles} users`}</Text>
          </Flex>
        </Flex>
        <Flex m={1} mt={3} alignItems="center">
          <Text
            width="200px"
            mr={1}
            fontSize="14px"
            fontWeight="500"
            color="darkestShade"
            data-testid="deliveryRecipientCount"
          >
            Created by
          </Text>
          <Flex alignItems="center">
            <Text fontSize="14px">{data?.createdBy}</Text>
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}

export default BroadcastManagerTaskInfoDelivery
