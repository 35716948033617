import React, { useEffect, useState } from 'react'
import { Box, Text, useApi } from '@fivehealth/botero'
import { chain } from 'lodash'
import { useQueryClient } from 'react-query'
import AsyncSelect from 'react-select/async'
import Input from 'old-code/components/Input/Input'
import { GRAPHQL_DOCUMENT as DOCUMENT_ENTRIES_GQL } from 'old-code/api/queries/useEinsteinDocumentEntrys'
import BotSelect from 'old-code/components/BotSelect/BotSelect'
import getCustomStyles from 'old-code/components/BotSelect/Helper'

const FavouritesFormContentTypeFields = ({
  contentTypeValue = '',
  onChange,
  formData = {},
  isPrefillMode = false,
  formErrorMessage,
  disabled = false,
}) => {
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [selectedModule, setSelectedModule] = useState(null)
  const [documents, setDocuments] = useState([])
  const [customClinicalModules, setCustomClinicalModules] = useState([])
  const queryClient = useQueryClient()
  const { client } = useApi()

  const {
    queries: { useEinsteinDocumentEntrys, useEinsteinAdministrator },
  } = useApi({
    queries: ['useEinsteinDocumentEntrys', 'useEinsteinAdministrator'],
  })

  const { data: currentAdmin } = useEinsteinAdministrator()
  const { data: documentsData } = useEinsteinDocumentEntrys({
    variables: {
      dataSourceIn: [
        `${
          ((currentAdmin || {}).hospital || {}).organizationKey || ''
        }-documents`,
      ],
    },
  })

  const handleFetch = async (input) => {
    queryClient.cancelQueries('einsteinDocuments')
    if (input && input.length > 2) {
      const data = await queryClient.fetchQuery(
        'einsteinDocuments',
        () =>
          client.request(DOCUMENT_ENTRIES_GQL, {
            searchQuery: input,
            first: 25,
          }),
        { cacheTime: 0 }
      )

      const formattedData = chain(data)
        .get('einsteinDocumentEntrys', [])
        .get('edges', [])
        .filter((doc) => !doc.node.isDeactivated)
        .uniqBy('node.uid')
        .map((doc) => {
          const docObj = {
            label: doc.node.title,
            value: doc.node.originalFileStitchUrl || doc.node.webUrl,
          }

          return docObj
        })
        .value()

      const uniqueArray = []
      formattedData.filter((item) => {
        const i = uniqueArray.findIndex(
          (x) => x.label === item.label && x.value === item.value
        )
        if (i <= -1) {
          uniqueArray.push(item)
        }
        return null
      })

      return new Promise((resolve) =>
        setTimeout(() => resolve(uniqueArray), 100)
      )
    }

    return new Promise((resolve) =>
      setTimeout(() => resolve(documents || []), 100)
    )
  }

  const handleAsyncSelect = (selected) => {
    if (selected) {
      onChange(selected, 'selectedDoc')
    }
  }

  const renderDocumentSelect = () => {
    if (
      documents.length > 0 &&
      ((isPrefillMode && selectedDocument) || !isPrefillMode)
    ) {
      return (
        <Box width="100%" mb={3} mt={1}>
          <Text fontSize="14px" fontWeight="bold" color="darkestShade" mb={1}>
            Select document
          </Text>

          <AsyncSelect
            styles={getCustomStyles('100%', false)}
            classNames={{ control: () => 'target-border' }}
            placeholder="Type to search"
            loadOptions={(input) =>
              new Promise((resolve) =>
                setTimeout(() => resolve(handleFetch(input)), 400)
              )
            }
            cacheOptions
            defaultOptions={documents || []}
            defaultValue={selectedDocument}
            isClearable
            onChange={(selected) => handleAsyncSelect(selected)}
          />
        </Box>
      )
    }
    return <></>
  }

  useEffect(() => {
    if (documents.length && formData.url && contentTypeValue === 'document') {
      const newSelectedDocument = documents.find(
        (document) => formData.url === document.value
      )
      setSelectedDocument(newSelectedDocument || null)
    } else if (formData.url) {
      handleFetch(formData.subtitle).then((docs) => {
        setSelectedDocument(docs[0])
      })
    }

    if (
      customClinicalModules.length &&
      formData.url &&
      contentTypeValue === 'custom_clinical_module'
    ) {
      const newSelectedModule = customClinicalModules.find(
        (clnicalModule) => formData.url === clnicalModule.value
      )
      setSelectedModule(newSelectedModule || null)
    }
  }, [formData])

  useEffect(() => {
    const hospitalSettingsFavorites =
      (
        ((((currentAdmin || {}).hospital || {}).settings || {}).legacy || {})
          .modules || []
      ).find(({ key }) => key === 'favorites') || {}

    if (!hospitalSettingsFavorites) {
      return
    }

    const clinicalModules =
      hospitalSettingsFavorites?.metadata?.links.map((settingsFavorite) => ({
        value: settingsFavorite.url,
        label: settingsFavorite.title,
      })) || []

    setCustomClinicalModules(clinicalModules)

    if (contentTypeValue !== 'custom_clinical_module') {
      return
    }

    const newSelectedModule = clinicalModules.find(
      (clinicalModule) => formData.url === clinicalModule.value
    )

    if (formData.url && newSelectedModule) {
      setSelectedModule(newSelectedModule || null)
    } else if (clinicalModules.length && selectedModule === null) {
      onChange(clinicalModules[0].value, 'url')
    } else {
      onChange(null, 'url')
    }
  }, [currentAdmin, contentTypeValue])

  const handleChange = (e, formkey) => {
    onChange(e.target.value, formkey)
  }

  const handleChangeSelect = (selected) => {
    if (selected) {
      onChange(selected.value, 'url')
    }
  }

  useEffect(() => {
    if (selectedDocument) {
      setDocuments((prevDocuments) => [...prevDocuments, selectedDocument])
    }
  }, [selectedDocument])

  useEffect(() => {
    if (documentsData) {
      const docs = chain(documentsData.pages)
        .flatMap() // flatten the pages array
        .filter((doc) => !doc.isDeactivated) // exclude deactivated documents
        .map((doc) => ({
          value: doc.originalFileStitchUrl || doc.webUrl,
          label: doc.title,
        })) // create new objects with 'value' and 'label' properties
        .sortBy('label') // sort by title
        .value()
      setDocuments(docs)

      if (contentTypeValue !== 'document') {
        return
      }

      const newSelectedDocument = docs.find(
        (document) => formData.url === document.value
      )
      if (formData.url && newSelectedDocument) {
        setSelectedDocument(newSelectedDocument || null)
      } else if (
        docs.length &&
        selectedDocument === null &&
        formData.url === null
      ) {
        onChange(docs[0].value, 'url')
      } else {
        handleFetch(formData.subtitle).then((otherDocs) => {
          setSelectedDocument(otherDocs[0])
        })
      }
    }
  }, [documentsData, contentTypeValue])

  const handleWebsiteLinkBlur = () => {
    if (
      formData.websiteLink &&
      !formData.websiteLink.match('^(http|https)://')
    ) {
      onChange(`https://${formData.websiteLink}`, 'url')
    }
  }

  const renderCustomClinicalModuleSelect = () => (
    <Box width="100%" mb={3} mt={1}>
      <Text fontSize="14px" fontWeight="bold" color="darkestShade" mb={1}>
        Select module
      </Text>
      <BotSelect
        isMulti={false}
        isClearable={false}
        options={customClinicalModules}
        onChange={(selected) => handleChangeSelect(selected)}
        value={selectedModule}
        errorMessage={(formErrorMessage || {}).module}
        isDisabled={disabled}
      />
    </Box>
  )
  const renderWebsiteLinkField = () => (
    <Box width="100%" mb={3} mt={-2}>
      <Input
        label="Website link"
        fontSize="14px"
        placeholder="E.g. https://www.botmd.com"
        onChange={(e) => handleChange(e, 'url')}
        onBlur={handleWebsiteLinkBlur}
        value={formData.url || ''}
        error={!!(formErrorMessage || {}).websiteLink}
        errorLabel={(formErrorMessage || {}).websiteLink}
        disabled={disabled}
      />
    </Box>
  )

  switch (contentTypeValue) {
    case 'document':
      return renderDocumentSelect()
    case 'custom_clinical_module':
      return renderCustomClinicalModuleSelect()
    case 'website_link':
      return renderWebsiteLinkField()
    default:
      return null
  }
}

export default FavouritesFormContentTypeFields
