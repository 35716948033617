import { Text, Box, Tooltip, FAIcon } from '@fivehealth/botero'
import React, { useState } from 'react'
// import { faLongArrowUp } from '@fortawesome/pro-solid-svg-icons'
import { faSquareArrowUpRight } from '@fortawesome/pro-regular-svg-icons'
import useToken from 'old-code/customHooks/useToken'
import { getPDFViewerDomain } from 'Utils'
import { useConfig } from 'old-code/context/ConfigContext'

export const TableCell = ({ children, cursor }) => (
  <Box cursor={cursor || 'pointer'} onClick={() => {}}>
    {children}
  </Box>
)

const FileCell = (props) => {
  const { cell } = props
  const [show, setShow] = useState(false)
  const token = useToken()
  const { config } = useConfig()

  const isVideo =
    cell?.row?.original?.misc?.originalFileStitchUrl?.endsWith('.mp4')

  const handleOnClick = (e) => {
    e.stopPropagation()
    props?.logPostHogEvent('files:file_item_external_link_click')
    if (!props.isExternal) {
      if (isVideo) {
        window.open(cell?.row?.original?.misc?.webUrl)
      } else {
        const recieverWindow = window.open(
          `https://${getPDFViewerDomain(
            config
          )}/?disableAnnotations=true&gql_uri=${config.GQL_ENDPOINT}&document=${
            cell.row.original.misc.originalFileStitchUrl
          }`,
          '_blank'
        )
        const messageData = {
          type: 'secureToken',
          secureToken: token,
        }
        setTimeout(() => {
          recieverWindow.postMessage(
            messageData,
            `https://${getPDFViewerDomain(config)}`
          )
        }, 1500)
      }
    } else {
      window.open(cell.row.original.misc.webUrl, '_blank')
    }
  }

  return (
    <TableCell {...props}>
      <Tooltip
        show={show}
        tooltip={<Text color="white">Open file in new tab</Text>}
      >
        <FAIcon
          onClick={handleOnClick}
          cursor="pointer"
          icon={faSquareArrowUpRight}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        />
      </Tooltip>
    </TableCell>
  )
}

export default FileCell
