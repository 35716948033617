import React, { useEffect } from 'react'
import _ from 'lodash'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'

import {
  Flex,
  Text,
  Box,
  PrimaryButton,
  FAIcon,
  SecondaryOutlinedButton,
  useApi,
  sendLog,
} from '@fivehealth/botero'

import { useModal } from 'old-code/context/ModalContext'
import { useNavStateContext } from 'old-code/context/NavStateContext'
import DropdownMenu from 'old-code/components/DropdownMenu/DropdownMenu'
import EventsConstant from '../../../../config/constants/events.constants'

const UserGroupsTableRowSelect = ({
  isArchivedView,
  selectedFlatRows,
  toggleAllRowsSelected,
  data = [],
  enableRowSelect,
  rowSelectionType,
  onCancelBulkUpdate,
  selectedRowUids,
  toggleRowSelected,
  addToFixedGroupFlow,
  setIsDeleting,
  setSelectedRowUids,
}) => {
  const selectedCount = selectedRowUids.length
  const { expanded } = useNavStateContext()
  const {
    queries: { useProfilesGroupDelete },
  } = useApi({
    queries: ['useProfilesGroupDelete'],
  })
  const { openModal, closeModal } = useModal()

  const { mutateAsync: deleteProfilesGroup } = useProfilesGroupDelete({
    variables: {},
    onSuccess: ({ queryClient, data: resultData }) => {
      setIsDeleting(false)
      if (resultData?.errors?.length) {
        sendLog(
          {
            subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
            page: EventsConstant.USER_GROUP_PAGE,
            metaData: JSON.stringify(resultData.errors || []),
          },
          EventsConstant.USER_GROUP_ERROR_DELETE
        )
      }
      if (queryClient) {
        queryClient.invalidateQueries('profilesGroup')
        queryClient.invalidateQueries('allProfileGroups')
      }
    },
  })

  useEffect(() => {
    data.forEach((group) => {
      const isGroupSelected = _.find(
        selectedRowUids,
        (selectedRow) => selectedRow.uid === group.uid
      )
      if (isGroupSelected) {
        toggleRowSelected(group.uid, true)
      }
    })
  }, [data, toggleRowSelected, selectedRowUids])

  useEffect(() => {
    if (enableRowSelect) {
      toggleAllRowsSelected(rowSelectionType !== 'customSelect')
    }
  }, [rowSelectionType, toggleAllRowsSelected, enableRowSelect])

  const activeActions = [
    // {
    //   id: 'sendBroadcast',
    //   label: 'Send Broadcast',
    //   onClick: () => {
    //     toggleAllRowsSelected(false)
    //     return openModal(
    //       <Box p={4}>
    //         <BroadcastMessageCreate
    //           closeModal={closeModal}
    //           inModal="true"
    //           defaultData={{
    //             recipients: _.map(selectedFlatRows, (row) => ({
    //               uid: row.original.uid,
    //               value: row.original,
    //               label: row.original.name,
    //               type: 'profilesGroup',
    //               addOptions: 'false',
    //             })),
    //           }}
    //           defaultValue={_.map(selectedFlatRows, (row) => row.original.uid)}
    //         />
    //       </Box>,
    //       { overflow: 'scroll' }
    //     )
    //   },
    // },
    {
      id: 'archiveGroup',
      label: selectedCount > 1 ? 'Delete Groups' : 'Delete Group',
      color: 'red',
      divider: true,
      onClick: () => {
        openModal(
          <Box width="640px" p={4}>
            <Text fontWeight="bold" fontSize={3}>
              Are you sure?
            </Text>
            <Text mt={3} fontSize={2}>
              Are you sure you want to delete the selected group(s)?
            </Text>
            <Flex mt={3} justifyContent="right" alignItems="center">
              <SecondaryOutlinedButton
                borderRadius="8px"
                mr={2}
                onClick={() => {
                  closeModal()
                }}
              >
                Cancel
              </SecondaryOutlinedButton>
              <PrimaryButton
                borderRadius="8px"
                style={{ backgroundColor: '#E05138' }}
                onClick={() => {
                  setIsDeleting(true)
                  closeModal()
                  Promise.all(
                    _.map(selectedFlatRows, (row) =>
                      deleteProfilesGroup({
                        input: {
                          uid: row.original.uid,
                        },
                      })
                    )
                  ).then(() => {
                    onCancelBulkUpdate()
                  })
                }}
              >
                Delete
              </PrimaryButton>
            </Flex>
          </Box>
        )
      },
      logEventProps: {
        subSource: EventsConstant.USER_GROUP_BULK_ACTION_SOURCE,
        eventName: EventsConstant.USER_GROUP_DELETE_GROUP_PROMPTED,
        page: EventsConstant.USER_GROUP_PAGE,
      },
    },
  ]

  const archivedActions = [
    {
      id: 'restoreGroup',
      label: 'Restore Group',
      color: 'red',
      onClick: () =>
        Promise.all(
          _.map(selectedFlatRows, (row) =>
            deleteProfilesGroup({
              input: {
                uid: row.original.uid,
                deactivatedOn: null,
              },
            })
          )
        ).then(() => toggleAllRowsSelected(false)),
      logEventProps: {
        subSource: EventsConstant.USER_GROUP_BULK_ACTION_SOURCE,
        eventName: EventsConstant.USER_GROUP_RESTORE_GROUP_PROMPTED,
        page: EventsConstant.USER_GROUP_PAGE,
      },
    },
  ]

  if (addToFixedGroupFlow) {
    return null
  }

  return (
    <Box
      position="fixed"
      zIndex="1"
      bg="#E9F0FE"
      p={2}
      borderRadius="8px"
      bottom="18px"
      right={`${expanded ? '60px' : '150px'}`}
      left={`${expanded ? '400px' : '200px'}`}
      boxShadow="0px 6px 12px #98a2b326, 0px 4px 4px #98a2b326, 0px 2px 2px #98a2b326"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <Text m={1} textAlign="center" color="primary" fontWeight="bold">
            {selectedCount} {selectedCount <= 1 ? 'user group' : 'user groups'}{' '}
            selected
          </Text>
          {rowSelectionType !== 'selectAll' && !_.isEmpty(selectedRowUids) && (
            <SecondaryOutlinedButton
              borderRadius="8px"
              bg="white"
              color="#697481"
              letterSpacing="-0.08px"
              fontWeight="500"
              onClick={() => {
                toggleAllRowsSelected(false)
                setSelectedRowUids([])
              }}
            >
              Deselect
            </SecondaryOutlinedButton>
          )}
        </Flex>
        <Flex>
          <SecondaryOutlinedButton
            mr={2}
            borderRadius="8px"
            bg="white"
            onClick={onCancelBulkUpdate}
          >
            Cancel
          </SecondaryOutlinedButton>
          <DropdownMenu
            fontWeight="normal"
            disabled={!selectedRowUids.length}
            actions={isArchivedView ? archivedActions : activeActions}
            label={
              <PrimaryButton
                borderRadius="8px"
                mr={2}
                endIcon={<FAIcon icon={faAngleDown} color="lightShade" />}
                disabled={!selectedRowUids.length}
              >
                Bulk actions
              </PrimaryButton>
            }
          />
        </Flex>
      </Flex>
    </Box>
  )
}

export default UserGroupsTableRowSelect
