import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, H1, Text } from '@fivehealth/botero'
import Tabs from 'old-code/components/Tabs/Tabs'
import { map, startCase, isEqual } from 'lodash'
import StatusBar from 'old-code/components/Status/StatusBar'
import usePostHog from 'old-code/customHooks/usePostHog'
import AnalyticsDashboard from './AnalyticsDashboard'
import ChatMessagesExport from './ChatMessagesExport'
import EventsConstant from '../../config/constants/events.constants'

const AnalyticsPage = () => {
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState(null)
  const [showStatusBar, setShowStatusBar] = useState(false)

  const logPostHogEvent = usePostHog()
  useEffect(() => {
    logPostHogEvent('dashboard:user_analytics_view')
  }, [logPostHogEvent])

  const showAnalyticsDetail = (data) => {
    history.push({
      pathname: '/analytics_detail_chart',
      state: {
        defaultData: data,
      },
    })
  }

  const onSetTabsRef = (ref) => setSelectedTab(ref.selectedTab)

  return (
    <Box>
      <StatusBar
        testId="sendEmailSuccessToast"
        showStatusBar={showStatusBar}
        status="SUCCESS"
        fontSize="14px"
        successMessage="Email has been sent successfully"
        showCloseButton={false}
      />
      <Box mt={2} p={2}>
        {/* StatusBar */}

        <H1 letterSpacing="-0.24px">User Analytics</H1>

        <Text letterSpacing="-0.08px" mt={2} fontSize="16px">
          View and download user analytics for your hospital app user base{' '}
        </Text>
        {/* Tab */}
        <Tabs
          mt={4}
          tabsRef={onSetTabsRef}
          tabs={map(['userAnalytics', 'messageLogs'], (tab) => ({
            id: tab,
            label: startCase(tab),
            logEventProps: {
              subSource: EventsConstant.ANALYTICS_PAGE,
              eventName: `${EventsConstant.ANALYTICS_TAB_SELECTED} - ${tab}`,
              page: EventsConstant.ANALYTICS_PAGE,
            },
          }))}
        />
        {isEqual(selectedTab, 'userAnalytics') && (
          <Box height={500}>
            <AnalyticsDashboard
              showAnalyticsDetail={showAnalyticsDetail}
              parentPage={EventsConstant.ANALYTICS_PAGE}
            />
          </Box>
        )}
        {isEqual(selectedTab, 'messageLogs') && (
          <ChatMessagesExport
            setShowStatusBar={setShowStatusBar}
            parentPage={EventsConstant.ANALYTICS_PAGE}
          />
        )}
      </Box>
    </Box>
  )
}

export default AnalyticsPage
