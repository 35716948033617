import React, { useRef, useState } from 'react'
import _ from 'lodash'
import {
  Flex,
  Box,
  PrimaryButton,
  SecondaryOutlinedButton,
  useApi,
  H2,
  FAIcon,
  sendLog,
} from '@fivehealth/botero'
import { useHistory } from 'react-router-dom'

import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import AlertModal from 'old-code/components/Modals/AlertModal'
import UserGroupsTable from '../UserGroups/UserGroupsTable'
import EventsConstant from '../../../../config/constants/events.constants'

const AddToFixed = ({
  selectedUids,
  openModal,
  closeModal,
  clearSelectBar,
}) => {
  const history = useHistory()
  const selectedRowsRef = useRef()
  const [selectedRows, setSelectedRows] = useState([])

  const {
    queries: { useProfilesGroupUpdate },
  } = useApi({
    queries: ['useProfilesGroupUpdate'],
  })

  const { mutateAsync: updateProfilesGroup } = useProfilesGroupUpdate({
    variables: {},
    onSuccess: ({ queryClient, data: resultData }) => {
      if (resultData?.errors?.length) {
        sendLog(
          {
            subSource: EventsConstant.USER_MANAGEMENT_TABLE_SOURCE,
            page: EventsConstant.USER_MANAGEMENT_PAGE,
            metaData: JSON.stringify(resultData.errors || []),
          },
          EventsConstant.USER_ERROR_UPDATE
        )
      }
      if (queryClient) {
        queryClient.invalidateQueries('profilesGroup')
      }
    },
  })

  const handleConfirmAddToFixedGroup = () => {
    openModal(
      <AlertModal
        description="You have added members to user group(s)"
        handleClose={closeModal}
        primaryButton={{
          text: 'View Groups',
          onClick: () => {
            history.push('/user_groups')
            closeModal()
          },
        }}
        secondaryButton={{
          text: 'Close',
          onClick: () => closeModal(),
        }}
      />,
      {},
      false
    )
  }

  const handleAddToGroup = () => {
    if (
      selectedRowsRef &&
      selectedRowsRef.current &&
      selectedRowsRef.current.selectedFlatRows
    ) {
      Promise.all(
        _.map(selectedRowsRef.current.selectedFlatRows, (row) => {
          const originalUids = row.original.ruleset.directoryProfiles.map(
            (dir) => dir.uid
          )

          return updateProfilesGroup({
            input: {
              uid: row.original.uid,
              ruleset: {
                directory_profile_uids: _.union(originalUids, selectedUids),
              },
            },
          })
        })
      ).then(() => {
        handleConfirmAddToFixedGroup()
        clearSelectBar()
      })
    }
    if (selectedRows) {
      Promise.all(
        _.map(selectedRows, (row) =>
          updateProfilesGroup({
            input: {
              uid: row.uid,
              upsertDirectoryprofileUids: selectedUids,
            },
          })
        )
      ).then(() => {
        handleConfirmAddToFixedGroup()
        clearSelectBar()
      })
    }
  }

  const onChangeSelectedRows = (rows) => {
    setSelectedRows(rows)
  }

  return (
    <Box p={4} style={{ boxSizing: 'border-box' }}>
      <Flex mb={4} justifyContent="space-between">
        <H2>Add to group(s)</H2>
        <FAIcon
          cursor="pointer"
          onClick={() => {
            closeModal()
            clearSelectBar()
          }}
          icon={faTimes}
          fontSize={20}
          color="darkestShade"
        />
      </Flex>
      <Box maxHeight={496} overflow="scroll">
        <UserGroupsTable
          addToFixedGroupFlow
          onChangeSelectedRows={onChangeSelectedRows}
        />
      </Box>
      <Flex mt={4} justifyContent="flex-end">
        <SecondaryOutlinedButton
          borderRadius="8px"
          mr={3}
          onClick={() => {
            closeModal()
            clearSelectBar()
          }}
        >
          Close
        </SecondaryOutlinedButton>
        <PrimaryButton
          borderRadius="8px"
          disabled={!selectedRows.length}
          onClick={handleAddToGroup}
        >
          Add to group
        </PrimaryButton>
      </Flex>
    </Box>
  )
}

export default AddToFixed
