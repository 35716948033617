import React, { useEffect, useRef, useState, memo } from 'react'
import _, { chain } from 'lodash'
import styled from 'styled-components'

import { Box, Text, useApi } from '@fivehealth/botero'
import { useQueryClient } from 'react-query'
import AsyncSelect from 'react-select/async'

import getCustomStyles from 'old-code/components/BotSelect/Helper'
import Form from 'old-code/components/Form/Form'
import { GRAPHQL_DOCUMENT as DOCUMENT_ENTRIES_GQL } from 'old-code/api/queries/useEinsteinDocumentEntrys'
import { EINSTEIN_DOCUMENT_ENTRY_GRAPHQL_DOCUMENT } from 'old-code/api/queries/useEinsteinDocumentEntry'
import ErrorMessage from 'old-code/components/ErrorMessage/ErrorMessage'
import { getMimeType, getMimeTypeBadge } from '../../../../Utils'

const Option = styled(Box)`
  position: relative;
  cursor: pointer;

  &:hover {
    background: #eee;
  }
`

const customOption = ({ innerProps, label, data }) => (
  <Option {...innerProps} p="14px">
    <Text
      fontSize="14px"
      style={{
        width: '380px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {label}
    </Text>
    <Box style={{ position: 'absolute', top: 6, right: 6 }}>
      {getMimeTypeBadge(data.value.mimeType)}
    </Box>
  </Option>
)

const BroadcastMessageTemplateDocuments = ({
  templateRef,
  defaultFormData,
  onFormChange,
  tmpId,
  ...props
}) => {
  const queryClient = useQueryClient()
  const formRef = useRef([])
  const { client } = useApi()

  useEffect(() => {
    queryClient.invalidateQueries('einsteinDocuments')
  }, [])

  const {
    queries: {
      useEinsteinDocumentEntrys,
      useEinsteinSettings,
      useEinsteinAdministrator,
    },
  } = useApi({
    queries: [
      'useEinsteinDocumentEntrys',
      'useEinsteinSettings',
      'useEinsteinAdministrator',
    ],
  })

  const { data: settingsData } = useEinsteinSettings({
    variables: {},
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  const { data: documentsData } = useEinsteinDocumentEntrys({
    variables: {
      dataSourceIn: [
        `${
          ((currentAdmin || {}).hospital || {}).organizationKey || ''
        }-documents`,
      ],
    },
  })

  // default to documents and media
  // eslint-disable-next-line no-param-reassign
  defaultFormData.file_source = 'documentsAndMedia'

  useEffect(() => {
    const filteredDocuments = chain(documentsData)
      .get('pages', [])
      .flatMap((page) => page || [])
      .filter(
        (doc) => !doc.isDeactivated && (doc.variables || {}).status !== 'ignore'
      )
      .uniqBy('uid')
      .map((doc) => {
        const docObj = {
          label: doc.title,
          value: doc,
        }

        return docObj
      })
      .value()

    formRef.current[0].updateFormData('documentOptions', filteredDocuments)
  }, [documentsData])

  const getRawFormData = () => ({
    tmpId,
    template: formRef.current[0].formData,
    selected: 'documents-media',
  })

  const resetFormData = () => {
    formRef.current[0].resetFormData()
  }

  const getTemplateData = (shouldCheckValid) => {
    const templateFormData = formRef.current[0].getFormData(shouldCheckValid)
    if (!_.isNull(templateFormData)) {
      const {
        selectedDocument,
        missingSelectedDoc,
        clearDefaultDocument = false,
      } = templateFormData
      let fileSourceMetadata = null

      if (
        templateFormData.template &&
        !missingSelectedDoc &&
        !clearDefaultDocument
      ) {
        fileSourceMetadata = {
          uid: templateFormData.einsteinDocId,
          type: templateFormData.template.type,
          url: templateFormData.url_upload,
          title: templateFormData.template.title,
        }
      }

      if (selectedDocument) {
        // check if stitch link is available, if not then use s3 link
        let fileSourceUrl = selectedDocument.originalFileStitchUrl
          ? `${selectedDocument.originalFileStitchUrl}`
          : selectedDocument.pdfFile

        if (selectedDocument?.dataSource?.key.includes('external-links')) {
          fileSourceUrl = selectedDocument.webUrl
        }

        fileSourceMetadata = {
          uid: selectedDocument.uid,
          type: getMimeType(selectedDocument.mimeType).toLocaleLowerCase(),
          url: fileSourceUrl,
          title: selectedDocument.title,
        }
      }

      if (fileSourceMetadata) {
        const mimeType = fileSourceMetadata?.type || ''
        const einsteinSettings = settingsData?.einsteinSettings
        const einsteinLogo = einsteinSettings?.logo?.url || ''

        switch (mimeType) {
          case 'video':
          case 'audio':
            return {
              type: 'message',
              message: {
                type: 'carousel',
                carousel: [
                  {
                    type: 'sections',
                    sections: [
                      {
                        type: mimeType,
                        [mimeType]: {
                          url: fileSourceMetadata.url,
                        },
                      },
                      {
                        type: 'paragraphs',
                        paragraphs: [
                          {
                            type: 'title',
                            title: fileSourceMetadata?.title,
                          },
                        ],
                      },
                      {
                        type: 'actions',
                        actions: [
                          {
                            type: 'button',
                            button: {
                              url: fileSourceMetadata.url,
                              content_type: mimeType,
                              text: `Play ${mimeType}`,
                              style: 'link',
                              event: {
                                type: 'einsteindocid',
                                properties: {
                                  docid: fileSourceMetadata.uid,
                                },
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            }
          default:
            return {
              type: 'message',
              message: {
                type: 'carousel',
                carousel: [
                  {
                    type: 'sections',
                    sections: [
                      {
                        type: 'image',
                        image: {
                          url: einsteinLogo,
                        },
                      },
                      {
                        type: 'paragraphs',
                        paragraphs: [
                          {
                            type: 'title',
                            title: fileSourceMetadata.title,
                          },
                        ],
                      },
                      {
                        type: 'actions',
                        actions: [
                          {
                            type: 'button',
                            button: {
                              url: fileSourceMetadata.url,
                              content_type: 'text/html',
                              text: 'Open',
                              style: 'link',
                              event: {
                                type: 'einsteindocid',
                                properties: {
                                  docid: fileSourceMetadata.uid,
                                },
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            }
        }
      }

      // placeholder only
      return {
        type: 'message',
        message: {
          type: 'text',
          text: '',
        },
      }
    }

    return null
  }

  useEffect(() => {
    if (templateRef) {
      templateRef({ getTemplateData, getRawFormData, resetFormData })
    }
  }, [settingsData])

  const [templateForms, setTemplateForms] = useState([
    {
      id: 'file-source',
      subTitle: '',
      type: 'options',
      fields: [
        {
          id: 'file_source',
          type: 'options',
          label: 'Add document file',
          options: [
            { id: 'documentsAndMedia', label: 'Einstein Files & Media' },
          ],
          required: true,
        },
      ],
    },
  ])

  const handleFetch = async (input) => {
    queryClient.cancelQueries('einsteinDocuments')
    if (input.length > 2) {
      const data = await queryClient.fetchQuery(
        'einsteinDocuments',
        () =>
          client.request(DOCUMENT_ENTRIES_GQL, {
            searchQuery: input,
            first: 25,
          }),
        { cacheTime: 0 }
      )

      const formattedData = chain(data)
        .get('einsteinDocumentEntrys', [])
        .get('edges', [])
        .filter(
          (doc) =>
            !doc.node.isDeactivated &&
            (doc.node.variables || {}).status !== 'ignore'
        )
        .uniqBy('node.uid')
        .map((doc) => {
          const docObj = {
            label: doc.node.title,
            value: doc.node,
          }

          return docObj
        })
        .value()

      const uniqueArray = []
      formattedData.filter((item) => {
        const i = uniqueArray.findIndex(
          (x) => x.label === item.label && x.value === item.value
        )
        if (i <= -1) {
          uniqueArray.push(item)
        }
        return null
      })

      return new Promise((resolve) =>
        setTimeout(() => resolve(uniqueArray), 100)
      )
    }

    return new Promise((resolve) =>
      setTimeout(
        () => resolve(formRef.current[0].formData.documentOptions || []),
        100
      )
    )
  }

  const onSetTemplateFromRef = async (ref) => {
    formRef.current[0] = ref
    if (formRef.current[0].formData.file_source === 'documentsAndMedia') {
      let defaultValue = null
      let isMissingDocument = false

      if (
        typeof formRef.current[0].formData.template !== 'undefined' &&
        formRef.current[0].formData.einsteinDocId
      ) {
        const selectedDocumentResponse = await queryClient.fetchQuery(
          'einsteinDocument',
          () =>
            client.request(EINSTEIN_DOCUMENT_ENTRY_GRAPHQL_DOCUMENT, {
              uid: formRef.current[0].formData.einsteinDocId,
            }),
          { cacheTime: 0 }
        )

        if (
          selectedDocumentResponse.einsteinDocumentEntry &&
          selectedDocumentResponse.einsteinDocumentEntry.isDeactivated &&
          !formRef.current[0].formData.missingSelectedDoc
        ) {
          isMissingDocument = true
          formRef.current[0].updateFormData('missingSelectedDoc', true)
        }

        defaultValue = isMissingDocument
          ? undefined
          : {
              label: formRef.current[0].formData.template.title,
              value: {
                uid: formRef.current[0].formData.einsteinDocId,
              },
            }
      }

      setTemplateForms([
        {
          id: 'file_source',
          subTitle: '',
          type: 'options',
          fields: [
            {
              id: 'file_source',
              type: 'options',
              label: 'Add document file',
              options: [
                {
                  id: 'documentsAndMedia',
                  label: 'Einstein Files & Media',
                },
              ],
              required: true,
            },
          ],
        },
        {
          id: 'selected-document',
          noMarginBottom: true,
          fields: [
            {
              id: 'selected-document',
              type: 'custom',
              visibility: true,
              component: (
                <Box mb={2} mt={0} style={{ maxWidth: '469px' }}>
                  <Text
                    fontSize="12px"
                    fontWeight="bold"
                    mb={1}
                    color="darkestShade"
                  >
                    Select file
                  </Text>
                  <AsyncSelect
                    classNames={{ control: () => 'target-border' }}
                    styles={getCustomStyles(
                      '100%',
                      formRef.current[0].formData.missingSelectedDoc &&
                        !formRef.current[0].formData.selectedDocument
                    )}
                    placeholder="Type to search documents & media"
                    loadOptions={(input) =>
                      new Promise((resolve) =>
                        setTimeout(() => resolve(handleFetch(input)), 400)
                      )
                    }
                    cacheOptions
                    defaultOptions={
                      formRef.current[0].formData.documentOptions || []
                    }
                    defaultValue={defaultValue}
                    isClearable
                    onChange={(selected) => {
                      formRef.current[0].updateFormData(
                        'selectedDocument',
                        (selected || {}).value || null
                      )
                    }}
                    components={{ Option: customOption }}
                  />
                  {formRef.current[0].formData.missingSelectedDoc &&
                    !formRef.current[0].formData.selectedDocument && (
                      <ErrorMessage error="The file you've selected is no longer available. Please select another file" />
                    )}
                </Box>
              ),
            },
          ],
        },
      ])
    }

    return true
  }

  return (
    <Box>
      <Form
        formRef={onSetTemplateFromRef}
        forms={templateForms}
        formSpacing={false}
        onFormChange={onFormChange}
        defaultFormData={defaultFormData}
        labelSize="12px"
        withinOutlinedBox
        {...props}
      />
    </Box>
  )
}

export default memo(
  BroadcastMessageTemplateDocuments,
  ({ tmpId: oldTmpId }, { tmpId: newTmpId }) => oldTmpId === newTmpId
)
