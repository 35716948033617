import _, { cloneDeep } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useHistory, useLocation } from 'react-router-dom'
import {
  faEnvelope,
  faQuestionCircle,
  faHeartRate,
} from '@fortawesome/pro-regular-svg-icons'
import usePostHog from '../../customHooks/usePostHog'
import BOTMD_LOGO_LARGE from '../../assets/assets/logo-large.svg'
import BOTMD_LOGO_SMALL from '../../assets/assets/logo-small.svg'
import { useOrganizationsContext } from '../../context/OrganizationsContext'
import { useConfig } from '../../context/ConfigContext'
import { useEinsteinAdministratorProfile } from '../../context/EinsteinAdministratorContext'

import SideBar from './Sidebar'
import EventsConstant from '../../config/constants/events.constants'
import { getMenuItems } from './helper'

const SidebarSlide = (props) => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const [expandSideBar, setExpandSideBar] = useState(false)
  const { isLabFormularyEnabled, isDrugFormularyEnabled } =
    useOrganizationsContext()
  const { einsteinAdmin, einsteinModules } =
    useEinsteinAdministratorProfile() || {}
  const { config } = useConfig()

  const logPostHogEvent = usePostHog()

  const isMobileHeight = useMediaQuery({ query: '(max-height: 700px)' })

  const handleMenuClick = (item) => {
    if (item.path === '/broadcast') {
      localStorage.setItem('selected_broadcast_tab', 'sent')
    }

    history.push(item.path)
    setExpandSideBar(true)
  }
  const headers = [
    {
      id: 'botmd',
      logo: (expand) => (expand ? BOTMD_LOGO_LARGE : BOTMD_LOGO_SMALL),
    },
  ]

  const enabledModulesNameArray =
    einsteinModules?.pages?.[0]?.einsteinModules?.edges?.map((element) =>
      element.node.name.toLowerCase().replace('-', ' ')
    )
  const menus = getMenuItems({
    handleMenuClick,
    EventsConstant,
    isDrugFormularyEnabled,
    t,
    einsteinAdmin: cloneDeep(einsteinAdmin),
    isLabFormularyEnabled,
    enabledModulesNameArray,
  })

  const additionalMenus = []

  const footers = [
    {
      id: 'statusCheck',
      label: t('Status check'),
      testId: 'statusCheck',
      icon: faHeartRate,
      onClick: () => {
        const url = new URL(
          config.domain === 'botmd.sg'
            ? 'https://status.botmd.sg/'
            : 'https://status.botmd.com/'
        )
        window.open(decodeURI(url.toString()))
        logPostHogEvent('dashboard:status_check_click')
      },
      toolTipElementProps: {
        color: 'white',
      },
    },
    {
      id: 'contactUs',
      label: t('Contact Us'),
      testId: 'contactUs',
      icon: faEnvelope,
      onClick: () => {
        const CUSTOMER_SUPPORT_EMAIL = 'customersupport@botmd.io'
        const url = new URL(`mailto:${CUSTOMER_SUPPORT_EMAIL}`)
        logPostHogEvent('dashboard:contact_us_click')
        window.open(decodeURI(url.toString()))
      },
      toolTipElementProps: {
        color: 'white',
      },
      logEventProps: {
        subSource: EventsConstant.SIDEBAR_SOURCE,
        eventName: EventsConstant.NAVIGATION_TO_CONTACT_US,
        page: EventsConstant.EINSTEIN_DASHBOARD,
      },
    },
  ]

  footers.push({
    id: 'helpGuide',
    label: t('Help Guide'),
    testId: 'helpGuide',
    icon: faQuestionCircle,
    onClick: () => {
      window.open('https://help.botmd.com/collection/228-einstein')
      logPostHogEvent('dashboard:help_guide_click')
    },
    toolTipElementProps: {
      color: 'white',
    },
    logEventProps: {
      subSource: EventsConstant.SIDEBAR_SOURCE,
      eventName: EventsConstant.NAVIGATION_TO_HELP_GUIDE,
      page: EventsConstant.EINSTEIN_DASHBOARD,
    },
  })

  const getActiveTab = () => {
    const allMenus = []
    menus?.forEach((menuItem) => {
      if ('subMenu' in menuItem) {
        allMenus.push(...menuItem.subMenu)
      }
      allMenus.push(menuItem)
    })

    return _.chain(allMenus)
      .filter((menu) => location.pathname.match(new RegExp(menu.path, 'i')))
      .first()
      .get('id', 'overview')
      .value()
  }

  return (
    <SideBar
      data-testid="side-nav-bar"
      defaultExpand={expandSideBar}
      activeTab={getActiveTab()}
      headers={headers}
      menus={menus}
      additionalMenus={additionalMenus}
      footers={footers}
      overflow={isMobileHeight ? 'scroll' : 'hidden'}
      history={history}
      footerProps={{
        position: isMobileHeight ? 'unset' : 'fixed',
        marginTop: isMobileHeight ? '100px' : 0,
      }}
      {...props}
    />
  )
}

export default SidebarSlide
