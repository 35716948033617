import React, { useCallback, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useQueryClient } from 'react-query'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import divide from 'lodash/divide'
import filter from 'lodash/filter'
import inRange from 'lodash/inRange'
import value from 'lodash/value'
import mapValues from 'lodash/mapValues'
import endsWith from 'lodash/endsWith'
import * as XLSX from 'xlsx/xlsx.mjs'

import {
  Box,
  Checkbox,
  Dialog,
  Flex,
  FAIcon,
  Text,
  PrimaryButton,
  SecondaryOutlinedButton,
  H5,
  useApi,
} from '@fivehealth/botero'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import BotExcelAvatar from '../../assets/bot-excel-avatar.svg'
import { checkTemplate } from '../../../Utils'
import { useModal } from '../../context/ModalContext'

import chernobylModuleMap from './ChernobylModuleMap'
import ChernobylDataSourceUploadLoading from './components/ChernobylDataSourceUploadLoading'

const chainableFunctions = {
  map,
  filter,
  divide,
  inRange,
  value,
}

export const chain = (input) => {
  let newValue = input
  const wrapper = {
    ...mapValues(chainableFunctions, (f) => (...args) => {
      newValue = f(newValue, ...args)
      return wrapper
    }),
    value: () => newValue,
  }
  return wrapper
}

const DialogTitle = ({ label, onClick, ...props }) => (
  <Flex justifyContent="space-between" mb={3} {...props}>
    <Text fontWeight={600} fontSize={3}>
      {label}
    </Text>
  </Flex>
)

const DialogSubTitle = ({ label, onClick, ...props }) => (
  <Flex justifyContent="space-between" mb={3} {...props}>
    <Text fontWeight={400} fontSize={2}>
      {label}
    </Text>
  </Flex>
)

const BackButton = styled(Flex)`
  &:hover {
    opacity: 0.8;
  }
`

const getRelevantColumnId = (rowObj) =>
  Object.keys(rowObj).find((columnId) =>
    ['code', 'name'].some((key) => columnId.toLowerCase().includes(key))
  )

const trimValue = (valuePararm, maxLength) =>
  valuePararm.length > maxLength
    ? `${valuePararm.slice(0, maxLength - 1)}...`
    : valuePararm

const ChernobylDataSourceUpload = () => {
  const history = useHistory()
  const hiddenFileInput = useRef()
  const queryClient = useQueryClient()
  const { module = 'drug_formulary' } = useParams()

  const [uploadFile, setUploadFile] = useState(null)
  const [uploadError, setUploadError] = useState('')
  const [uploadFEError, setUploadFEError] = useState('')
  const [uploadedData, setUploadedData] = useState({ uploadID: '' })
  const [triggerSync, setTriggerSync] = useState(false)
  const { openModal, closeModal } = useModal()
  const [moduleData, setModuleData] = useState()
  const [syncStatus, setSyncStatus] = useState(null)
  const [dataSourceUid, setDataSourceUid] = useState(null)
  const [pollDataSource, setPollDataSource] = useState(false)
  const [templatePath, setTemplatePath] = useState('')

  const [openExcelOption, setOpenExcelOption] = useState(false)

  const [excelOptions, setExcelOptions] = useState({
    sheetNames: [],
  })

  const location = useLocation()
  const { customModuleData } = location

  const customChernobylModuleMap = localStorage.getItem(
    'currentCustomModuleChernobylMap'
  )

  const chernobylModuleMapData =
    module.startsWith('custom') && customChernobylModuleMap
      ? JSON.parse(customChernobylModuleMap)
      : chernobylModuleMap[module]

  const handleLoading = (action) => {
    openModal(
      <ChernobylDataSourceUploadLoading
        closeModal={closeModal}
        showDashboard={() => history.push(chernobylModuleMapData.path)}
        action={action}
        chernobylModuleMap={chernobylModuleMapData}
      />,
      { width: '650px' },
      true
    )
  }

  const {
    queries: {
      useStitchUpload,
      useEinsteinModules,
      useEinsteinSyncChernobylDataSource,
      useEinsteinAdministrator,
      useEinsteinDataSource,
    },
  } = useApi({
    queries: [
      'useStitchUpload',
      'useEinsteinModules',
      'useEinsteinSyncChernobylDataSource',
      'useEinsteinAdministrator',
      'useEinsteinDataSource',
    ],
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  const { data: einsteinDatasource, refetch: refetchEinsteinDataSource } =
    useEinsteinDataSource({
      enabled: !!dataSourceUid,
      variables: {
        uid: dataSourceUid,
      },
      onSuccess: () => {
        setPollDataSource(true)
      },
    })

  useEffect(() => {
    queryClient.invalidateQueries('einsteinModules')
    queryClient.invalidateQueries('einsteinDataSource')

    return () => {
      setDataSourceUid(null)
      setPollDataSource(false)
    }
  }, [])

  useEffect(() => {
    setSyncStatus(null)
  })

  useEffect(() => {
    if (dataSourceUid && einsteinDatasource && pollDataSource) {
      const { einsteinDataSource } = einsteinDatasource
      const newSyncStatus = einsteinDataSource.syncStatus

      setPollDataSource(false)
      if (newSyncStatus === 'IN_PROGRESS') {
        // poll every three seconds
        setTimeout(() => {
          refetchEinsteinDataSource()
        }, 3000)
      } else if (newSyncStatus === 'SUCCESS') {
        setSyncStatus(
          einsteinDatasource.einsteinDataSource.syncMetadata.sync_status
        )
      } else {
        handleLoading('error')
      }
    }
  }, [einsteinDatasource, pollDataSource, dataSourceUid])

  useEffect(() => {
    if (dataSourceUid) {
      setTimeout(() => {
        refetchEinsteinDataSource()
      }, 3000)
    }
  }, [dataSourceUid])

  useEinsteinModules({
    variables: { visible: true },
    onSuccess: ({ data }) => {
      setModuleData(data.pages[0].einsteinModules.edges)
    },
  })

  const { mutateAsync: uploadFileToStitch } = useStitchUpload({
    variables: {},
    onSuccess: () => {},
    onError: () => handleLoading('error'),
  })

  const { mutateAsync: uploadChernobylDataSource } =
    useEinsteinSyncChernobylDataSource({
      variables: {},
      onSuccess: ({ data }) => {
        const newDataSourceUid =
          (
            ((data || {}).einsteinSyncChernobylDataSource || {}).dataSource ||
            {}
          ).uid || null

        setDataSourceUid(newDataSourceUid)
      },
      onError: () => handleLoading('error'),
    })

  useEffect(() => {
    if (syncStatus) {
      const newCount = syncStatus.created_count || 0
      const sameCount = syncStatus.same_count || 0
      const errorMessages = syncStatus.failed.map(([message, rowObj]) => {
        const columnId = getRelevantColumnId(rowObj)

        if (columnId) {
          const maxLength = 15
          const trimmedValue = trimValue(rowObj[columnId], maxLength)
          return `For ${trimmedValue}: ${message}`
        }

        return message
      })

      if (newCount || sameCount) {
        openModal(
          <ChernobylDataSourceUploadLoading
            closeModal={closeModal}
            showDashboard={() => history.push(chernobylModuleMapData.path)}
            action="success"
            totalUpdate={newCount + sameCount}
            chernobylModuleMap={chernobylModuleMapData}
            errorMessages={errorMessages}
          />,
          { width: '650px' },
          true
        )
      }
    }
  }, [syncStatus])

  const readExcelFile = (file, options) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (event) => {
        const wb = XLSX.read(event.target.result, {
          type: 'binary',
          ...options,
        })
        resolve(wb)
      }

      reader.onerror = (err) => {
        reject(err)
      }

      reader.readAsBinaryString(file)
    })

  const getSheetNames = (file) => {
    if (file) {
      readExcelFile(file, { bookSheets: true }).then((workbook) => {
        setExcelOptions({
          ...excelOptions,
          sheetNames: map(workbook.SheetNames, (name) => ({
            name,
            selected: false,
          })),
        })
      })
    }
  }

  const checkFileSize = (size) =>
    chain(size)
      .divide(1024) // KB
      .divide(1024) // MB
      .inRange(5) // 5MB
      .value()

  const onDrop = useCallback(
    (acceptedFiles) => {
      checkTemplate(
        acceptedFiles[0],
        templatePath,
        setUploadFile,
        setUploadError
      )
      if (acceptedFiles.length === 1) {
        if (!endsWith(acceptedFiles[0].name, '.xlsx')) {
          setUploadFile(null)
          setUploadError('Error: Only .xlsx file is allowed.')
        } else if (!checkFileSize(acceptedFiles[0].size)) {
          setUploadFile(null)
          setUploadError('Error: Maximum file size allowed is 5MB.')
        } else {
          getSheetNames(acceptedFiles[0])
          setUploadFile(acceptedFiles[0])
          setUploadError('')
        }
      } else {
        setUploadFile(null)
        setUploadError('Error: Only one file is allowed.')
      }
    },
    [templatePath, currentAdmin]
  )

  useEffect(() => {
    if (!isEmpty(uploadedData.uploadID) && triggerSync) {
      const chernobylModule = moduleData.find(
        ({ node }) =>
          (((node.settings || {}).chernobyl || {}).key || {}) ===
          `${currentAdmin.hospital.organizationKey}-${chernobylModuleMapData.key}`
      )

      uploadChernobylDataSource({
        input: {
          dataSourceUid: chernobylModule.node.dataSources[0].uid,
          fileId: uploadedData.uploadID.split('/')[1],
          preview: false,
          syncJarvis: true,
          activeSheets:
            excelOptions.sheetNames.length > 1
              ? chain(excelOptions.sheetNames)
                  .filter((sheet) => sheet.selected)
                  .map((sheet) => sheet.name)
                  .value()
              : undefined,
        },
      })
    }
  }, [triggerSync])

  useEffect(() => {
    if (chernobylModuleMapData.key === 'hospital-directory-einstein') {
      switch (currentAdmin?.hospital?.organizationKey) {
        case 'nkti':
          setTemplatePath(chernobylModuleMapData.custom.nkti)
          break
        default:
          setTemplatePath(chernobylModuleMapData.templatePath)
          break
      }
    } else if (chernobylModuleMapData.key === 'drug-formulary-einstein') {
      switch (currentAdmin?.hospital?.organizationKey) {
        case 'parkwayradio':
          setTemplatePath(chernobylModuleMapData.custom.parkwayradio)
          break
        default:
          setTemplatePath(chernobylModuleMapData.templatePath)
          break
      }
    } else {
      setTemplatePath(chernobylModuleMapData.templatePath)
    }
  }, [currentAdmin, chernobylModuleMapData])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const getUploadStatus = () => {
    if (uploadFile) {
      return (
        <Flex alignItems="center" justifyContent="center" mt={2}>
          <Text color="green">{`Selected: ${uploadFile.name}`}</Text>
        </Flex>
      )
    }

    if (uploadError) {
      return (
        <Flex alignItems="center" justifyContent="center" mt={2}>
          <Text color="danger">{uploadError}</Text>
        </Flex>
      )
    }

    return null
  }

  const handleCheckboxChange = (id, newValue) => {
    setExcelOptions({
      ...excelOptions,
      sheetNames: map(excelOptions.sheetNames, (sheet) => {
        if (isEqual(sheet.name, id)) {
          return {
            ...sheet,
            selected: newValue,
          }
        }
        return sheet
      }),
    })
  }

  const handleUpload = () => {
    if (!isNull(uploadFile)) {
      handleLoading('pending')
      uploadFileToStitch({
        input: {
          key: 'einstein',
          mimeType: uploadFile.type,
        },
      })
        .then(({ stitchCreateUploadUrl }) => {
          const body = new FormData()
          const uploadedFile = uploadFile
          map(stitchCreateUploadUrl.fields, (newValue, key) => {
            body.append(key, newValue)
          })
          body.append('file', uploadedFile)
          setUploadedData({
            ...uploadedData,
            uploadID: stitchCreateUploadUrl.uploadId,
          })
          return fetch(stitchCreateUploadUrl.url, {
            method: 'post',
            body,
          })
        })
        .then(() => {
          setTriggerSync(true)
        })
        .catch(() => {
          handleLoading('error')
        })
    } else {
      setUploadError('Error: Please select file for upload.')
    }
  }

  const handleContinueUpload = () => {
    if (uploadFile) {
      handleUpload()
    }
  }

  return (
    <Box>
      {/* Back Button */}
      <BackButton
        id="backBtn"
        alignItems="center"
        mb={1}
        ml="16px"
        hover={{ opacity: 0.6 }}
        cursor="pointer"
        onClick={() => history.push(chernobylModuleMapData.path)}
      >
        <FAIcon
          icon={faChevronLeft}
          color="darkestShade"
          fontWeight="500"
          style={{ fontSize: 12, fontWeight: 500, marginRight: 4 }}
        />
        <H5 fontSize={14} color="darkestShade">
          Back
        </H5>
      </BackButton>
      {/* Title */}
      <Flex alignItems="center" justifyContent="space-between">
        <Text m={2} mt={0} fontSize={5} fontWeight="600">
          {customModuleData?.fullSyncFormTitle || 'Full sync'}
        </Text>
        <Flex justifyContent="right">
          <PrimaryButton
            borderRadius="8px"
            m={2}
            onClick={handleContinueUpload}
            disabled={!uploadFile}
          >
            Continue
          </PrimaryButton>
        </Flex>
      </Flex>

      {/* Description */}
      <Box m={2} mt={3}>
        <Text>
          Download and fill&nbsp;
          <Link to={templatePath} target="_blank" download>
            this template
          </Link>
          {customModuleData?.fullSyncFromDescription
            ? `. ${customModuleData?.fullSyncFromDescription}`
            : `. The ${chernobylModuleMapData?.fullLabel} will be completely replaced
          by the details from the uploaded template.`}
        </Text>
      </Box>

      <Flex m={2} mt={10} alignItems="center" justifyContent="center">
        <Box>
          <Text fontSize={1}>Upload file</Text>
          <Flex
            mt={1}
            width="600px"
            border="dashed"
            borderWidth={2}
            borderColor="#00000030"
            alignItems="center"
            justifyContent="center"
          >
            <Box p={8} {...getRootProps()}>
              <Flex alignItems="center" justifyContent="center" mb={3}>
                <Box as="img" pl={2} src={BotExcelAvatar} />
              </Flex>
              <Flex alignItems="center" justifyContent="center">
                <input
                  type="file"
                  style={{ display: 'none' }}
                  {...getInputProps()}
                  ref={hiddenFileInput}
                  accept=".xlsx"
                />
              </Flex>
              <Flex alignItems="center" justifyContent="center">
                <Text fontWeight="400" fontSize="14px" mr={1}>
                  Drag and drop template here or Upload File
                </Text>
                <SecondaryOutlinedButton
                  borderRadius="8px"
                  onClick={() => hiddenFileInput.current.click()}
                >
                  Browse Files
                </SecondaryOutlinedButton>
              </Flex>
              <Flex alignItems="center" justifyContent="center" mt={1}>
                <Text fontWeight="400" fontSize="11px" color="darkShade">
                  Allowed file types: .XLSX. Maximum size: 5MB.
                </Text>
              </Flex>
              {getUploadStatus()}
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Dialog type="basic" open={openExcelOption} onClose={() => {}}>
        <Box width="440px" p="8px">
          <DialogTitle
            label={`${
              ((excelOptions || {}).sheetNames || []).length
            } sheets found in ${(uploadFile || {}).name || ''}`}
            onClick={() => setOpenExcelOption(!openExcelOption)}
          />
          <DialogSubTitle
            label="Select the sheets you would like to sync:"
            onClick={() => setOpenExcelOption(!openExcelOption)}
          />
          {uploadFEError && (
            <Text color="danger" fontSize={12} mt={1} mb={1}>
              {uploadFEError}
            </Text>
          )}
          {map(excelOptions.sheetNames, ({ name, selected }, index) => (
            <Box mb={2} key={index}>
              <Checkbox
                label={name}
                checked={selected}
                onChange={(ev) => handleCheckboxChange(name, ev.target.checked)}
              />
            </Box>
          ))}
          <Flex justifyContent="right" mt={2}>
            <SecondaryOutlinedButton
              borderRadius="8px"
              mr={2}
              onClick={() => {
                setUploadFEError(null)
                setOpenExcelOption(false)
              }}
            >
              Cancel
            </SecondaryOutlinedButton>
            <PrimaryButton
              borderRadius="8px"
              onClick={() => {
                if (excelOptions && excelOptions.sheetNames) {
                  const selectedSheet = filter(
                    excelOptions.sheetNames,
                    (sheet) => sheet.selected === true
                  )
                  if (!isEmpty(selectedSheet)) {
                    setOpenExcelOption(!openExcelOption)
                    handleUpload()
                  } else {
                    setUploadFEError(
                      'Please choose at least one sheet to continue'
                    )
                  }
                } else {
                  setUploadFEError(
                    'Please upload excel sheet sheet to continue'
                  )
                }
              }}
              disabled={
                !chain(excelOptions.sheetNames)
                  .filter((sheet) => sheet.selected)
                  .value().length
              }
            >
              Continue
            </PrimaryButton>
          </Flex>
        </Box>
      </Dialog>
    </Box>
  )
}

export default ChernobylDataSourceUpload
